import { ListingV2 } from 'types/listings';
import { MediaType, MediaLinkSchema } from '@kouto/types';
import { z } from 'zod';
import { getBiggerMediaPreviewLink } from '../data/medias/getBiggerMediaPreviewLink';

type MediaLinks = z.infer<typeof MediaLinkSchema>;

const getListingCoverImagePreview = (listing: ListingV2): string => {
  if (!listing.medias) return '';
  const media = listing.medias?.find((m) =>
    m.links.some((link: MediaLinks) => link.type === MediaType.IMAGE),
  );
  return media ? getBiggerMediaPreviewLink(media) : '';
};

export default getListingCoverImagePreview;
