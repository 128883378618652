import React from 'react';
import styled from 'styled-components';

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
`;

interface ProfilePictureProps {
  className?: string;
  src: string;
  alt: string;
}

const ProfilePicture = ({ className, src, alt }: ProfilePictureProps) => {
  if (!src) {
    return null;
  }
  return <StyledImage className={className} src={src} alt={alt} />;
};

export default ProfilePicture;
