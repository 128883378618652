import { get } from 'api/api';
import getIdExchangeUrl from 'api/listing/getExchangeUrl';
import useSWR from 'swr';

export interface IdExchangeResponse {
  data: string[];
}

const fetchExchangeIds = async (
  experienceIds: string[],
  brandId: string,
): Promise<string[]> => {
  try {
    const results = await Promise.all(
      experienceIds.map(async (id) => {
        const url = getIdExchangeUrl(id);
        const data: IdExchangeResponse = await get(url, {
          headers: {
            'Kouto-Brand-Id': brandId,
            'Way-Brand-Id': brandId,
          },
        });
        return data.data;
      }),
    );
    return results.flat();
  } catch (error) {
    console.error('Error fetching exchange IDs:', error);
    return [];
  }
};

const useExchangeId = (experienceIds: string | string[], brandId: string) => {
  const idsArray = Array.isArray(experienceIds)
    ? experienceIds
    : [experienceIds];
  const { data, error } = useSWR(
    idsArray.length > 0 && brandId ? [idsArray, brandId] : null,
    ([idsArray, brandId]) => fetchExchangeIds(idsArray, brandId),
  );

  if (error) {
    console.error('Error fetching exchange IDs:', error);
    return [];
  }

  return data;
};

export default useExchangeId;
