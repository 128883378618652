import { setCartId } from 'actions/cart';
import { useBrandId, useDispatch } from 'AppProvider';
import usePrevious from 'hooks/usePrevious';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import usePaymentIntent from './usePaymentIntent';

const MAX_PAYMENT_INTENT_ATTEMPTS = 3;

const useCartPaymentIntent = (
  cartTotal: number,
  selectedPaymentMethod: string,
) => {
  const dispatch = useDispatch();
  const brandId = useBrandId();
  const previousCartTotal = usePrevious(cartTotal);
  const [attempts, setAttempts] = useState(0);

  const { paymentIntent, paymentIntentSecret, isLoading } = usePaymentIntent(
    selectedPaymentMethod,
  );

  const previousIsLoading = usePrevious(isLoading);

  useEffect(() => {
    if (
      typeof previousCartTotal !== 'undefined' &&
      previousCartTotal > 0 &&
      cartTotal === 0
    ) {
      dispatch(
        setCartId({
          brandId,
          cartId: uuidv4(),
        }),
      );
    }
  }, [cartTotal, previousCartTotal, brandId, dispatch]);

  useEffect(() => {
    if (
      previousIsLoading &&
      !isLoading &&
      !paymentIntentSecret &&
      cartTotal > 0 &&
      attempts < MAX_PAYMENT_INTENT_ATTEMPTS
    ) {
      setTimeout(async () => {
        await dispatch(
          setCartId({
            brandId,
            cartId: uuidv4(),
          }),
        );
        setAttempts(attempts + 1);
      }, 1000);
    }
  }, [
    brandId,
    isLoading,
    previousIsLoading,
    cartTotal,
    paymentIntentSecret,
    attempts,
    dispatch,
  ]);

  return {
    paymentIntent,
    paymentIntentSecret,
    isLoading,
  };
};

export default useCartPaymentIntent;
