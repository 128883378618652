import React, { FC, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import useEventBookingSession from 'features/Events/hooks/useEventBookingSession';
import { SkeletonLineSlim } from 'components/theme/Skeleton/Skeleton';
import EventPriceTierSelector from 'components/Event/EventPriceTierSelector';
import useWaitList from 'api/waitlist/useWaitList';
import getUpdatedSelectedDate from 'utils/data/getUpdatedSelectedData';
import { getMaxQuantitiesPerTiersLists } from '../utils/tickets-utils';

interface Props {
  priceTiers: ReturnType<typeof getMaxQuantitiesPerTiersLists>['priceTiers'];
  priceTierSelection: { id: string; numberOfParticipants: number }[];
  onChangePriceTiers: (
    priceTiers: { id: string; numberOfParticipants: number }[],
  ) => void;
}

const TicketPricesSelector: FC<Props> = ({
  priceTiers,
  priceTierSelection,
  onChangePriceTiers,
}) => {
  const { event, isLoading, date } = useEventBookingSession();
  const { data: waitlist } = useWaitList(event?.waitlistId, event?.brandId);
  const selectedDate = getUpdatedSelectedDate(date, event, waitlist);

  useEffect(() => {
    onChangePriceTiers([]);
  }, [selectedDate]);

  const onParticipantsChange = useCallback(
    (tierId: string, increment: number) => {
      const newSelection = [...priceTierSelection];

      const index = newSelection.findIndex(({ id }) => id === tierId);

      if (index < 0) {
        newSelection.push({ id: tierId, numberOfParticipants: increment });
      } else {
        newSelection[index].numberOfParticipants += increment;
      }

      onChangePriceTiers(
        newSelection.filter(
          ({ numberOfParticipants }) => numberOfParticipants !== 0,
        ),
      );
    },
    [onChangePriceTiers, priceTierSelection],
  );

  if (isLoading) {
    return (
      <Wrapper>
        <PriceTierSkeleton />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {priceTiers.map((priceTier) => (
        <EventPriceTierSelector
          key={priceTier.id}
          priceTier={priceTier}
          value={
            priceTierSelection.find(({ id }) => id === priceTier.id)
              ?.numberOfParticipants ?? 0
          }
          onChange={(increment) =>
            onParticipantsChange(priceTier.id, increment)
          }
        />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 768px) {
    gap: 12px;
  }
`;

const PriceTierSkeleton = styled(SkeletonLineSlim)`
  height: 46px;
`;

export default TicketPricesSelector;
