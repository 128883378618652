import useSWR from 'swr';
import { Listing } from 'types/listings';
import { get } from 'utils/axios';
import { ListingVersion, PublicGetListingEndpoint } from '@kouto/types';
import useSupportedLanguage from 'utils/listingsHelpers/useSupportedLanguage';

const useListingsByIds = (ids: string[]) => {
  const key = ids.length > 0 ? JSON.stringify(Array.from(new Set(ids))) : null;
  const languageCode = useSupportedLanguage();

  const {
    data: listings,
    isLoading,
    error,
  } = useSWR<Listing[]>(key, async (idsToParse: string) => {
    const ids = JSON.parse(idsToParse) as string[];

    const results = await Promise.all(
      ids.map((id) =>
        get<{ data: Listing }>(
          PublicGetListingEndpoint.url({
            listingId: id,
            query: {
              version: ListingVersion.PUBLISHED,
              language: languageCode,
            },
          }),
        ),
      ),
    );
    return results.map((result) => result.data);
  });

  return {
    listings: Object.fromEntries(
      listings?.map((listing) => [listing.id, listing]) ?? [],
    ),
    isLoading,
    error,
  };
};

export default useListingsByIds;
