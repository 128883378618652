import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { initReactI18next } from 'react-i18next';

import TRANSLATION_CZ from 'translations/czech';
import TRANSLATION_EN from 'translations/english';
import TRANSLATION_FR from 'translations/french';
import TRANSLATION_DE from 'translations/german';
import TRANSLATION_IT from 'translations/italian';
import TRANSLATION_JP from 'translations/japanese';
import TRANSLATION_KO from 'translations/korean';
import TRANSLATION_PT from 'translations/portuguese';
import TRANSLATION_RU from 'translations/russian';
import TRANSLATION_ES from 'translations/spanish';

export enum Languages {
  EN = 'en',
  FR = 'fr',
  KO = 'ko',
  ES = 'es',
  RU = 'ru',
  DE = 'de',
  IT = 'it',
  PT = 'pt',
  CZ = 'cz',
  JP = 'jp',
}

const translations: Record<
  Languages,
  { translation: Record<string, string | object> }
> = {
  en: {
    translation: TRANSLATION_EN,
  },
  fr: {
    translation: TRANSLATION_FR,
  },
  ko: {
    translation: TRANSLATION_KO,
  },
  es: {
    translation: TRANSLATION_ES,
  },
  ru: {
    translation: TRANSLATION_RU,
  },
  de: {
    translation: TRANSLATION_DE,
  },
  it: {
    translation: TRANSLATION_IT,
  },
  pt: {
    translation: TRANSLATION_PT,
  },
  cz: {
    translation: TRANSLATION_CZ,
  },
  jp: {
    translation: TRANSLATION_JP,
  },
};
i18n
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  .use(new LanguageDetector() as any)
  .use(initReactI18next)
  .init({
    fallbackLng: Languages.EN,
    debug: process.env.NODE_ENV === 'development',
    resources: translations,
  });

export default i18n;
