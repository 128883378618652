import { ProductLine } from '@kouto/types';
import useSWR from 'swr';
import { get } from 'utils/axios';
import { getEmbedConfig } from 'features/EmbedConfig';
import buildCategoryUrl from 'api/categories/buildCategoryUrl';
import useSupportedLanguage from 'utils/listingsHelpers/useSupportedLanguage';

export type Category = {
  id: string;
  name: string;
  order: number | null;
};

const useBrandCategories = (brandId: string, product?: ProductLine) => {
  const languageCode = useSupportedLanguage();
  const url = buildCategoryUrl(brandId, languageCode, product);
  const pathNameAndSearch = url.pathname.replace('/public', '') + url.search;

  const { data, isLoading } = useSWR(
    pathNameAndSearch,
    get<{ items: Category[] }>,
  );

  const embedConfig = getEmbedConfig();

  const allCategories = data?.items ?? [];

  const filteredCategories = embedConfig.categoryId
    ? allCategories.filter((category) => category.id === embedConfig.categoryId)
    : allCategories;

  return {
    categories: filteredCategories.sort((c) => c.order ?? 0),
    isLoading,
  };
};

export default useBrandCategories;
