import React from 'react';
import styled from 'styled-components';

type CircleMinusIconProps = Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
  color?: string;
  scale?: number;
  className?: string;
};

const StyledSvg = styled.svg.withConfig({
  shouldForwardProp: (prop) => prop !== 'scale',
})<{ scale?: number }>`
  color: var(--way-colors-svgColor);
  height: ${({ scale = 1 }) => 24 * scale}px;
  width: ${({ scale = 1 }) => 24 * scale}px;
`;

const CircleMinusIcon = ({
  color,
  scale = 1,
  className = '',
  ...props
}: CircleMinusIconProps) => (
  <StyledSvg
    fill="none"
    scale={scale}
    style={{ color }}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={`feather feather-minus-circle ${className}`}
    {...props}
  >
    <circle
      cx="12"
      cy="12"
      r="10"
      style={{
        fill: 'none',
        stroke: 'currentColor',
      }}
    />
    <line
      x1="8"
      y1="12"
      x2="16"
      y2="12"
      style={{
        fill: 'none',
        stroke: 'currentColor',
      }}
    />
  </StyledSvg>
);

CircleMinusIcon.displayName = 'CircleMinusIcon';

export default CircleMinusIcon;
