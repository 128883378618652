import { z } from 'zod';
import {
  PublicGetListingsEndpoint,
  ListingVersion,
  ProductLine,
  ListingKind,
} from '@kouto/types';
import usePaginatedItems from 'hooks/usePaginatedItems';
import { filterEmpty } from 'actions/helpers';
import useSearchQueryParams from 'hooks/use-search-params';
import { getEmbedConfig } from 'features/EmbedConfig';
import { Listing } from 'types/listings';
import moment from 'moment';
import { DATE_FORMAT } from 'utils';

interface useGetListingsQueryProps
  extends Omit<
    z.infer<typeof PublicGetListingsEndpoint.querySchema>,
    'page' | 'limit'
  > {
  page?: number;
  limit?: number;
  productLine?: ProductLine;
}

/**
 * @deprecated Use `useV2Listings` in api/listing instead.
 */
const useGetListings = (
  brandId: string,
  query?: useGetListingsQueryProps,
  options?: {
    ignoreSearchParam?: boolean;
    showMultipleCardsPerEvent?: boolean;
    sortByDate?: boolean;
  },
) => {
  const ignoreSearchParam = options?.ignoreSearchParam ?? false;
  const showMultipleCardsPerEvent = options?.showMultipleCardsPerEvent ?? false;
  const sortByDate = options?.sortByDate ?? false;

  const embedConfig = getEmbedConfig();

  const { searchParams } = useSearchQueryParams();

  let categoryIds: string[] | undefined = embedConfig?.categoryId
    ? [embedConfig.categoryId]
    : query?.categoryIds;
  let vibeIds: string[] | undefined = query?.vibeIds;
  let productSearchParams = query?.productLine;
  const from = query?.from ?? searchParams?.from;
  const to = query?.to ?? searchParams?.to;

  if (!ignoreSearchParam) {
    if (searchParams.categoryIds) {
      if (Array.isArray(searchParams.categoryIds)) {
        categoryIds = searchParams.categoryIds;
      } else {
        categoryIds = [searchParams.categoryIds];
      }
    }
    if (searchParams.vibeIds) {
      if (Array.isArray(searchParams.vibeIds)) {
        vibeIds = searchParams.vibeIds;
      } else {
        vibeIds = [searchParams.vibeIds];
      }
    }
    if (searchParams.productLine) {
      productSearchParams = searchParams.productLine;
    }
  }

  const listingsUrl = PublicGetListingsEndpoint.url({
    query: filterEmpty({
      isUnlisted: 'false',
      version: ListingVersion.PUBLISHED,
      brandIds: [brandId],
      ...(categoryIds && {
        categoryIds: Array.isArray(categoryIds) ? categoryIds : [categoryIds],
      }),
      ...(vibeIds && {
        vibeIds: Array.isArray(vibeIds) ? vibeIds : [vibeIds],
      }),
      ...query,
      from,
      to,
      limit: query?.limit ?? 24,
      ...(productSearchParams && {
        filters: [
          { name: 'productLine', operator: 'in', value: [productSearchParams] },
        ],
      }),
    }),
  });

  const {
    items,
    error,
    meta,
    isEmpty,
    isLoadingInitialData,
    isLoadingMore,
    loadMore,
    allItemsLoaded,
    mutate,
  } = usePaginatedItems<Listing>(listingsUrl);

  return {
    listings: formatListingsToGroupActivateByDate(
      filterListingsWithoutMedias(items),
      showMultipleCardsPerEvent,
      sortByDate,
    ),
    error,
    meta,
    isEmpty,
    isLoadingInitialData,
    isLoadingMore,
    isLoading: isLoadingInitialData || isLoadingMore,
    loadMore,
    allItemsLoaded,
    mutate,
  };
};

const filterListingsWithoutMedias = (listings: Listing[]) =>
  listings
    .filter((listing) => listing.pictures.length > 0)
    .map((listing) => ({
      ...listing,
      medias: listing.medias.filter((media) => media.links.length > 0),
    }));

const formatListingsToGroupActivateByDate = (
  listings: Listing[],
  showMultipleCardsPerEvent: boolean,
  sortByDate: boolean,
  maxDaysDiffToGroup = 3,
) => {
  return listings
    .flatMap((listing) => {
      if (listing.productLine === ProductLine.ACTIVATE) {
        if (listing.kind === ListingKind.EXPERIENCE) {
          // fix for one time experiences treated as 'activate' listings
          return [
            {
              ...listing,
              dates: listing.firstAvailableDate
                ? [listing.firstAvailableDate.scheduledDate]
                : listing.dates || null,
            },
          ];
        }

        const availableDates = (listing.dates || []).filter((date) =>
          listing.firstAvailableDate
            ? date >= listing.firstAvailableDate.scheduledDate
            : true,
        );

        // normal 'activate' listings
        const groupedDates = groupEventDatesByProximity(
          availableDates,
          maxDaysDiffToGroup,
        );

        return groupedDates
          .splice(0, showMultipleCardsPerEvent ? groupedDates.length : 1)
          .map((dates) => ({
            ...listing,
            dates,
          }));
      }

      return [listing];
    })
    .sort((a: Listing, b: Listing) => {
      if (!sortByDate) {
        return 0;
      }

      const firstDate =
        a.dates?.[0] || a.firstAvailableDate?.scheduledDate || '';
      const secondDate =
        b.dates?.[0] || b.firstAvailableDate?.scheduledDate || '';
      return firstDate > secondDate ? 1 : -1;
    });
};

export const groupEventDatesByProximity = (
  dates: string[],
  maxDaysDiffToGroup: number,
  today = moment().format(DATE_FORMAT),
) =>
  dates
    .filter((date) => date >= today)
    .sort()
    .reduce((groups, date) => {
      if (!groups.length) {
        // This is the first date, start a new group
        groups.push([date]);
      } else {
        const currentGroup = groups[groups.length - 1];
        const lastDateInGroup = currentGroup[currentGroup.length - 1];
        const daysApart = moment(date).diff(moment(lastDateInGroup), 'days');
        const sameMonth = moment(date).isSame(moment(lastDateInGroup), 'month');

        if (daysApart <= maxDaysDiffToGroup && sameMonth) {
          // This date is within 3 days of the first date in the current group and in the same month, add it to this group
          currentGroup.push(date);
        } else {
          // This date is more than 3 days apart from the first date in the current group or in a different month, start a new group
          groups.push([date]);
        }
      }

      return groups;
    }, [] as string[][]);

export default useGetListings;
