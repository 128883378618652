import toDateString from 'utils/date/toDateString';

const buildSessionsUrl = (
  listingId: string,
  {
    from,
    to,
    resourceGroupId,
  }: { from: Date; to: Date; resourceGroupId?: string },
) => {
  const url = new URL(
    `${process.env.KOUTO_API_URL}/v1/listings/${listingId}/sessions`,
  );
  url.searchParams.set('from', toDateString(from));
  url.searchParams.set('to', toDateString(to));

  if (resourceGroupId) {
    url.searchParams.set('resourceGroupId', resourceGroupId);
  }

  return url.toString();
};

export default buildSessionsUrl;
