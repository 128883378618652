import { z } from 'zod';
import { GetCancellationPolicyPublicEndpoint } from '@kouto/types';
import { useAppState, useBrandId } from 'AppProvider';
import useSWR from 'swr';
import { get } from 'utils/axios';
import useSupportedLanguage from 'utils/listingsHelpers/useSupportedLanguage';

const useCancellationPolicy = (listingCancellationPolicyId?: string | null) => {
  const brandId = useBrandId();
  const languageCode = useSupportedLanguage();

  const { settings } = useAppState(
    (state: { brand: { settings: unknown } }) => state.brand,
  );

  const brandCancellationPolicyId = (settings?.cancellationPolicyId || null) as
    | string
    | null;

  const cancellationPolicyId =
    listingCancellationPolicyId || brandCancellationPolicyId;

  let getCancellationPolicyUrl: string | null = null;
  let cancellationPolicy:
    | z.infer<typeof GetCancellationPolicyPublicEndpoint.responseSchema>
    | undefined;
  if (cancellationPolicyId) {
    getCancellationPolicyUrl = GetCancellationPolicyPublicEndpoint.url({
      brandId,
      cancellationPolicyId,
    });
  }

  if (languageCode) {
    getCancellationPolicyUrl += `?language=${languageCode}`;
  }

  const { data: cancellationPolicyData, isLoading } = useSWR(
    getCancellationPolicyUrl,
    get<{
      data: z.infer<typeof GetCancellationPolicyPublicEndpoint.responseSchema>;
    }>,
  );
  if (cancellationPolicyData && !isLoading) {
    cancellationPolicy = cancellationPolicyData.data;
  }

  return {
    cancellationPolicy,
    isLoading,
  };
};

export default useCancellationPolicy;
