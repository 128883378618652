import useHosts from 'api/hosts/useHosts';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SkeletonLine } from 'components/theme/Skeleton/SkeletonLine';
import PeopleIcon from 'assets/icon-user';
import { getShortDescription } from 'utils';
import {
  Footer,
  HostedBy,
  Title4,
  DefaultUserAvatar,
  HostedByImageWrap,
  HostedByContent,
} from './styles';
import { ProfilePicture } from '../../atoms/ProfilePicture';

interface HostedContainerProps {
  hostedById: string;
}

const HostedContainer = ({ hostedById }: HostedContainerProps) => {
  const { t } = useTranslation();
  const { data, isLoading } = useHosts([hostedById]);

  if (isLoading || !data) {
    return <SkeletonLine translucent />;
  }
  if (!data) return null;

  const { firstName, lastName, profilePicture, description } = data[0];

  if (firstName === 'No Host' || !firstName) return null;

  return (
    <Footer className="featured-experience__item-footer">
      <Title4 className="featured-experience__item-hosted-by-label host">
        {t('hostedBy')} {`${firstName} ${lastName}`}
      </Title4>
      <HostedBy className="featured-experience__item-hosted-by-wrapper">
        {profilePicture ? (
          <HostedByImageWrap>
            <ProfilePicture
              className="featured-experience__item-hosted-by-image"
              src={profilePicture.uri['48w']}
              alt={t('profileImageOfHost', { hostName: firstName })}
            />
          </HostedByImageWrap>
        ) : (
          <DefaultUserAvatar>
            <PeopleIcon aria-label={t('hostUserIcon')} />
          </DefaultUserAvatar>
        )}
        <HostedByContent className="featured-experience__item-hosted-by-content">
          {getShortDescription(description, 150)}
        </HostedByContent>
      </HostedBy>
    </Footer>
  );
};

export default HostedContainer;
