import React from 'react';
import styled from 'styled-components';

type ApplePayIconProps = Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
  color?: string;
  scale?: number;
};

const StyledSvg = styled.svg.withConfig({
  shouldForwardProp: (prop) => prop !== 'scale',
})<{ scale?: number }>`
  color: var(--way-colors-svgColor);
  height: ${({ scale = 1 }) => 18 * scale}px;
  width: ${({ scale = 1 }) => 43 * scale}px;
`;

const ApplePayIcon = ({ color, scale = 1, ...props }: ApplePayIconProps) => (
  <StyledSvg
    fill="none"
    scale={scale}
    style={{ color }}
    viewBox="0 0 48 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.91076 2.57913C9.46584 1.88484 9.84252 0.95262 9.74317 0C8.93061 0.040404 7.93904 0.536074 7.36496 1.2309C6.84951 1.82592 6.39329 2.79718 6.51222 3.70986C7.42437 3.78898 8.33566 3.25393 8.91076 2.57913Z"
      style={{
        fill: 'currentColor',
        stroke: 'none',
      }}
    />
    <path
      d="M9.7327 3.8881C8.40805 3.80919 7.28177 4.6399 6.64919 4.6399C6.01625 4.6399 5.04755 3.92786 3.99982 3.94705C2.63614 3.96708 1.3708 4.73812 0.678792 5.96443C-0.744556 8.41765 0.303171 12.0566 1.6873 14.0547C2.35947 15.0431 3.16954 16.1315 4.23694 16.0924C5.24545 16.0529 5.64074 15.4394 6.86663 15.4394C8.09162 15.4394 8.44769 16.0924 9.51526 16.0726C10.6224 16.0528 11.3145 15.0837 11.9866 14.0942C12.7577 12.9674 13.0734 11.8794 13.0933 11.8196C13.0734 11.7999 10.9585 10.9885 10.9389 8.55569C10.9189 6.51864 12.5993 5.54971 12.6784 5.48962C11.7295 4.08611 10.2467 3.92786 9.7327 3.8881Z"
      style={{
        fill: 'currentColor',
        stroke: 'none',
      }}
    />
    <path
      d="M21.2667 1.13135C24.1458 1.13135 26.1506 3.11594 26.1506 6.00536C26.1506 8.90509 24.1045 10.9 21.1945 10.9H18.0067V15.9694H15.7036V1.13135L21.2667 1.13135ZM18.0067 8.96677H20.6494C22.6546 8.96677 23.7959 7.88721 23.7959 6.01567C23.7959 4.14433 22.6546 3.07488 20.6597 3.07488H18.0067V8.96677Z"
      style={{
        fill: 'currentColor',
        stroke: 'none',
      }}
    />
    <path
      d="M26.7524 12.8949C26.7524 11.0027 28.2023 9.84087 30.7732 9.69687L33.7344 9.52213V8.6893C33.7344 7.48617 32.922 6.7664 31.565 6.7664C30.2793 6.7664 29.4772 7.38324 29.2821 8.34994H27.1844C27.3078 6.39609 28.9735 4.95654 31.6471 4.95654C34.2691 4.95654 35.9451 6.34472 35.9451 8.51437V15.9694H33.8165V14.1905H33.7653C33.1382 15.3936 31.7704 16.1544 30.3515 16.1544C28.2332 16.1544 26.7524 14.8382 26.7524 12.8949ZM33.7344 11.9181V11.0646L31.0711 11.2291C29.7446 11.3217 28.9941 11.9078 28.9941 12.8332C28.9941 13.7791 29.7755 14.3961 30.9684 14.3961C32.521 14.3961 33.7344 13.3267 33.7344 11.9181Z"
      style={{
        fill: 'currentColor',
        stroke: 'none',
      }}
    />
    <path
      d="M37.9547 19.9488V18.1493C38.1189 18.1903 38.489 18.1903 38.6743 18.1903C39.7025 18.1903 40.2578 17.7585 40.597 16.648C40.597 16.6274 40.7926 15.99 40.7926 15.9796L36.8853 5.15186H39.2911L42.0266 13.954H42.0675L44.803 5.15186H47.1474L43.0957 16.5348C42.1706 19.157 41.1012 20.0002 38.8595 20.0002C38.6743 20.0002 38.1189 19.9796 37.9547 19.9488Z"
      style={{
        fill: 'currentColor',
        stroke: 'none',
      }}
    />
  </StyledSvg>
);

ApplePayIcon.displayName = 'ApplePayIcon';

export default ApplePayIcon;
