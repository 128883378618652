const buildCategoryUrl = (
  brandId: string,
  languageCode?: string,
  product?: string,
) => {
  const url = new URL(
    `${process.env.KOUTO_API_URL}/v1/brands/${brandId}/categories`,
  );
  if (product) {
    url.searchParams.set('product', product);
  }
  if (languageCode) {
    url.searchParams.set('language', languageCode);
  }
  return url;
};

export default buildCategoryUrl;
