import getDateDifference from 'utils/date/getDateDifference';
import isSameMonth from 'utils/date/isSameMonth';

const groupEventDatesByProximity = (
  dates: (Date | string)[] | null,
  maxDaysDiffToGroup: number,
): Date[][] => {
  if (!dates || dates.length === 0) return [];
  const today = new Date();
  const dateObjects = dates.map((dateStr) => new Date(dateStr));
  const futureDates = dateObjects.filter((date) => date >= today).sort();

  return futureDates.reduce<Date[][]>((groupedDates, currentDate) => {
    // If no groups yet, start with the first date
    if (!groupedDates.length) {
      groupedDates.push([currentDate]);
      return groupedDates;
    }

    const lastDateInGroup = groupedDates[groupedDates.length - 1].slice(-1)[0];
    const daysApart = getDateDifference(currentDate, lastDateInGroup);
    const sameMonth = isSameMonth(currentDate, lastDateInGroup);

    // Group dates that are close enough and in the same month
    if (daysApart <= maxDaysDiffToGroup && sameMonth) {
      groupedDates[groupedDates.length - 1].push(currentDate);
    } else {
      groupedDates.push([currentDate]);
    }

    return groupedDates;
  }, []);
};

export default groupEventDatesByProximity;
