import HyattSignIn from 'components/HyattLMS/HyattSignIn';
import HyattSignOut from 'components/HyattLMS/HyattSignOut';
import { CartIcon } from 'features/ShoppingCart/components/CartIcon/CartIcon';
import useHyattAuth from 'hooks/useHyattAuth';
import useIsHyattLMSEnabledFlag from 'hooks/useIsHyattLMSEnabledFlag';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BackLabel,
  HoverablePrevArrowIcon,
  IconBack,
  LeftAligned,
  LinksBack,
  NavigationHeaderContainer,
  RightAligned,
} from './NavigationHeader.styles';
import { useNavigationBack } from './useNavigationBack';

type Props = {
  goBackLabel?: string;
  showCart?: boolean;
  onGoBack?: () => void;
  preventDefaultBack?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

export const NavigationHeader: FC<Props> = ({
  goBackLabel,
  showCart = true,
  preventDefaultBack,
  className,
  onGoBack,
  style,
}) => {
  const { t: translate } = useTranslation();
  const label = useMemo(
    () => goBackLabel || translate('back'),
    [goBackLabel, translate],
  );
  const goBack = useNavigationBack();

  const hyattLMSEnabled = useIsHyattLMSEnabledFlag();

  const { verifySession } = useHyattAuth();

  const handleBackClick = useCallback(() => {
    onGoBack?.();
    if (!preventDefaultBack) goBack();
  }, [preventDefaultBack, onGoBack, goBack]);

  const renderHyattButton = () => {
    if (!hyattLMSEnabled) return null;
    return verifySession() ? <HyattSignOut /> : <HyattSignIn />;
  };

  return (
    <NavigationHeaderContainer
      role="navigation"
      style={style}
      className={className}
    >
      <LeftAligned>
        <LinksBack
          tabIndex={0}
          role="button"
          aria-label={translate('goBack')}
          title={label}
          className="header__back-button"
          onClick={handleBackClick}
        >
          <IconBack className="header__back-button-icon">
            <HoverablePrevArrowIcon />
          </IconBack>
          <BackLabel className="header-back-label">{label}</BackLabel>
        </LinksBack>
      </LeftAligned>
      <RightAligned>
        {renderHyattButton()}
        {showCart && <CartIcon />}
      </RightAligned>
    </NavigationHeaderContainer>
  );
};
