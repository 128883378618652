import { LanguageCode } from '@kouto/types';

const checkForSupportedLanguageCode = (
  languageCode: string,
): LanguageCode | undefined =>
  Object.values(LanguageCode).find(
    (code) => code === languageCode || code.startsWith(`${languageCode}-`),
  );

export default checkForSupportedLanguageCode;
