import { ANALYTICS_EVENT } from '@kouto/types';
import { Stripe } from '@stripe/stripe-js';
import { useAppState, useBrandId, useDispatch } from 'AppProvider';
import { useToastContext } from 'ToastProvider';
import { getBookingContext } from 'actions/booking';
import { resetCart } from 'actions/cart';
import { filterEmpty } from 'actions/helpers';
import { Modal } from 'components/Modal';
import { NavigationHeader } from 'components/NavigationHeader';
import { Flex } from 'components/common/styled/common-styled';
import LoadingIndicator from 'components/theme/LoadingIndicator';
import { SkeletonLine } from 'components/theme/Skeleton/SkeletonLine';
import { Wrapper } from 'components/theme/Wrapper';
import { EmptyCartError } from 'features/ShoppingCart/components/EmptyCartError';
import { PaymentContext } from 'features/ShoppingCart/contexts/PaymentContext';
import { useTrackCartParticipants } from 'features/ShoppingCart/hooks';
import {
  ConfirmCardPaymentCallback,
  SubmitCardPaymentCallback,
} from 'features/ShoppingCart/types';
import {
  ApprovalStatus,
  kiccApprovalPolling,
} from 'features/ShoppingCart/utils/kiccApprovalPolling';
import * as Styled from 'features/ShoppingCart/utils/styles';
import { useAnalyticsReadyForCartData } from 'hooks/use-analytics-ready-for-cart-data';
import useCustomHistory from 'hooks/use-custom-history';
import { useExperienceFilterParam } from 'hooks/use-experience-filter-param';
import useSearchQueryParams from 'hooks/use-search-params';
import useCartItemsListings from 'hooks/useCartItemsListings';
import { usePageViewEvent } from 'hooks/usePageViewEvent';
import { useTotalCartAmountTracker } from 'hooks/useTotalCartAmountTracker';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useTotalCartAmount from 'selectors/cart';
import useFetchExperienceList from 'selectors/experienceList';
import styled from 'styled-components';
import { ValidationError } from 'types/api.types';
import { STATUS_PENDING, STATUS_SUCCESS } from 'types/app';
import { GET_BOOKING_CONTEXT_FAILURE } from 'types/booking';
import { serializeParams } from 'utils';
import CheckoutPage from './CheckoutPage';

const Checkout = () => {
  useTotalCartAmountTracker();
  useTrackCartParticipants(true);
  const brandId = useBrandId();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();
  const { searchParams, setParam } = useSearchQueryParams();
  const trackingReady = useAnalyticsReadyForCartData();
  const experienceFilterParam = useExperienceFilterParam();
  const { push: customPush } = useCustomHistory();
  const { showToast } = useToastContext();
  const { experienceList } = useFetchExperienceList();
  const { cartItems, listings, isLoading } = useCartItemsListings();
  const cartTotalDetails = useTotalCartAmount(cartItems, listings);
  const isCartEmpty = Object.keys(cartItems).length === 0;
  const [isWaitingConfirmation, setIsWaitingConfirmation] = useState(false);
  const [submitCardPaymentCallback, setSubmitCardPaymentCallback] =
    useState<SubmitCardPaymentCallback | null>(null);
  const [confirmCardPaymentCallback, setConfirmCardPaymentCallback] =
    useState<ConfirmCardPaymentCallback | null>(null);
  const [getPaymentIntentInfoCallback, setPaymentIntentInfoCallback] = useState<
    Stripe['retrievePaymentIntent'] | null
  >(null);
  const [bookingApprovalError, setBookingApprovalError] = useState<
    ValidationError[] | undefined
  >();
  const { fetchSettingsStatus, settings } = useAppState(
    (state: Record<string, unknown>) => state.brand,
  );

  const paymentContextValue = useMemo(
    () => ({
      ...cartTotalDetails,
      confirmCardPaymentCallback,
      setConfirmCardPaymentCallback,
      submitCardPaymentCallback,
      getPaymentIntentInfoCallback,
      setPaymentIntentInfoCallback,
      setSubmitCardPaymentCallback,
    }),
    [
      cartTotalDetails,
      confirmCardPaymentCallback,
      setConfirmCardPaymentCallback,
      submitCardPaymentCallback,
      getPaymentIntentInfoCallback,
      setPaymentIntentInfoCallback,
      setSubmitCardPaymentCallback,
    ],
  );

  usePageViewEvent({
    eventName: ANALYTICS_EVENT.VIEW_CHECKOUT,
    isNotReady: !trackingReady,
  });

  const { contextFetchStatus } = useAppState(
    (state: Record<string, unknown>) => state.booking,
  );

  const fetchContextAndConfirmBookingForKICC = async (cartId: string) => {
    if (!cartId) {
      return;
    }
    const contextResponse = await dispatch(getBookingContext(cartId));
    if (contextResponse.type === GET_BOOKING_CONTEXT_FAILURE) {
      showToast({
        type: 'failure',
        title: 'Action Failed',
        message: contextResponse.error,
      });

      setBookingApprovalError([
        {
          message: contextResponse.error,
          code: 'Booking Failed',
        },
      ]);
      return;
    }
    const { purchaser } = contextResponse.payload?.response || {};
    setIsWaitingConfirmation(true);
    const approvalResponse = await kiccApprovalPolling({
      brandId,
      subjectId: cartId,
    });

    setIsWaitingConfirmation(false);

    if (approvalResponse.status === ApprovalStatus.FAILED) {
      showToast({
        type: 'failure',
        title: 'Booking Failed',
        message: approvalResponse.errorMessage,
      });
      setBookingApprovalError([
        {
          message: approvalResponse.errorMessage,
          code: 'Booking Failed',
        },
      ]);
      setParam('cartId', null);

      return;
    }
    dispatch(resetCart(brandId));
    localStorage.removeItem('cart');
    const bookingSuccessConfirmationParams = {
      status: STATUS_SUCCESS,
      emailAddress:
        purchaser.primaryContactEmailAddress || purchaser.emailAddress,
      lang: searchParams?.lang,
    };
    customPush({
      pathname: `/e/booking/confirmation`,
      search: serializeParams(filterEmpty(bookingSuccessConfirmationParams)),
      unset: [
        'email',
        'emailAddress',
        'payment_intent',
        'redirect_status',
        'payment_intent_client_secret',
      ],
    });
  };

  useEffect(() => {
    fetchContextAndConfirmBookingForKICC(searchParams.cartId);
  }, [searchParams.cartId]);

  useEffect(() => {
    if (!isLoading && isCartEmpty) {
      history.push('/e');
    }
  }, [isCartEmpty, isLoading, history]);

  if (
    isLoading ||
    fetchSettingsStatus === STATUS_PENDING ||
    contextFetchStatus === STATUS_PENDING
  ) {
    return (
      <Wrapper>
        <SkeletonLine style={{ height: 100, width: 600 }} translucent />
        <SkeletonLine style={{ height: 300, width: 600 }} translucent />
      </Wrapper>
    );
  }

  if (isCartEmpty) {
    return <EmptyCartError />;
  }

  return (
    <PaymentContext.Provider value={paymentContextValue}>
      <Wrapper>
        <Helmet>
          {settings?.name && (
            <title>Experience Cart Checkout | {settings?.name}</title>
          )}
        </Helmet>
        <Modal
          isVisible={isWaitingConfirmation}
          onClose={() => setIsWaitingConfirmation(false)}
          isClosable={false}
          title="Payment processing"
          content={
            <Flex direction="column" gap={18}>
              <ModalPara>
                This can take up to 30 seconds. Please do not close or refresh
                your browser.
              </ModalPara>
              <LoadingWrapper>
                <LoadingIndicator height="30" width="30" />
              </LoadingWrapper>
            </Flex>
          }
          isDarkMode
        />
        <NavigationHeader
          preventDefaultBack
          goBackLabel={translate('browseAllExperieces')}
          onGoBack={() =>
            history.push({
              pathname: '/e',
              search: `?${experienceFilterParam}`,
            })
          }
        />
        <Styled.FormWrapper>
          <CheckoutPage
            experienceList={experienceList}
            bookingApprovalError={bookingApprovalError}
          />
        </Styled.FormWrapper>
      </Wrapper>
    </PaymentContext.Provider>
  );
};

const ModalPara = styled.p`
  color: var(--way-palette-white-100);
  font-size: 14px;
  text-align: center;
  line-height: 22px;
  margin: 0;
`;

const LoadingWrapper = styled.div`
  width: 56px;
  height: 56px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  background-color: var(--way-palette-white-5);
`;

export default Checkout;
