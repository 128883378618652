import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useEventBookingSession from 'features/Events/hooks/useEventBookingSession';
import { SkeletonLineSlim } from 'components/theme/Skeleton/Skeleton';
import { getFormattedTimes } from 'utils/session';

const EventLocation: FC = () => {
  const { event } = useEventBookingSession();
  const { t: translate } = useTranslation();

  return (
    <Wrapper>
      <Where>
        <Title>{translate('location')}</Title>
        <Label>{event ? event.location?.name : <SkeletonLineSlim />}</Label>
        {event?.location.additionalInfo && (
          <Label>{event.location.additionalInfo}</Label>
        )}
      </Where>
      <When>
        <Title>{translate('time')}</Title>
        <Label>
          {event?.startTimes ? (
            getFormattedTimes(event.startTimes)
          ) : (
            <SkeletonLineSlim />
          )}
        </Label>
      </When>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  padding: 48px 0px;
  border-bottom: solid 0.5px var(--way-colors-borderColor);
  display: flex;
  gap: 24px;
`;

const Where = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 8px;
`;
const When = styled(Where)``;

const Title = styled.span`
  font-weight: bold;
  color: var(--way-colors-primaryTextColor);
`;

const Label = styled.span`
  color: ${({ theme }) => `${theme.colors.primaryTextColor}99`};
`;

export default EventLocation;
