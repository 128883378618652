import { ProductLine, BookingAvailabilityModeValue } from '@kouto/types';
import React from 'react';
import styled from 'styled-components';
import getSameMonthDateLabels from 'features/LandingPage/getSameMonthDateLabels';
import { useFormatPrice } from 'utils/listingsHelpers';

const DetailsLabel = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: var(--way-colors-contrastColorShades-80);
`;

interface ListingItemCardDetailsLabelProps {
  bookingAvailabilityMode: BookingAvailabilityModeValue | null;
  dates: Date[] | null;
  hidePrice: boolean | null;
  maxParticipants: number | null;
  productLine: ProductLine;
  showYear?: boolean;
  startingPrice: number | null;
}

const ListingItemCardDetailsLabel = ({
  bookingAvailabilityMode,
  dates,
  hidePrice,
  maxParticipants,
  productLine,
  showYear,
  startingPrice,
}: ListingItemCardDetailsLabelProps) => {
  const priceDisplay = useFormatPrice({
    bookingAvailabilityMode,
    maxParticipants,
    startingPrice,
  });

  const dateLabels = getSameMonthDateLabels(dates);
  // eslint-disable-next-line no-nested-ternary
  const displayedDate = dateLabels
    ? showYear
      ? `${dateLabels?.month} ${dateLabels?.day}, ${dateLabels?.year}`
      : `${dateLabels?.month} ${dateLabels?.day}`
    : '';

  return (
    <DetailsLabel className="listing-card-details">
      {productLine === ProductLine.ACTIVATE &&
        displayedDate &&
        `${displayedDate} • `}
      {!hidePrice && priceDisplay}
    </DetailsLabel>
  );
};

export default ListingItemCardDetailsLabel;
