import { useBrandId, useAppState } from 'AppProvider';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { Question, QUESTION_SCOPES } from 'types/custom-questions';
import { IExperienceFields } from 'types/experience.types';
import { get } from 'utils/axios';
import useSupportedLanguage from 'utils/listingsHelpers/useSupportedLanguage';

const groupByHierarchy = R.groupBy<Question>((question: Question) =>
  question.config.conditions?.length ? 'conditional' : 'parent',
);

const useCustomQuestions = () => {
  const brandId = useBrandId();
  const languageCode = useSupportedLanguage();

  const [state, setState] = useState<{
    participantParentQuestions: Question[];
    participantConditionalQuestions: Question[];
    purchaserParentQuestions: Question[];
    purchaserConditionalQuestions: Question[];
  }>({
    participantParentQuestions: [],
    participantConditionalQuestions: [],
    purchaserParentQuestions: [],
    purchaserConditionalQuestions: [],
  });
  const {
    experience,
  }: { experience: IExperienceFields & { loading?: boolean } } = useAppState(
    (state: Record<string, unknown>) => state.experience,
  );

  const url =
    brandId && !experience?.loading && experience.id
      ? `v1/brands/${brandId}/experiences/${
          experience.id
        }/custom-questions?isActive=true${
          languageCode ? `&language=${languageCode}` : ''
        }`
      : null;

  const { data, error, isLoading } = useSWR(url, get<{ items: Question[] }>);

  useEffect(() => {
    const allParticipantQuestionsResponse =
      data?.items.filter(
        (q) => q.config.scope === QUESTION_SCOPES.PARTICIPANT,
      ) ?? [];
    const allPurchaserQuestionsResponse =
      data?.items.filter((q) => q.config.scope === QUESTION_SCOPES.PURCHASER) ??
      [];

    const groupedParticipantQuestions = groupByHierarchy(
      allParticipantQuestionsResponse,
    );
    const groupedPurchaserQuestions = groupByHierarchy(
      allPurchaserQuestionsResponse,
    );

    if (data?.items.length) {
      setState({
        participantParentQuestions: groupedParticipantQuestions.parent || [],
        participantConditionalQuestions:
          groupedParticipantQuestions.conditional || [],
        purchaserParentQuestions: groupedPurchaserQuestions.parent || [],
        purchaserConditionalQuestions:
          groupedPurchaserQuestions.conditional || [],
      });
    }

    if (error) {
      console.error(error);
    }
  }, [data, error]);

  return { loading: experience?.loading || isLoading, ...state };
};

export default useCustomQuestions;
