import { BottomSheetDatePicker } from 'components/BottomDrawer/BottomSheetDatePicker';
import { BottomSheetSessionSelector } from 'components/BottomDrawer/BottomSheetSessionSelector';
import { Flex, SrOnly } from 'components/common/styled/common-styled';
import { DateSelector } from 'components/DatePicker/DateSelector';
import { selectStyles } from 'components/ExperienceView/styles';
import { SessionTimeOption } from 'features/Reserve/hooks/useSessionTimeOptions';
import moment from 'moment';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Select, { ControlProps, StylesConfig, components } from 'react-select';
import styled from 'styled-components';
import { Session } from 'types/listings';
import { DATE_FORMAT } from 'utils';
import { useIsMobile } from 'WindowDimensionProvider';

export interface SessionSelectorProps {
  sessionsTimeOptions: SessionTimeOption[];
  selectedDate: string;
  selectedTime: string;
  selectedDuration: number;
  availableDates: string[];
  isLoadingAvailableDates: boolean;
  onMonthChange: (date: moment.Moment) => void;
  onChangeSelectedDate: (date: string) => void;
  onChangeSelectedSession: (time: string, duration: number) => void;
}

const ResourceSessionSelector: FC<SessionSelectorProps> = ({
  sessionsTimeOptions,
  selectedDate,
  selectedTime,
  selectedDuration,
  isLoadingAvailableDates,
  availableDates,
  onMonthChange,
  onChangeSelectedDate,
  onChangeSelectedSession,
}) => {
  const { t: translate } = useTranslation();
  const selectControllerRef = useRef<HTMLDivElement>(null);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const handleDateSelectorFocusChange = ({ focused }: { focused: boolean }) =>
    setShowDatePicker(focused);

  useEffect(() => {
    selectControllerRef.current?.children?.[0]?.setAttribute(
      'aria-label',
      translate('selectSession'),
    );
  }, [selectControllerRef, translate]);

  const selectedSessionOption = useMemo(
    () =>
      sessionsTimeOptions.find((timeOption) => {
        const [sessionTime, sessionDuration] = timeOption.value.split('-');
        return (
          selectedTime === sessionTime &&
          selectedDuration === parseInt(sessionDuration, 10)
        );
      }),
    [sessionsTimeOptions, selectedTime, selectedDuration],
  );

  const isMobile = useIsMobile();

  const handleSessionSelect = (session: Session) => {
    onChangeSelectedSession(
      session.startDateTime.split('T')[1],
      session.duration,
    );
  };

  const selectedSession = useMemo(() => {
    return sessionsTimeOptions.find(
      (o) => o.value === `${selectedTime}-${selectedDuration}`,
    )?.original;
  }, [sessionsTimeOptions, selectedTime, selectedDuration]);

  const Control = useCallback(
    (props: ControlProps<Omit<SessionTimeOption, 'original'>, false>) => {
      return (
        <div ref={selectControllerRef}>
          <components.Control
            {...props}
            aria-label={translate('selectSession')}
          />
        </div>
      );
    },
    [translate],
  );

  return (
    <SelectWrapper
      direction="row"
      gap={12}
      alignItem="center"
      justifyContent="flex-start"
    >
      {isMobile ? (
        <BottomSheetDatePicker
          availableDates={availableDates}
          onChange={(option) => {
            if (option) {
              onChangeSelectedDate(moment(option as Date).format(DATE_FORMAT));
            }
          }}
          onMonthChange={onMonthChange}
          onOpenChange={setShowDatePicker}
          open={showDatePicker}
          value={selectedDate}
        />
      ) : (
        <DateSelector
          date={selectedDate}
          loading={isLoadingAvailableDates}
          availableDates={availableDates}
          onMonthChange={onMonthChange}
          onChange={onChangeSelectedDate}
          onFocusChange={handleDateSelectorFocusChange}
          showDatePicker={showDatePicker}
        />
      )}

      {isMobile ? (
        <BottomSheetSessionSelector
          sessions={sessionsTimeOptions.map((o) => o.original)}
          onSessionClick={handleSessionSelect}
          selectedSession={selectedSession}
        />
      ) : (
        <StyledLabel>
          <Select<Omit<SessionTimeOption, 'original'>, false>
            components={{
              Control,
            }}
            styles={resourceSelectStyles}
            className="resource-item__session-times"
            classNamePrefix="resource-item-sessions"
            placeholder={translate(
              !sessionsTimeOptions.length ? 'unavailable' : 'select',
            )}
            isSearchable={false}
            isDisabled={!sessionsTimeOptions.length}
            options={sessionsTimeOptions}
            onChange={(option) => {
              if (option) {
                const [sessionTime, sessionDuration] = option.value.split('-');
                onChangeSelectedSession(
                  sessionTime,
                  parseInt(sessionDuration, 10),
                );
              }
            }}
            value={selectedSessionOption}
          />
          <SrOnly>{translate('selectSession')}</SrOnly>
        </StyledLabel>
      )}
    </SelectWrapper>
  );
};

const StyledLabel = styled.label`
  margin-bottom: 0;
`;

export const resourceSelectStyles: StylesConfig<
  { label: string; value: string },
  false
> = {
  ...selectStyles,
  control: (styles) => ({
    ...selectStyles.control(styles),
    minHeight: 48,
    width: '100%',
    padding: '12px 14px',
    borderColor: 'var(--way-colors-borderColor)',
    fontSize: 14,
    fontWeight: 500,
    borderRadius: 'var(--way-design-borderRadiusDefault)',
    lineHeight: 'normal',
  }),
  menu: (provided, state) => ({
    ...selectStyles.menu(provided, state),
    borderRadius: 'var(--way-design-borderRadiusDefault)',
  }),
  menuList: (provided, state) => ({
    ...selectStyles.menuList(provided, state),
    padding: 0,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),

  valueContainer: () => ({
    padding: 0,
    marginRight: 6,
  }),
  option: (styles, props) => ({
    ...selectStyles.option(styles, props),
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 'normal',
    padding: 12,
    borderRadius: 0,
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    color: 'var(--way-colors-contrastColorShades-60)',
  }),
};

const SelectWrapper = styled(Flex)`
  & > * {
    flex: 1;
  }

  & .resource-item-sessions__control {
    &:hover {
      border-color: var(--way-palette-black-40);
      background: var(--way-palette-black-5);
    }
  }

  & .resource-item-sessions__indicator {
    padding: 0;
  }
`;

export default ResourceSessionSelector;
