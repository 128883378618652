import useSWR from 'swr';
import { useBrandId } from 'AppProvider';
import fetchSessions from './fetchSessions';
import buildSessionsUrl from './buildSessionsUrl';

const fetchSessionsSWR = ([url, { brandId }]: [string, { brandId: string }]) =>
  fetchSessions(url, { brandId });

const useSessions = (
  listingId: string,
  {
    from,
    to,
    skip = false,
    resourceGroupId,
  }: { from: Date; to: Date; skip?: boolean; resourceGroupId?: string },
) => {
  const brandId = useBrandId();
  const url = !skip
    ? buildSessionsUrl(listingId, { from, to, resourceGroupId })
    : null;
  const swr = useSWR(url ? [url, { brandId }] : null, fetchSessionsSWR);

  return {
    ...swr,
    sessions: swr.data?.sessions ?? [],
  };
};

export default useSessions;
