import TRANSLATION_ACCESSIBILITY_EN from './accessibility/translation';
import TRANSLATION_BOOKING_EN from './booking/translation';
import TRANSLATION_EVENTS_EN from './events/translation';
import TRANSLATION_EXPERIENCES_EN from './experiences/translation';
import TRANSLATION_FORM_EN from './form/translation';
import TRANSLATION_LANDING_PAGES_EN from './landingPages/translation';
import TRANSLATION_RESERVE_EN from './reserve/translation';
import TRANSLATION_SIGNIN_EN from './singin/translation';
import TRANSLATION_DATES_EN from './weekdays/translation';

export default {
  ...TRANSLATION_ACCESSIBILITY_EN,
  ...TRANSLATION_DATES_EN,
  ...TRANSLATION_BOOKING_EN,
  ...TRANSLATION_FORM_EN,
  ...TRANSLATION_EXPERIENCES_EN,
  ...TRANSLATION_LANDING_PAGES_EN,
  ...TRANSLATION_EVENTS_EN,
  ...TRANSLATION_RESERVE_EN,
  ...TRANSLATION_SIGNIN_EN,
};
