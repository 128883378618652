import TRANSLATION_ACCESSIBILITY_DE from './accessibility/translation';
import TRANSLATION_BOOKING_DE from './booking/translation';
import TRANSLATION_EVENTS_DE from './events/translation';
import TRANSLATION_EXPERIENCES_DE from './experiences/translation';
import TRANSLATION_FORM_DE from './form/translation';
import TRANSLATION_LANDING_PAGES_DE from './landingPages/translation';
import TRANSLATION_RESERVE_DE from './reserve/translation';
import TRANSLATION_SIGNIN_DE from './singin/translation';
import TRANSLATION_DATES_DE from './weekdays/translation';

export default {
  ...TRANSLATION_DATES_DE,
  ...TRANSLATION_BOOKING_DE,
  ...TRANSLATION_FORM_DE,
  ...TRANSLATION_EXPERIENCES_DE,
  ...TRANSLATION_LANDING_PAGES_DE,
  ...TRANSLATION_EVENTS_DE,
  ...TRANSLATION_RESERVE_DE,
  ...TRANSLATION_ACCESSIBILITY_DE,
  ...TRANSLATION_SIGNIN_DE,
};
