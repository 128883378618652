import TRANSLATION_ACCESSIBILITY_KO from './accessibility/translation';
import TRANSLATION_BOOKING_KO from './booking/translation';
import TRANSLATION_EVENTS_KO from './events/translation';
import TRANSLATION_EXPERIENCES_KO from './experiences/translation';
import TRANSLATION_FORM_KO from './form/translation';
import TRANSLATION_LANDING_PAGES_KO from './landingPages/translation';
import TRANSLATION_RESERVE_KO from './reserve/translation';
import TRANSLATION_SIGNIN_KO from './singin/translation';
import TRANSLATION_DATES_KO from './weekdays/translation';

export default {
  ...TRANSLATION_DATES_KO,
  ...TRANSLATION_BOOKING_KO,
  ...TRANSLATION_FORM_KO,
  ...TRANSLATION_EXPERIENCES_KO,
  ...TRANSLATION_LANDING_PAGES_KO,
  ...TRANSLATION_EVENTS_KO,
  ...TRANSLATION_RESERVE_KO,
  ...TRANSLATION_ACCESSIBILITY_KO,
  ...TRANSLATION_SIGNIN_KO,
};
