import React, { useCallback } from 'react';
import { NonMappedGroupContent } from 'features/Reserve/pages/CollectionPage/NonMapped/NonMappedGroupContent';
import useResourceBookingSession from 'features/Reserve/hooks/useResourceBookingSession';
import {
  ResourceHeading2,
  ResourceParagraph,
} from 'components/theme/Typography/ResourceTypography';
import moment from 'moment/moment';
import { ResponsiveFlex } from 'components/common/styled/common-styled';

export const NonMappedResourceGroupPage: React.FC = () => {
  const { setResourceBookingSessionParams, resourceGroup, date } =
    useResourceBookingSession();
  const deselectResourceGroup = useCallback(() => {
    setResourceBookingSessionParams({
      groupId: undefined,
    });
  }, [setResourceBookingSessionParams]);

  const Header = !resourceGroup ? null : (
    <ResponsiveFlex direction="column" alignItem="flex-start" gap={12}>
      <ResourceHeading2 className="resource-title">
        {resourceGroup.title}
      </ResourceHeading2>
      <ResourceParagraph
        className="current-date"
        fontSize="16px"
        fontWeight="400"
        lineHeight="20px"
      >
        {moment(date).format('dddd, MMMM Do')}
      </ResourceParagraph>
    </ResponsiveFlex>
  );

  return (
    <NonMappedGroupContent
      heading={Header}
      size="full"
      open
      onClose={deselectResourceGroup}
    />
  );
};
