import { ListingV2 } from 'types/listings';
import { MediaLinkResolution, MediaType } from '@kouto/types';

export const getBiggerMediaPreviewLink = (
  media: NonNullable<ListingV2['medias']>[number],
) => {
  if (!media?.links?.length) return '';

  const mediaLinks = media.links.filter((l) => l.type === MediaType.IMAGE);

  const originalResolution = mediaLinks.find(
    (link) => link.resolution === MediaLinkResolution.ORIGINAL,
  );
  if (originalResolution) return originalResolution.url;

  const highestResolution = [...mediaLinks].sort(
    (a, b) => Number(b.resolution) - Number(a.resolution),
  )[0];

  return highestResolution?.url || '';
};
