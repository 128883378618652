import { Listing } from 'types/listings';
import ApiResponse from 'api/types/ApiResponse';
import { get } from '../api';

const fetchListing = async (url: string, { brandId }: { brandId: string }) => {
  const response = await get<ApiResponse<Listing>>(url, {
    headers: {
      'Kouto-Brand-Id': brandId,
      'Way-Brand-Id': brandId,
    },
  });

  return response.data;
};

export default fetchListing;
