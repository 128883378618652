interface ReserveResourceUrlParams {
  collectionId: string | null;
  groupId: string;
  resourceId: string | null;
  date?: string;
  time?: string;
  duration?: number;
}

const buildReserveResourceUrl = ({
  collectionId,
  groupId,
  resourceId,
  date = '',
  time = '',
  duration,
}: ReserveResourceUrlParams) => {
  const searchParams = new URLSearchParams({
    date,
    time,
    ...(duration && { duration: duration.toString() }),
  });

  if (!resourceId) return undefined;

  return `#!/collection/${collectionId}/group/${groupId}/resource/${resourceId}?${searchParams.toString()}`;
};

export default buildReserveResourceUrl;
