import { post } from 'api/api';
import { z } from 'zod';
import { getHyattValidateListingsUrl } from './hyattUrls';

const ValidateListingsResponseSchema = z.object({
  data: z.object({
    validListingIds: z.array(z.string().uuid()),
    invalidListingIds: z.array(z.string().uuid()),
  }),
});
export type ValidateListingsResponse = z.infer<
  typeof ValidateListingsResponseSchema
>;

const validateHyattExperienceIsEligible = async (
  listingIds: string[],
  brandId: string,
) => {
  const url = getHyattValidateListingsUrl();
  const data = await post(url, {
    body: JSON.stringify({
      listingIds,
    }),
    headers: {
      'Kouto-Brand-Id': brandId,
      'Way-Brand-Id': brandId,
    },
  });
  return ValidateListingsResponseSchema.parse(data);
};

export default validateHyattExperienceIsEligible;
