import React from 'react';
import styled from 'styled-components';

type TrashIconProps = Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
  color?: string;
  scale?: number;
};

const StyledSvg = styled.svg.withConfig({
  shouldForwardProp: (prop) => prop !== 'scale',
})<{ scale?: number }>`
  color: var(--way-colors-svgColor);
  height: ${({ scale = 1 }) => 21 * scale}px;
  width: ${({ scale = 1 }) => 21 * scale}px;
`;

const TrashIcon = ({ color, scale = 1, ...props }: TrashIconProps) => (
  <StyledSvg
    fill="none"
    scale={scale}
    style={{ color }}
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.06999 2.64355C8.73285 2.64355 8.45954 2.91686 8.45954 3.254V3.86445H5.00034C4.6632 3.86445 4.38989 4.13776 4.38989 4.4749C4.38989 4.81204 4.6632 5.08535 5.00034 5.08535H16.3954C16.7325 5.08535 17.0058 4.81204 17.0058 4.4749C17.0058 4.13776 16.7325 3.86445 16.3954 3.86445H12.9362V3.254C12.9362 2.91686 12.6628 2.64355 12.3257 2.64355H9.06999Z"
      style={{
        fill: 'currentColor',
        stroke: 'none',
      }}
    />
    <path
      d="M9.0709 9.47998C9.40803 9.47998 9.68134 9.7533 9.68134 10.0904V15.7879C9.68134 16.1251 9.40803 16.3984 9.0709 16.3984C8.73376 16.3984 8.46045 16.1251 8.46045 15.7879V10.0904C8.46045 9.7533 8.73376 9.47998 9.0709 9.47998Z"
      style={{
        fill: 'currentColor',
        stroke: 'none',
      }}
    />
    <path
      d="M12.9362 10.0904C12.9362 9.7533 12.6629 9.47998 12.3258 9.47998C11.9886 9.47998 11.7153 9.7533 11.7153 10.0904V15.7879C11.7153 16.1251 11.9886 16.3984 12.3258 16.3984C12.6629 16.3984 12.9362 16.1251 12.9362 15.7879V10.0904Z"
      style={{
        fill: 'currentColor',
        stroke: 'none',
      }}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.80797 7.25593C5.84232 6.94677 6.10363 6.71289 6.41469 6.71289H14.9824C15.2934 6.71289 15.5548 6.94677 15.5891 7.25593L15.752 8.72209C16.0473 11.3798 16.0473 14.0621 15.752 16.7198L15.7359 16.8642C15.6187 17.9192 14.8011 18.7604 13.7499 18.9076C11.7255 19.191 9.67151 19.191 7.64717 18.9076C6.59595 18.7604 5.77832 17.9192 5.6611 16.8642L5.64507 16.7198C5.34976 14.0621 5.34976 11.3798 5.64507 8.72209L5.80797 7.25593ZM6.96106 7.93379L6.85849 8.85692C6.57314 11.4251 6.57314 14.0169 6.85849 16.5851L6.87452 16.7294C6.93012 17.2297 7.31789 17.6287 7.81645 17.6985C9.72849 17.9661 11.6686 17.9661 13.5806 17.6985C14.0792 17.6287 14.4669 17.2297 14.5225 16.7294L14.5386 16.5851C14.8239 14.0169 14.8239 11.4251 14.5386 8.85692L14.436 7.93379H6.96106Z"
      style={{
        fill: 'currentColor',
        stroke: 'none',
      }}
    />
  </StyledSvg>
);

TrashIcon.displayName = 'TrashIcon';

export default TrashIcon;
