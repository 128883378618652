import RadioField from 'components/theme/RadioField/RadioField';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PaymentMethod } from 'types/payment';

const paymentLabel = {
  [PaymentMethod.CREDIT_CARD]: 'creditCard',
  [PaymentMethod.ROOM_CHARGE]: 'roomCharge',
  [PaymentMethod.MEMBER_NUMBER]: 'memberCharge',
  [PaymentMethod.KICC]: 'kicc',
  [PaymentMethod.HYATT_POINTS]: 'worldOfHyattPoints',
};

interface IAccordionProps {
  onSelectOption(): void;
  selected: boolean;
  charge: string;
}

const PaymentAccordion = ({
  onSelectOption,
  selected,
  charge,
  children,
}: PropsWithChildren<IAccordionProps>) => {
  const { t: translateText } = useTranslation();

  return (
    <PaymentAccordionItem>
      <AccordionHeader id={charge} onClick={onSelectOption}>
        <RadioField
          id={charge}
          label={translateText(
            paymentLabel[charge as keyof typeof paymentLabel],
          )}
          checked={selected}
          onChange={() => onSelectOption()}
        />
      </AccordionHeader>
      {selected ? children : null}
    </PaymentAccordionItem>
  );
};

const PaymentAccordionItem = styled.div`
  padding: 12px 0px;
`;

const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

export default PaymentAccordion;
