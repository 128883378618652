import React from 'react';
import styled from 'styled-components';
import { formatListingMedia, listingMediaIsVideo } from 'utils/listings';
import { ListingV2 } from 'types/listings';
import { useIsMobile } from 'WindowDimensionProvider';
import { FeaturedExclusiveStar } from '../../atoms/FeaturedExclusiveStar';
import { FeaturedExclusiveOverlay } from '../../atoms/FeaturedExlusiveOverlay';
import { StyledLink, WrapperImage, WrapperImageRatio } from './styles';

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  position: absolute;
`;

interface FeaturedImageContainerProps {
  exclusiveTag?: string;
  isExclusive: boolean;
  isHovered: boolean;
  title: string;
  medias: ListingV2['medias'];
}

const FeaturedImageContainer = ({
  exclusiveTag,
  isExclusive,
  isHovered,
  title,
  medias,
}: FeaturedImageContainerProps) => {
  const isMobile = useIsMobile();

  const formattedMedias = formatListingMedia(medias).map((media) => ({
    ...media,
    alt: title,
  }));

  const media =
    formattedMedias.find((media) => media.kind === 'cover') ||
    formattedMedias[0];

  if (!media) {
    return null;
  }

  // eslint-disable-next-line no-nested-ternary
  const displayMedia = listingMediaIsVideo(media!)
    ? isMobile
      ? media?.previewDesktop
      : media?.previewMobile
    : isMobile
    ? media?.urlMobile
    : media?.urlDesktop;

  return (
    <WrapperImage className="featured-experience__item-image-wrapper">
      <WrapperImageRatio>
        <StyledLink>
          {isExclusive && isHovered && (
            <FeaturedExclusiveOverlay exclusiveTag={exclusiveTag} />
          )}
          {isExclusive && <FeaturedExclusiveStar />}
          <StyledImage
            loading="lazy"
            className="featured-experience__item-image-responsive-image"
            src={displayMedia}
            alt={media.alt}
          />
        </StyledLink>
      </WrapperImageRatio>
    </WrapperImage>
  );
};

export default FeaturedImageContainer;
