import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  height: 18px;
`;

const AliPayIcon = () => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 16">
    <path
      d="M10.476 9.115a78.809 78.809 0 006.061 2.084c1.121.46 0 3.07-1.184 2.531-1.34-.577-4.035-1.757-6.085-2.766C8.128 12.289 6.338 14 3.788 14 1.522 14 0 12.682 0 10.719c0-1.654 1.157-3.324 3.74-3.324 1.48 0 3 .415 4.818 1.04.336-.666.616-1.361.838-2.078l-6.57-.003V5.353l3.379.008V4.05H2.088v-.986l4.117.003V1.604c0-.383.208-.604.571-.604H8.5v2.073l4.08.003v.977H8.5V5.37l3.339.009s-.413 2.115-1.361 3.736zm-9.549 1.52v-.001c0 .94.741 1.89 2.545 1.89 1.393 0 2.757-.824 4.062-2.45-1.744-.862-2.679-1.277-4.03-1.277-1.312 0-2.577.631-2.577 1.839z"
      fill="#1C9FE5"
    />
  </StyledSvg>
);

AliPayIcon.displayName = 'AliPayIcon';

export default AliPayIcon;
