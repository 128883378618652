import {
  ANALYTICS_PROPERTY,
  IPriceTier,
  type IPublicExperienceDetailResponse,
  ListingKind,
  ProductLine,
} from '@kouto/types';
import format from 'date-fns/fp/format';
import type { Vibe, Category } from 'types/reducer-states/brandState';
import type { IFullExperienceFields } from 'types/experience.types';
import {
  AnalyticsProduct,
  AnalyticsLandingPage,
} from 'features/analytics/types/analytics';
import { Collection, Listing, ResourceGroup, Session } from 'types/listings';
import useGetListings from 'features/LandingPage/hooks/useGetListings';
import { ICartExperience } from 'types/cart';
import useListingsV2 from 'api/listing/useListingsV2';
import { analyticsManager } from './AnalyticsManager';

export const IsBookableMapper: Record<string, string> = {
  true: 'Yes',
  false: 'No',
};

const ProductLineMapper = {
  [ProductLine.HOST]: 'Host Experiences',
  [ProductLine.ACTIVATE]: 'Activate Experiences',
  [ProductLine.RESERVE]: 'Reserve Experiences',
} as const;

export const getAnalyticsListNameFromProductLine = (
  productLine?: ProductLine,
  inProductScope?: boolean,
) => {
  if (inProductScope || !productLine) {
    return undefined;
  }

  return ProductLineMapper[productLine] ?? productLine;
};

export const getAnalyticsItemsFromLandingPage = ({
  listings,
  product,
}: {
  listings?: ReturnType<typeof useListingsV2>['listings'];
  product?: ProductLine;
}): AnalyticsLandingPage[] => {
  if (!listings?.length) {
    return [];
  }

  return listings.flatMap((listing, index) => {
    const itemListName = getAnalyticsListNameFromProductLine(
      listing.productLine,
      !!product,
    );

    return [
      {
        itemListName,
        id: listing.id,
        title: listing.title,
        category: listing.category?.name,
        index,
        startingPrice: listing?.startingPrice,
      },
    ];
  });
};

export const getAnalyticsItemsFromProductsListing = ({
  listings,
  product,
}: {
  listings?: ReturnType<typeof useGetListings>['listings'];
  product?: ProductLine;
}): AnalyticsProduct[] => {
  if (!listings?.length) {
    return [];
  }

  return listings.flatMap((listing, index) => {
    const itemListName = getAnalyticsListNameFromProductLine(
      listing.productLine,
      !!product,
    );

    if (
      listing.kind === ListingKind.EXPERIENCE ||
      listing.kind === ListingKind.EVENT
    ) {
      return [
        {
          itemListName,
          id: listing.id,
          title: listing.title,
          category: listing.category?.name,
          index,
          priceTiers: listing.defaultPriceTiers,
        },
      ];
    }

    return getProductFromResourceGroups({
      groups: listing.resourceGroups ?? [],
      collectionId: listing.id,
      collectionName: itemListName,
      index,
      categoryName: listing.category?.name,
    });
  });
};

export const getAnalyticsDataFromCartItem = ({
  cartData,
  collection,
  resourceGroup,
  listing,
}: {
  cartData: Omit<ICartExperience, 'cartItemId'>;
  resourceGroup?: Pick<ResourceGroup, 'id' | 'title'>;
  collection?: Pick<Collection, 'id' | 'category' | 'title'>;
  listing?: Listing;
}) => {
  const listingData = collection ?? listing;

  const group =
    resourceGroup ??
    listing?.resourceGroups?.find((group) =>
      group.experiences?.find((exp) => exp.id === cartData.experienceId),
    );

  return {
    [ANALYTICS_PROPERTY.CurrentCartAmount]: `${
      cartData.participants.reduce((sum, p) => sum + p.price, 0) +
      (cartData.addOns?.reduce(
        (sum, addOn) => sum + addOn.price * (addOn.selectedNumber ?? 0),
        0,
      ) ?? 0)
    }`,
    [ANALYTICS_PROPERTY.ListId]: listingData?.id,
    [ANALYTICS_PROPERTY.ListName]: listingData?.title,
    [ANALYTICS_PROPERTY.ExperienceId]: group?.id,
    [ANALYTICS_PROPERTY.ExperienceTitle]: group?.title,
    [ANALYTICS_PROPERTY.ExperienceCategory]: listingData?.category?.name,
    [ANALYTICS_PROPERTY.Products]: JSON.stringify([
      {
        id: group?.id ?? cartData.experienceId,
        title: group?.title,
        category: listingData?.category?.name,
        priceTiers: Array.from(
          cartData.participants
            .reduce((acc, p) => {
              const key = `${p.priceTierName}-${p.price}`;
              if (!acc.has(key)) {
                acc.set(key, {
                  name: p.priceTierName,
                  price: p.price,
                  selectedNumber: 0,
                });
              }
              acc.get(key).selectedNumber += 1;
              return acc;
            }, new Map())
            .values(),
        ),
      },
      ...(cartData.addOns?.map((addOn) => {
        return {
          id: addOn.id,
          title: addOn.label,
          category: listingData?.category?.name,
          priceTiers: [
            {
              name: 'Addons',
              price: addOn.price,
              selectedNumber: addOn.selectedNumber,
            },
          ],
        };
      }) ?? []),
    ]),
  };
};

export const getProductFromResourceGroups = ({
  groups,
  collectionId,
  collectionName,
  categoryName,
  index,
  session,
  priceTiers,
}: {
  groups: Array<{
    id: string;
    title: string;
    defaultPriceTiers?: IPriceTier[];
    firstAvailableDate?: ResourceGroup['firstAvailableDate'];
  }>;
  session?: Session;
  collectionId?: string;
  collectionName?: string;
  categoryName?: string;
  index?: number;
  priceTiers?: IPriceTier[];
}): AnalyticsProduct[] => {
  return groups.map((group, groupIndex) => {
    return {
      itemListId: collectionId,
      itemListName: collectionName,
      id: group.id,
      category: categoryName,
      index: index ?? groupIndex,
      title: group.title,
      priceTiers:
        session?.priceTiers ??
        group.firstAvailableDate?.sessions[0]?.priceTiers ??
        group.defaultPriceTiers ??
        priceTiers,
    };
  });
};

export const getExperiencesForAnalytics = (
  experiences: (Pick<IFullExperienceFields, 'id' | 'priceTiers' | 'title'> & {
    loading?: boolean;
    category?: null | {
      name?: string;
    };
    defaultPriceTiers?: IFullExperienceFields['priceTiers'];
    firstAvailableDate?: IPublicExperienceDetailResponse['firstAvailableDate'];
  })[],
): AnalyticsProduct[] | undefined => {
  return experiences?.some((experience) => experience?.loading)
    ? undefined
    : experiences?.map((experience, index) => {
        return {
          id: experience.id ?? '',
          title: experience.title ?? '',
          category: experience?.category?.name ?? '',
          priceTiers:
            experience?.firstAvailableDate?.sessions?.[0]?.priceTiers ??
            experience?.defaultPriceTiers ??
            experience?.priceTiers ??
            [],
          index,
        };
      });
};

export const setExperienceAnalyticsData = (
  experience: IPublicExperienceDetailResponse,
) => {
  analyticsManager.setAppData({
    [ANALYTICS_PROPERTY.Products]: JSON.stringify([
      getExperiencesForAnalytics([experience])?.[0] ?? {},
    ]),
    [ANALYTICS_PROPERTY.ExperienceId]: experience.id,
    [ANALYTICS_PROPERTY.ExperienceTitle]: experience.title,
    [ANALYTICS_PROPERTY.ExperienceCategory]: experience.category?.name,
    [ANALYTICS_PROPERTY.IsBookable]:
      IsBookableMapper[(experience?.isBookable ?? '').toString()],
  });
};

export const setBrowserUserData = () => {
  analyticsManager.setAppData({
    [ANALYTICS_PROPERTY.UserAgent]: window.navigator.userAgent,
    [ANALYTICS_PROPERTY.BrowserLanguage]: window.navigator.language,
    [ANALYTICS_PROPERTY.SessionStart]: format(
      'MM-dd-yyyy:HH:mm:SS',
      new Date(),
    ),
  });
};

const trackableRouteParams = new Set([
  'vibeIds',
  'categoryIds',
  'startDate',
  'fromDate',
  'endDate',
] as const);

type ITrackableParams = typeof trackableRouteParams extends Set<infer T>
  ? T
  : never;

export const formatQueryParams = (searchParams: Record<string, string>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { search, fbclid, ...params } = searchParams || {};

  const routeParams = params as Partial<
    Record<ITrackableParams, string | string[]>
  >;

  if (search) {
    analyticsManager.setAppData({ [ANALYTICS_PROPERTY.SearchQuery]: search });
  } else {
    analyticsManager.removeAppData(ANALYTICS_PROPERTY.SearchQuery);
  }

  analyticsManager.removeAppData(ANALYTICS_PROPERTY.QueryParams);

  const data = {
    startDate: '',
    endDate: '',
    fromDate: '',
    vibeIds: [] as string[],
    categoryIds: [] as string[],
  } satisfies Record<ITrackableParams, string | string[]>;

  (Object.keys(routeParams || {}) as ITrackableParams[]).forEach((key) => {
    if (trackableRouteParams.has(key)) {
      const value = routeParams[key];
      if (!value) {
        return;
      }
      if (
        ['startDate', 'fromDate', 'endDate'].includes(key) &&
        typeof value === 'string'
      ) {
        const [YYYY, MM, DD] = value.split('-');
        data[key as 'startDate' | 'endDate' | 'fromDate'] = [MM, DD, YYYY].join(
          '-',
        ) as string;
      } else {
        data[key as 'vibeIds' | 'categoryIds'] =
          typeof value === 'string' ? [value as string] : (value as string[]);
      }
    }
  });

  const date =
    data.startDate && data.endDate
      ? [data.startDate, data.endDate].join(' - ')
      : data.startDate || data.endDate || data.fromDate || '';

  return { date, categoryIds: data.categoryIds, vibesIds: data.vibeIds };
};

export const formatQueryString = ({
  queryParams,
  vibes,
  categories,
}: {
  queryParams: ReturnType<typeof formatQueryParams>;
  vibes: Vibe[];
  categories: Category[];
}): string => {
  const categoriesIdToDataMap = new Map(categories.map((v) => [v.id, v.name]));
  const vibesIdToDataMap = new Map(vibes.map((v) => [v.id, v.name]));

  if (!Object.values(queryParams).find((d) => d.length)) {
    return '| |';
  }
  return [
    queryParams.date,
    queryParams.categoryIds
      .map((id) => categoriesIdToDataMap.get(id) || '')
      .join('; '),
    queryParams.vibesIds.map((id) => vibesIdToDataMap.get(id) || '').join('; '),
  ].join(' | ');
};
