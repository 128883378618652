/**
 * Creates a Date object from a date string and a time string
 * @param dateStr - The date string in the format YYYY-MM-DD
 * @param timeStr - The time string in the format HH:MM:SS
 * @returns A Date object
 */

const fromDateAndTimeString = (dateStr: string, timeStr: string): Date => {
  const [year, month, day] = dateStr.split('-').map(Number);
  const [hours, minutes, seconds] = timeStr.split(':').map(Number);
  return new Date(year, month - 1, day, hours, minutes, seconds);
};

export default fromDateAndTimeString;
