import React, { useMemo } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Popover } from 'components/Popover';
import { getSessionStartEndTime } from 'utils/formatDuration';
import { Session } from 'types/listings';

interface Props {
  date: string;
  selectedSession?: Session;
  sessions: Session[];
  onSessionSelect: (time: string, duration: number) => void;
}

export const ResourceMeta: React.FC<Props> = ({
  selectedSession,
  date,
  sessions,
  onSessionSelect,
}) => {
  const { startTime, endTime } = useMemo(() => {
    if (!selectedSession) {
      return {
        startTime: '',
        endTime: '',
      };
    }
    return getSessionStartEndTime(selectedSession);
  }, [selectedSession]);

  const allSessions = useMemo(() => {
    return sessions.map((session) => {
      const data = getSessionStartEndTime(session);
      return {
        ...session,
        formattedStartTime: data.startTime,
        formattedEndTime: data.endTime,
      };
    });
  }, [sessions]);

  const handleKeyUp = (
    e: React.KeyboardEvent<HTMLDivElement>,
    session: Session,
  ) => {
    if (e.key === 'Enter') {
      onSessionSelect(session.startDateTime.split('T')[1], session.duration);
    }
  };

  return (
    <DateTimeLabel className="resource-detail-meta">
      {moment(date).format('dddd, D MMMM')}&nbsp; • &nbsp;
      {allSessions.length > 1 ? (
        <Popover
          asChild
          showCloseIcon={false}
          trigger={
            <TimeSelector>
              {startTime.toUpperCase()} - {endTime.toUpperCase()}
            </TimeSelector>
          }
        >
          <StyledSessionCard role="menu">
            {allSessions.map((session) => (
              <div
                className={
                  session.startDateTime === selectedSession?.startDateTime &&
                  session.duration === selectedSession.duration
                    ? 'selected-session session-item'
                    : 'session-item'
                }
                tabIndex={0}
                role="menuitem"
                key={session.duration + session.startDateTime}
                onClick={() =>
                  onSessionSelect(
                    session.startDateTime.split('T')[1],
                    session.duration,
                  )
                }
                onKeyUp={(e) => handleKeyUp(e, session)}
              >
                {session.formattedStartTime.toUpperCase()} -{' '}
                {session.formattedEndTime.toUpperCase()}
              </div>
            ))}
          </StyledSessionCard>
        </Popover>
      ) : (
        `${startTime.toUpperCase()} - ${endTime.toUpperCase()}`
      )}
    </DateTimeLabel>
  );
};

const DateTimeLabel = styled.p`
  display: flex;
  font-size: 15px;
  font-weight: 400;
  line-height: 17px;
  color: var(--way-colors-contrastColorShades-100);
  margin: 0px;
`;

const TimeSelector = styled(DateTimeLabel)`
  cursor: pointer;
  text-decoration: underline;
`;

const StyledSessionCard = styled.div`
  width: 195px;
  gap: 8px;
  border-radius: var(--way-design-borderRadiusDefault);
  border: 0.5px solid var(--way-colors-borderColor);
  background: var(--way-palette-white-100);

  > * {
    width: 100%;
    padding: 12px 16px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;

    &:hover {
      background: var(--way-palette-black-10);
    }

    &.selected-session {
      background: var(--way-palette-black-20);
      &:hover {
        background: var(--way-palette-black-30);
      }
    }
  }
`;
