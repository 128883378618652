import { z } from 'zod';
import { PublicGetWaitlistEndpoint } from '@kouto/types';
import { get } from 'utils/axios';
import ApiResponse from '../types/ApiResponse';

export type Waitlist = z.infer<typeof PublicGetWaitlistEndpoint.responseSchema>;

const fetchWaitList = async (url: string, { brandId }: { brandId: string }) => {
  const response = await get<ApiResponse<Waitlist>>(url, {
    headers: {
      'Kouto-Brand-Id': brandId,
    },
  });
  return response.data;
};

export default fetchWaitList;
