import React from 'react';
import styled from 'styled-components';

type CartIconProps = Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
  color?: string;
  scale?: number;
};

const StyledSvg = styled.svg.withConfig({
  shouldForwardProp: (prop) => prop !== 'scale',
})<{ scale?: number }>`
  color: var(--way-colors-svgColor);
  height: ${({ scale = 1 }) => 18 * scale}px;
  width: ${({ scale = 1 }) => 14 * scale}px;
`;

const CartIcon = ({ color, scale = 1, ...props }: CartIconProps) => (
  <StyledSvg
    fill="none"
    scale={scale}
    style={{ color }}
    viewBox="0 0 14 18"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.86401 4.98996V3.4539C3.86401 1.82246 5.26789 0.5 6.99976 0.5C8.73164 0.5 10.1355 1.82246 10.1355 3.4539V4.98996H13.5V17.5001H0.5V4.98996H3.86401ZM4.52843 3.4539C4.52843 2.16813 5.63484 1.12589 6.99976 1.12589C8.36469 1.12589 9.4711 2.16813 9.4711 3.4539V4.98996H4.52843V3.4539ZM3.86401 5.61585V6.88714H4.52843V5.61585H9.4711V6.88714H10.1355V5.61585H12.8356V16.8743H1.16442V5.61585H3.86401Z"
      style={{
        fill: 'currentColor',
        stroke: 'none',
      }}
    />
  </StyledSvg>
);

CartIcon.displayName = 'CartIcon';

export default CartIcon;
