import React from 'react';
import { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TruncatedText, TruncationMode } from 'components/TruncatedText';

interface Props {
  description: string;
  maxLines: number;
  customStyles?: ReturnType<typeof css>;
  onReadMore: () => void;
  hasMoreToShow: boolean;
}

export const ResourceGroupDetail: React.FC<Props> = ({
  description,
  maxLines,
  customStyles,
  onReadMore,
  hasMoreToShow,
}) => {
  const { t: translate } = useTranslation();

  return (
    <div>
      <TruncatedText
        mode={TruncationMode.INLINE}
        text={description}
        maxLines={maxLines}
        isRichText
        showMoreButtonAnyways={hasMoreToShow}
        onShowMore={onReadMore}
        customStyles={customStyles}
        showLessText={translate('viewLess')}
        showMoreText={translate('viewMore')}
        showMoreAriaLabel={translate('viewFullDetailsDescription')}
        textBottomGap={8}
      />
    </div>
  );
};
