import { getHyattSignInUrl } from 'api/hyatt';
import { SignInLink } from 'components/RewardProgramSignIn';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCookieValue, setCookieValue } from 'utils/handleCookies';
import { HYATT_REDIRECT_ON_LOGIN_LOCAL_KEY } from '../../consts/HyattLMSConsts';

interface HyattSignInProps {
  isButtonStyle?: boolean;
  label?: string;
}

const HyattSignIn = ({ isButtonStyle, label = 'signIn' }: HyattSignInProps) => {
  const signInUrl = getHyattSignInUrl();
  const { t: translate } = useTranslation();
  const handleSignInLinkClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
  ) => {
    event.preventDefault();
    if (getCookieValue('auth-mock')) {
      setCookieValue('auth-mock', 'true');
      window.location.reload();
      return;
    }
    const targetUrl = event.currentTarget.href;
    const { href } = new URL(window.location.href);
    localStorage.setItem(HYATT_REDIRECT_ON_LOGIN_LOCAL_KEY, href);
    window.location.href = targetUrl;
  };

  return (
    <SignInLink
      href={signInUrl.toString()}
      isButtonStyle={isButtonStyle}
      onClick={handleSignInLinkClick}
    >
      {label === 'signIn' ? translate(label) : label}
    </SignInLink>
  );
};

export default HyattSignIn;
