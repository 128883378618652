import { ProductLine } from '@kouto/types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ListingV2 } from 'types/listings';
import { getProductPageTitle } from 'utils/listings';
import LandingPageActivateHero from './LandingPageActivateHero';
import LandingPageHostHero from './LandingPageHostHero';
import LandingPageReserveHero from './LandingPageReserveHero';

interface LandingPageHeroProps {
  isLoading: boolean;
  listings: ListingV2[];
  product: ProductLine;
}

export const LandingPageHero: FC<LandingPageHeroProps> = ({
  isLoading,
  listings,
  product,
}) => {
  const { t: translate } = useTranslation();
  const title = getProductPageTitle({
    product,
    translate,
  });

  if (!isLoading && listings.length < 3) {
    return null;
  }

  if (product === ProductLine.ACTIVATE) {
    return (
      <LandingPageActivateHero
        isLoading={isLoading}
        listings={listings}
        title={title}
      />
    );
  }

  if (product === ProductLine.HOST) {
    return (
      <LandingPageHostHero
        isLoading={isLoading}
        listings={listings}
        title={title}
      />
    );
  }

  return (
    <LandingPageReserveHero
      isLoading={isLoading}
      listings={listings}
      title={title}
    />
  );
};
