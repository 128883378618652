import React from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper } from 'components/theme/Wrapper';
import { SkeletonLine } from 'components/theme/Skeleton/Skeleton';
import useListingsV2 from 'api/listing/useListingsV2';
import useSupportedLanguage from 'utils/listingsHelpers/useSupportedLanguage';
import styled from 'styled-components';
import { FeaturedExperienceItem } from '../../organisms/FeaturedExperienceItem';

const NUMBER_OF_FEATURED_EXPERIENCES = 3; // Product only allows 3 featured experiences

const FeaturedTitle = styled.h2`
  display: block;
  color: var(--way-colors-primaryTextColor);
  font-family: ${({ theme }) => theme.font.primaryFont};
  font-size: 36px;
  font-style: normal;
  font-weight: normal;
  line-height: 44px;
  margin-bottom: 48px;
  margin-top: 0;
  text-align: center;
  text-transform: none;
  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 59px;
  }
`;

const FeaturedExperiences = () => {
  const { t: translate } = useTranslation();
  const languageCode = useSupportedLanguage();
  const { listings, isLoading } = useListingsV2({
    featured: 'true',
    limit: NUMBER_OF_FEATURED_EXPERIENCES,
    language: languageCode,
    isUnlisted: 'false',
  });

  if (!isLoading && !listings?.length) {
    return null;
  }

  const renderItems = () =>
    listings.map((item, index) => (
      <FeaturedExperienceItem
        key={`featured-experience-item-${item.id}`}
        listing={item}
        isEven={index % 2 === 0}
      />
    ));

  const renderTitle = () => {
    if (isLoading) {
      return <SkeletonLine style={{ width: 500, margin: '0 auto' }} />;
    }
    if (listings.length > 0) {
      return translate('featuredExperiences');
    }
    return null;
  };

  return (
    <section className="featured-experiences">
      <FeaturedTitle id="featured-title">{renderTitle()}</FeaturedTitle>
      <Wrapper>{renderItems()}</Wrapper>
    </section>
  );
};

export default FeaturedExperiences;
