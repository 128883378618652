import React from 'react';
import useBrandById from 'api/brand/useBrandById';
import { Name, InfoBox } from './styles';

interface BrandInfoBoxProps {
  brandId: string | null;
}
export const BrandInfoBox = ({ brandId }: BrandInfoBoxProps) => {
  const { data, error, isLoading } = useBrandById(brandId);
  if (error || isLoading) return null;
  return (
    <InfoBox>
      <Name>{data?.name}</Name>
    </InfoBox>
  );
};
