import { ListingV2 } from 'types/listings';
import { ProductLine, ListingKind } from '@kouto/types';
import groupEventDatesByProximity from './groupEventDatesByProximity';

const formatListingsToGroupActivateByDate = (
  listings: ListingV2[],
  showMultipleCardsPerEvent: boolean,
  sortByDate: boolean,
  maxDaysDiffToGroup = 3,
): ListingV2[] => {
  return listings
    .flatMap((listing) => {
      const availableDates = listing.dates || null;

      if (listing.productLine === ProductLine.ACTIVATE) {
        if (listing.kind === ListingKind.EXPERIENCE) {
          // fix for one time experiences treated as 'activate' listings
          return [
            {
              ...listing,
              dates: availableDates,
            },
          ];
        }

        // normal 'activate' listings
        const groupedDates = groupEventDatesByProximity(
          availableDates,
          maxDaysDiffToGroup,
        );

        return groupedDates
          .slice(0, showMultipleCardsPerEvent ? groupedDates.length : 1)
          .map((dates) => ({
            ...listing,
            dates,
          }));
      }

      return [listing];
    })
    .sort((a: ListingV2, b: ListingV2) => {
      if (!sortByDate) {
        return 0;
      }

      const firstDate = a.dates?.[0] ?? '';
      const secondDate = b.dates?.[0] ?? '';
      return firstDate > secondDate ? 1 : -1;
    });
};

export default formatListingsToGroupActivateByDate;
