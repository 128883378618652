import React, { FC, MouseEventHandler, useState } from 'react';
import moment from 'moment';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/common/styled/common-styled';
import { useIsMobile } from 'WindowDimensionProvider';
import ResponsiveImage from 'components/common/ResponsiveImage/ResponsiveImage';
import {
  ResourceHeading2,
  ResourceLinkButton,
} from 'components/theme/Typography/ResourceTypography';
import toPrettyDate from 'utils/date/toPrettyDate';
import DatePickerButton from 'components/DatePickerButton';
import toMoment from 'utils/date/toMoment';
import { ButtonText } from 'components/theme/Button';
import minutesToIso from 'utils/duration/minutesToIso';
import { ReserveButton } from 'components/CollapsibleResource/styles';
import toLocaleDateString from 'utils/date/toLocaleDateString';
import { ResourceGroupDetail } from './ResourceGroupDetail';
import { NonMappedGroupSessionLoading } from '../pages/Skeleton';
import { ResourceGroupFullDetail } from './ResourceGroupFullDetail';

const buildReserveUrl = ({
  groupId,
  collectionId,
  date,
  time,
  duration,
}: {
  groupId: string;
  collectionId: string;
  date: string;
  time: string;
  duration: string;
}) => {
  return `#!/collection/${encodeURIComponent(
    collectionId,
  )}/group/${encodeURIComponent(groupId)}?date=${encodeURIComponent(
    date,
  )}&time=${encodeURIComponent(time)}&duration=${encodeURIComponent(duration)}`;
};

interface Props {
  cancellationPolicyText?: string;
  capacityCount: number;
  capacityLabel: string;
  collectionId: string;
  daySessionRangeFrom?: string;
  daySessionRangeTo?: string;
  description: string;
  detailHasMoreToShow?: boolean;
  displayDate?: Date;
  fromPrice: string;
  groupId: string;
  handleIsDayBlocked?: (day: moment.Moment) => boolean;
  imageUriObject: object;
  isLoading?: boolean;
  newDatePickerOpen?: boolean;
  onMonthChange?: (date: Date) => void;
  onNewDatePickerDateChange?: (date: moment.Moment | null) => void;
  onNewDatePickerOpenChange?: (open: boolean) => void;
  onReserveClick?: MouseEventHandler<HTMLAnchorElement>;
  resourceGroupFullDetailDescription: string;
  resourceGroupFullDetailIncludedItems: string[];
  sessionDuration?: number;
  sessionTime?: string;
  title: string;
  unavailable?: boolean;
  vibes?: string[];
}

export const NonMappedResourceGroupCard: FC<Props> = ({
  cancellationPolicyText,
  capacityCount,
  capacityLabel,
  collectionId,
  daySessionRangeFrom,
  daySessionRangeTo,
  description,
  detailHasMoreToShow = false,
  displayDate,
  fromPrice,
  groupId,
  handleIsDayBlocked,
  imageUriObject,
  isLoading = false,
  newDatePickerOpen = false,
  onMonthChange,
  onNewDatePickerDateChange,
  onNewDatePickerOpenChange,
  onReserveClick,
  resourceGroupFullDetailDescription,
  resourceGroupFullDetailIncludedItems,
  sessionDuration,
  sessionTime,
  title,
  unavailable = false,
  vibes,
}) => {
  const isMobile = useIsMobile();
  const { t: translate } = useTranslation();
  const [showDetails, setShowDetails] = useState(false);
  const onReadMore = () => setShowDetails(true);
  const onClose = () => setShowDetails(false);

  const handleMonthChange = (momentDate: moment.Moment) => {
    onMonthChange?.(momentDate.toDate());
  };

  const datePickerButton = displayDate ? (
    <DatePickerButton
      date={toMoment(displayDate)}
      isDayBlocked={handleIsDayBlocked}
      onDateChange={onNewDatePickerDateChange}
      onNextMonthClick={handleMonthChange}
      onOpenChange={onNewDatePickerOpenChange}
      open={newDatePickerOpen}
      position="top-right"
      renderButton={(props) => (
        <ButtonText {...props}>{toPrettyDate(displayDate)}</ButtonText>
      )}
    />
  ) : null;

  const reserveButton = displayDate ? (
    <ReserveButton
      aria-label={translate('reserveName', {
        name: title,
      })}
      as={unavailable ? 'div' : 'a'}
      className="non-mapped-session-reserve"
      data-testid={`reserve-${title}`}
      onClick={unavailable ? undefined : onReserveClick}
      href={
        unavailable || !sessionDuration || !sessionTime
          ? undefined
          : buildReserveUrl({
              collectionId,
              date: toLocaleDateString(displayDate),
              duration: minutesToIso(sessionDuration),
              groupId,
              time: sessionTime,
            })
      }
    >
      {isLoading && translate('loading')}
      {unavailable && translate('unavailable')}
      {!isLoading && !unavailable && translate('reserve')}
    </ReserveButton>
  ) : null;

  return (
    <Wrapper>
      <ResponsiveImage
        className="resource-group-card__resource-image"
        uriObject={imageUriObject}
        defaultSize="384w"
        viewType="small"
        style={{
          borderRadius: 0,
        }}
        CustomRenderer={ResourceGroupImage}
        alt={title}
        pictureStyle={`
            display: grid;
            place-items: center;
            align-self: ${isMobile ? 'stretch' : 'auto'};
          `}
      />
      <MobileContentWrapper>
        <Flex
          direction="column"
          gap={6}
          alignItem="flex-start"
          justifyContent="space-between"
          width="100%"
        >
          <Flex
            direction="row"
            gap={6}
            alignItem="center"
            justifyContent="space-between"
            width="100%"
          >
            <ResourceHeading2
              className="nonmapped-resource-group-card__mobile-heading"
              fontSize="21px"
            >
              {title}
            </ResourceHeading2>
            <ResourceLinkButton
              className="nonmapped-resource-group-card__details-text"
              opacity="0.6"
              fontSize="12px"
              fontWeight="400"
              onClick={onReadMore}
            >
              {translate('viewDetails')}
            </ResourceLinkButton>
          </Flex>
          {!unavailable && (
            <Flex
              direction="row"
              gap={6}
              alignItem="center"
              justifyContent="space-between"
              width="100%"
            >
              <ResourceHeading2
                className="nonmapped-resource-group-card__mobile-date"
                fontSize="14px"
                color="var(--way-palette-black-100)"
                fontWeight="400"
              >
                {isMobile && datePickerButton}
              </ResourceHeading2>
              {fromPrice && (
                <ResourceGroupText className="nonmapped-resource-group-card__details-text">
                  {translate('from')} <strong>{fromPrice}</strong>
                </ResourceGroupText>
              )}
            </Flex>
          )}
        </Flex>
        {reserveButton}
      </MobileContentWrapper>
      <DesktopContentWrapper>
        <Flex
          className="nonmapped-resource-group-card__content"
          direction="column"
          alignItem="flex-start"
          justifyContent="space-between"
          height="100%"
        >
          <Flex direction="column" gap={12} alignItem="flex-start">
            <ResourceHeading2
              className="nonmapped-resource-group-card__heading"
              fontSize="28px"
            >
              {title}
            </ResourceHeading2>
            <ResourceGroupDetail
              description={description}
              maxLines={11}
              customStyles={resourceGroupTextStyles}
              onReadMore={onReadMore}
              hasMoreToShow={detailHasMoreToShow}
            />
          </Flex>

          <Flex direction="column" alignItem="flex-start" width="100%">
            <Flex direction="row" width="100%">
              {isLoading ? (
                <NonMappedGroupSessionLoading />
              ) : (
                <Flex direction="column" gap={4} alignItem="flex-start">
                  <ResourceGroupText className="non-mapped-session-date">
                    {!isMobile && datePickerButton}
                  </ResourceGroupText>
                  {daySessionRangeFrom && daySessionRangeTo && (
                    <ResourceGroupText className="non-mapped-session-time">
                      {daySessionRangeFrom} - {daySessionRangeTo}
                    </ResourceGroupText>
                  )}
                </Flex>
              )}
              <Flex direction="row" alignItem="center" gap={16}>
                {!unavailable && (
                  <Flex direction="column" gap={4} alignItem="flex-end">
                    {isLoading ? (
                      <NonMappedGroupSessionLoading />
                    ) : (
                      fromPrice && (
                        <ResourceGroupText className="non-mapped-session-pricing">
                          {translate('from')} <strong>{fromPrice}</strong>
                        </ResourceGroupText>
                      )
                    )}
                    {!!capacityLabel && (
                      <ResourceGroupText className="non-mapped-session-capacity">
                        {capacityLabel}
                      </ResourceGroupText>
                    )}
                  </Flex>
                )}

                {reserveButton}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </DesktopContentWrapper>
      <ResourceGroupFullDetail
        capacityCount={capacityCount}
        customStyles={resourceGroupTextStyles}
        description={resourceGroupFullDetailDescription}
        includedItems={resourceGroupFullDetailIncludedItems}
        maxLines={300}
        onClose={onClose}
        showContent={showDetails}
        vibes={vibes}
        cancellationPolicyText={cancellationPolicyText}
      />
    </Wrapper>
  );
};

const Wrapper = styled(Flex)`
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;

  @media (width > 768px) {
    flex-direction: row;
    gap: 0;
    align-items: stretch;
  }
`;

const MobileContentWrapper = styled.div`
  display: contents;
  width: 100%;

  @media (width > 768px) {
    display: none;
  }
`;

const DesktopContentWrapper = styled.div`
  display: none;

  @media (width > 768px) {
    border-left: 0px;
    border: 0.5px solid var(--way-colors-borderColor);
    display: block;
    flex: 1;
    padding: 32px 36px 32px 48px;
  }
`;

export const ResourceGroupImage = styled.img`
  object-fit: cover;
  max-height: 100%;
  height: 382px;
  width: 574px;
  object-position: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 240px;
  }
`;

const resourceGroupTextStyles = css`
  margin-bottom: 0;
  & > p {
    font-family: ${({ theme }) => theme.font.primaryFont};
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    font-size: 14px;
    color: var(--way-colors-contrastColorShades-80);
  }
`;

export const ResourceGroupText = styled.div`
  color: var(--way-colors-contrastColorShades-80);
  white-space: nowrap;
  font-size: 16px;
`;
