import { ResourceGroup } from 'types/listings';
import getLastEndingFirstAvailableDateSession from './getLastEndingFirstAvailableDateSession';

type FirstAvailableDate = NonNullable<ResourceGroup['firstAvailableDate']>;

const getEndingDateForFirstAvailableDateSession = (
  firstAvailableDate?: FirstAvailableDate | null,
) => {
  if (!firstAvailableDate) return undefined;

  const lastEndingSession = getLastEndingFirstAvailableDateSession(
    firstAvailableDate.sessions,
  );

  if (!lastEndingSession) return undefined;

  return new Date(
    new Date(
      `${firstAvailableDate.scheduledDate}T${lastEndingSession.startTime}`,
    ).getTime() +
      lastEndingSession.duration * 60 * 1000,
  );
};

export default getEndingDateForFirstAvailableDateSession;
