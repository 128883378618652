import type { CancellationPolicy, ResourceGroup } from 'types/listings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ResponsiveFlex } from 'components/common/styled/common-styled';
import { TruncatedText, TruncationMode } from 'components/TruncatedText';
import ResourceGroupIncludedItems from 'components/Resource/ResourceGroupIncludedItems';
import {
  ResourceHeading2,
  ResourceParagraph,
} from 'components/theme/Typography/ResourceTypography';
import { useIsMobile } from 'WindowDimensionProvider';
import { BottomDrawer } from 'components/BottomDrawer';
import { Modal } from 'components/Modal';
import styled, { css } from 'styled-components';
import ChipList from 'components/ChipList';
import Chip from 'components/Chip/Chip';

interface Props {
  group: ResourceGroup;
  showContent: boolean;
  onClose: () => void;
  maxLines: number;
  customStyles?: ReturnType<typeof css>;
  cancellationPolicy?: CancellationPolicy;
  vibes?: string[];
}

const getCapacityLabelFromCapacity = (
  capacity: number | null,
  translate: (s: string) => string,
) => {
  const displayedCapacity = Number(capacity);
  return displayedCapacity > 0
    ? `${displayedCapacity} ${translate(
        displayedCapacity === 1 ? 'person' : 'people',
      )}`
    : '';
};

const FullDetail: React.FC<
  Pick<
    Props,
    'group' | 'maxLines' | 'customStyles' | 'cancellationPolicy' | 'vibes'
  >
> = ({ group, maxLines, customStyles, cancellationPolicy, vibes }) => {
  const { t: translate } = useTranslation();

  const capacityLabel = getCapacityLabelFromCapacity(
    group.experiences?.[0]?.displayedCapacity ?? 0,
    translate,
  );

  return (
    <ResponsiveFlex direction="column" gap={36} alignItem="flex-start">
      <ResponsiveFlex direction="column" gap={16} alignItem="flex-start">
        <ChipList>
          {vibes?.map((vibe) => (
            <Chip key={vibe}>{vibe}</Chip>
          ))}
        </ChipList>
        <TruncatedText
          customStyles={customStyles}
          mode={TruncationMode.INLINE}
          text={group.description}
          maxLines={maxLines}
          showLessText={translate('viewLess')}
          showMoreText={translate('viewMore')}
          showMoreAriaLabel={translate('viewFullDescription')}
          isRichText
        />
      </ResponsiveFlex>

      {!group.included?.length ? null : (
        <div>
          <ResourceGroupIncludedItems
            title={translate('included')}
            includedItems={group.included}
            headingProps={{
              fontWeight: 700,
              fontSize: '14px',
            }}
          />
        </div>
      )}

      {!capacityLabel ? null : (
        <ResponsiveFlex direction="column" gap={20} alignItem="flex-start">
          <ResourceHeading2
            className="resource-group-included__heading"
            fontSize="14px"
            fontWeight="700"
          >
            {translate('capacity')}
          </ResourceHeading2>

          <ResourceParagraph className="capacity-label">
            {capacityLabel}
          </ResourceParagraph>
        </ResponsiveFlex>
      )}

      {!!cancellationPolicy && cancellationPolicy.active && (
        <ResponsiveFlex direction="column" gap={20} alignItem="flex-start">
          <ResourceHeading2
            className="resource-group-cancellation-policy__heading"
            fontSize="14px"
            fontWeight="700"
          >
            {translate('cancellationPolicy')}
          </ResourceHeading2>

          <ResourceParagraph className="cancellation-policy-label">
            {cancellationPolicy.notes}
          </ResourceParagraph>
        </ResponsiveFlex>
      )}
    </ResponsiveFlex>
  );
};

export const ResourceGroupFullDetail: React.FC<Props> = ({
  group,
  showContent,
  maxLines,
  onClose,
  customStyles,
  cancellationPolicy,
  vibes,
}) => {
  const isMobileView = useIsMobile();
  const { t: translate } = useTranslation();

  return (
    <FullDetailContainer>
      {isMobileView ? (
        <BottomDrawer
          open={showContent}
          heading={translate('details')}
          size="full"
          onClose={onClose}
        >
          <FullDetail
            customStyles={customStyles}
            maxLines={maxLines}
            group={group}
            cancellationPolicy={cancellationPolicy}
            vibes={vibes}
          />
        </BottomDrawer>
      ) : (
        <Modal
          size="large"
          title={translate('details')}
          disableDismissOnClickOutside={false}
          isVisible={showContent}
          isClosable
          onClose={onClose}
          content={
            <FullDetail
              customStyles={customStyles}
              maxLines={maxLines}
              group={group}
              cancellationPolicy={cancellationPolicy}
              vibes={vibes}
            />
          }
        />
      )}
    </FullDetailContainer>
  );
};

const FullDetailContainer = styled.div`
  display: contents;
  .modal-overlay {
    background-color: ${({ theme }) =>
      theme.mode === 'dark'
        ? 'var(--way-palette-black-30)'
        : 'var(--way-palette-black-10)'} !important;
  }
  .modal-content {
    box-shadow: var(--way-design-boxShadow-s);
    border: 0.5px solid var(--way-colors-borderColor);
    border-radius: 0;
    position: relative;
    background: ${({ theme }) =>
      theme.mode === 'dark'
        ? 'var(--way-palette-black-60)'
        : 'var(--way-palette-white-60)'};
    > * {
      z-index: +1;
      position: relative;
    }

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      backdrop-filter: blur(120px) !important;
      position: absolute;
      inset: 0;
    }
  }

  .read-more-btn {
    color: var(--way-colors-contrastColorShades-100) !important;
  }

  .modal-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: var(--way-colors-contrastColorShades-100) !important;
  }
`;
