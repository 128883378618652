import { ANALYTICS_EVENT, ANALYTICS_PROPERTY, ProductLine } from '@kouto/types';
import { NavigationHeader } from 'components/NavigationHeader';
import Spinner from 'components/Spinner/Spinner';
import { Wrapper } from 'components/theme/Wrapper';
import React, { FC, useMemo } from 'react';

import {
  getAnalyticsListNameFromProductLine,
  getAnalyticsItemsFromLandingPage,
} from 'features/analytics';
import { useLandingPageRouteParams } from 'hooks/useLandingPageRouteParams';
import { usePageViewEvent } from 'hooks/usePageViewEvent';
import useListingsV2 from 'api/listing/useListingsV2';
import {
  formatListingsToGroupActivateByDate,
  useListingsV2Params,
} from 'utils/listingsHelpers';
import { DynamicListingsGrid } from './components/DynamicListingsGrid';
import LandingPageHeader from './components/LandingPageHeader';
import { LandingPageHero } from './components/LandingPageHero/LandingPageHero';
import NoResultsPage from './components/NoResultsPage';

interface ProductLandingPageProps {
  product: ProductLine;
}

const eventsMapper = {
  [ProductLine.ACTIVATE]: ANALYTICS_EVENT.VIEW_EVENTS,
  [ProductLine.HOST]: ANALYTICS_EVENT.VIEW_HOSTS,
  [ProductLine.RESERVE]: ANALYTICS_EVENT.VIEW_ACTIVITIES,
};

export const ProductLandingPage: FC<ProductLandingPageProps> = ({
  product,
}) => {
  const { isResults, product: productRoute } = useLandingPageRouteParams();
  const showMultipleCardsPerEvent =
    (isResults && product === ProductLine.ACTIVATE) ?? false;
  const sortByDate = (isResults && product === ProductLine.ACTIVATE) ?? false;
  const baseParams = useListingsV2Params();
  const includeProductLine = {
    ...baseParams,
    productLines: [product],
  };

  const { listings, loadMore, isLoading, isError } =
    useListingsV2(includeProductLine);

  const updateIfActivate =
    product === ProductLine.ACTIVATE
      ? formatListingsToGroupActivateByDate(
          listings,
          showMultipleCardsPerEvent,
          sortByDate,
        )
      : listings;

  const analyticsItems = useMemo(() => {
    const items = getAnalyticsItemsFromLandingPage({
      listings,
      product,
    });

    const data = items.reduce(
      (acc, item) => {
        acc.ids.push(item.id ?? '');
        acc.titles.push(item.title ?? '');

        acc.categories.push(item.category ?? '');
        return acc;
      },
      {
        ids: [] as string[],
        titles: [] as string[],
        categories: [] as string[],
      },
    );

    return { items, data };
  }, [listings, product]);

  usePageViewEvent({
    eventName: eventsMapper[product] || ANALYTICS_EVENT.VIEW_HOME,
    payload: {
      [ANALYTICS_PROPERTY.ListName]:
        getAnalyticsListNameFromProductLine(product),
      [ANALYTICS_PROPERTY.AllExperiences]:
        analyticsItems.data.titles.join(';') || '',
      [ANALYTICS_PROPERTY.AllCategories]:
        analyticsItems.data.categories.join(';') || '',
      [ANALYTICS_PROPERTY.ExperienceCount]:
        analyticsItems.items?.length?.toString(),
      [ANALYTICS_PROPERTY.Products]: JSON.stringify(analyticsItems.items),
    },
    isNotReady: !listings.length,
  });

  return (
    <Wrapper
      style={{
        position: 'relative',
        zIndex: 5,
      }}
    >
      {(isResults || productRoute) && <NavigationHeader />}
      {!isResults && (
        <LandingPageHero
          isLoading={isLoading}
          listings={updateIfActivate}
          product={product}
        />
      )}
      <LandingPageHeader product={product} />
      {isResults && isLoading && (
        <Spinner
          style={{
            marginTop: '100px',
            minHeight: '300px',
          }}
        />
      )}
      {(!isResults || (listings.length > 0 && !isLoading)) && (
        <DynamicListingsGrid
          isLoading={isLoading}
          listings={updateIfActivate}
          loadMore={loadMore}
          isError={isError}
        />
      )}
      {isResults && listings.length === 0 && !isLoading && <NoResultsPage />}
    </Wrapper>
  );
};
