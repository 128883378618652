import React, { FC, useMemo, useCallback } from 'react';
import DynamicGrid from 'components/DynamicGrid/DynamicGrid';
import DynamicGridLoading from 'components/DynamicGrid/DynamicGridLoading';
import { Listing } from 'types/listings';
import AddOnCard from './AddOnCard';

interface Props {
  addOns: Listing['addOns'];
  addOnsAmounts: Record<string, number>;
  selectedPriceTiersCount?: number;
  onAddOnsAmountsChange?: (addOns: { [key: string]: number }) => void;
  columns?: number;
  isLoading?: boolean;
}

const AddOnsList: FC<Props> = ({
  addOns,
  addOnsAmounts,
  selectedPriceTiersCount = 1,
  onAddOnsAmountsChange,
  columns = 2,
  isLoading = false,
}) => {
  const handleAmountChange = useCallback(
    (id: string, amount: number) => {
      if (addOnsAmounts[id] !== amount) {
        onAddOnsAmountsChange?.({
          ...addOnsAmounts,
          [id]: amount,
        });
      }
    },
    [addOnsAmounts, onAddOnsAmountsChange],
  );

  const gridConfig = useMemo(
    () => ({
      columnGap: 16,
      rowGap: 16,
      templateRows: [
        { height: 126, areas: new Array(columns).fill('').map((_, i) => i) },
      ],
    }),
    [columns],
  );

  if (isLoading) {
    return (
      <DynamicGridLoading
        config={gridConfig}
        skeletonCount={4}
        className="add-ons-list-loading"
      />
    );
  }

  return (
    <DynamicGrid
      config={gridConfig}
      style={{ width: '100%', paddingBottom: 1 }}
      count={addOns.length}
    >
      {addOns.map((addOn, index) => (
        <AddOnCard
          key={addOn.id}
          gridArea={`A${index}`}
          selectedPriceTiersCount={selectedPriceTiersCount}
          addOn={addOn}
          currentAmount={addOnsAmounts[addOn.id] || 0}
          onAmountChange={(amount: number) =>
            handleAmountChange(addOn.id, amount)
          }
        />
      ))}
    </DynamicGrid>
  );
};

export default AddOnsList;
