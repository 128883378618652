import React from 'react';
import styled from 'styled-components';

type ChevronLeftIconProps = Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
  color?: string;
  scale?: number;
};

const StyledSvg = styled.svg.withConfig({
  shouldForwardProp: (prop) => prop !== 'scale',
})<{ scale?: number }>`
  color: var(--way-colors-svgColor);
  height: ${({ scale = 1 }) => 14 * scale}px;
  width: ${({ scale = 1 }) => 8 * scale}px;
`;

const ChevronLeftIcon = ({
  color,
  scale = 1,
  ...props
}: ChevronLeftIconProps) => (
  <StyledSvg
    fill="none"
    scale={scale}
    style={{ color }}
    viewBox="0 0 8 14"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.646484 6.74645L6.74648 0.646446L7.45359 1.35355L1.70714 7.1L7.45359 12.8464L6.74648 13.5536L0.646484 7.45355V6.74645Z"
      style={{
        fill: 'currentColor',
        stroke: 'none',
      }}
    />
  </StyledSvg>
);

ChevronLeftIcon.displayName = 'ChevronLeftIcon';

export default ChevronLeftIcon;
