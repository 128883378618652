import { GetListingsV2Endpoint } from '@kouto/types';
import { ListingsV2QueryProps } from 'types/listings';

const buildListingsV2Url = (params?: ListingsV2QueryProps) => {
  return GetListingsV2Endpoint.url({
    query: {
      from: params?.from,
      to: params?.to,
      page: params?.page,
      limit: params?.limit,
      isUnlisted: params?.isUnlisted,
      categoryIds: params?.categoryIds,
      productLines: params?.productLines,
      language: params?.language,
      featured: params?.featured,
    },
  });
};

export default buildListingsV2Url;
