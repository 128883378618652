import React from 'react';
import styled from 'styled-components';
import StarIcon from 'assets/star-icon.svg';

const ExclusiveStar = styled.div`
  position: absolute;
  float: right;
  right: 17px;
  z-index: 4;
  top: 17px;
  pointer-events: none;
`;

const FeaturedExclusiveStar = () => {
  return (
    <ExclusiveStar className="featured-experience__item-image-exclusive-star">
      <StarIcon />
    </ExclusiveStar>
  );
};

export default FeaturedExclusiveStar;
