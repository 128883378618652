import styled from 'styled-components';

const Chip = styled.div`
  /* skip css namespace */
  background-color: var(--way-colors-contrastColorShades-5);
  color: var(--way-colors-secondaryTextColor);
  display: inline-block;
  font-size: 14px;
  padding: 10px 12px;
`;

export default Chip;
