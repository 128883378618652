import { ProductLine } from '@kouto/types';
import { getListingsCoverPreview } from 'utils/listingsHelpers';
import useListingsV2 from 'api/listing/useListingsV2';

const useProductList = () => {
  const products: Partial<
    Record<
      ProductLine,
      {
        bg: string;
        count: number;
      }
    >
  > = {};

  const hostListings = useListingsV2({
    productLines: [ProductLine.HOST],
    limit: 1,
    isUnlisted: 'false',
  });

  const activateListings = useListingsV2({
    productLines: [ProductLine.ACTIVATE],
    limit: 1,
    isUnlisted: 'false',
  });

  const reserveListings = useListingsV2({
    productLines: [ProductLine.RESERVE],
    limit: 1,
    isUnlisted: 'false',
  });

  if (!hostListings.isLoading) {
    const hostListingsCover = getListingsCoverPreview(hostListings.listings);
    if (hostListingsCover) {
      products[ProductLine.HOST] = {
        bg: hostListingsCover,
        count: hostListings?.data?.[0]?.meta?.totalItems || 0,
      };
    }
  }

  if (!activateListings.isLoading) {
    const activateListingsCover = getListingsCoverPreview(
      activateListings.listings,
    );
    if (activateListingsCover) {
      products[ProductLine.ACTIVATE] = {
        bg: activateListingsCover,
        count: activateListings?.data?.[0]?.meta?.totalItems || 0,
      };
    }
  }

  if (!reserveListings.isLoading) {
    const reserveListingsCover = getListingsCoverPreview(
      reserveListings.listings,
    );
    if (reserveListingsCover) {
      products[ProductLine.RESERVE] = {
        bg: reserveListingsCover,
        count: reserveListings?.data?.[0]?.meta?.totalItems || 0,
      };
    }
  }

  return {
    products,
    isLoading:
      hostListings.isLoading ||
      activateListings.isLoading ||
      reserveListings.isLoading,
  };
};

export default useProductList;
