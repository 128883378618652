import { combineReducers } from 'redux';
import accessCode from './access-code';
import app from './app';
import booking from './booking';
import brand from './brand';
import cart from './cart';
import customQuestionResponses from './customQuestionResponses';
import discountCode from './discount-code';
import experience from './experience';
import selectedParticipants from './participants';
import { setPriceTierReducer } from './priceTier';
import review from './review';
import rewardProgramIntegration from './rewardProgramIntegration';
import theme from './theme';
import user from './user';
import waitlist from './waitlist';

export default combineReducers({
  app,
  booking,
  brand,
  experience,
  review,
  theme,
  user,
  discountCode,
  accessCode,
  selectedParticipants,
  cart,
  waitlist,
  customQuestionResponses,
  setPriceTierReducer,
  priceTierData: setPriceTierReducer,
  rewardProgramIntegration,
});
