export default {
  // these should be unique as the values are used as keys
  profileImageOfHost: "Image de profil de l'hôte {{hostName}}",
  hostedBy: 'Hébergé par',
  experiences: 'expériences',
  experience: 'expérience',
  clear: 'Effacer',
  cancel: 'Annuler',
  apply: 'Appliquer',
  more: 'Plus',
  experiencePhotoOfListing:
    "Photo numéro {{photoNumber}} de l'annonce {{listingTitle}}",
  noExperiencesFound: 'Aucune expérience trouvée',
  noExperiencesFoundFor: 'Aucune expérience trouvée pour',
  discoverExperiences: 'Découvrir les expériences',
  clearFilters: 'Effacer les filtres',
  buyTickets: 'Réserver',
  reserve: 'Réserver',
  activateProductCardTitle: 'Événements',
  reserveProductCardTitle: 'Activités',
  hostProductCardTitle: 'Expériences locales',
  mainLandingPageTitle: 'Toutes les expériences',
  activateLandingPageTitle: 'Événements',
  reserveLandingPageTitle: 'Activités et équipements',
  hostLandingPageTitle: 'Expériences locales',
  exclusive: 'Exclusif',
  filterExperiences: 'Filtrer les expériences',
};
