import {
  authenticateHyattUser,
  getHyattMemberProfile,
  HyattAuthResponse,
  RewardProgramIntegrationResponse,
} from 'api/hyatt';
import { useDispatch } from 'AppProvider';
import {
  HYATT_ACCESS_TOKEN_EXPIRY_TIME_SESSION_KEY,
  HYATT_ACCESS_TOKEN_SESSION_KEY,
} from 'consts/HyattLMSConsts';
import { useToastContext } from 'ToastProvider';
import { getCookieValue, setCookieValue } from 'utils/handleCookies';
import { setMemberInfo } from '../reducers/rewardProgramIntegration';
import useIsHyattLMSEnabledFlag from './useIsHyattLMSEnabledFlag';

const useHyattAuth = () => {
  const dispatch = useDispatch();
  const { showToast: toast } = useToastContext();
  const { href } = new URL(window.location.href);
  const hyattLMSEnabled = useIsHyattLMSEnabledFlag();

  const handleSignOut = () => {
    dispatch(setMemberInfo(null));
    sessionStorage.removeItem(HYATT_ACCESS_TOKEN_SESSION_KEY);
    sessionStorage.removeItem(HYATT_ACCESS_TOKEN_EXPIRY_TIME_SESSION_KEY);
    if (getCookieValue('auth-mock')) {
      setCookieValue('auth-mock', 'false');
    }
    toast({
      type: 'success',
      title: 'Sign out successful',
    });
  };

  const removeCodeFromURL = () => {
    const currentUrl = new URL(href);
    currentUrl.searchParams.delete('code');
    window.history.replaceState({}, '', currentUrl.toString());
  };

  const getMemberInfo = async (token: string) => {
    if (getCookieValue('auth-mock') === 'true') {
      const memberData = {
        firstName: 'John',
        loyaltyId: '123456789',
        memberSince: '2019-01-01',
        points: '1500',
        tierCode: 'PLT',
        tierExpiration: '2022-01-01',
      };
      dispatch(setMemberInfo(memberData));
      return memberData;
    }
    try {
      const data: RewardProgramIntegrationResponse =
        await getHyattMemberProfile(token);
      dispatch(setMemberInfo(data.data));
      return data.data;
    } catch (error) {
      throw new Error('Error fetching member info');
    }
  };

  const setHyattAccessToken = (accessToken: string, expiresIn: number) => {
    const expiryTime = Date.now() + expiresIn * 1000;
    sessionStorage.setItem(HYATT_ACCESS_TOKEN_SESSION_KEY, accessToken);
    sessionStorage.setItem(
      HYATT_ACCESS_TOKEN_EXPIRY_TIME_SESSION_KEY,
      expiryTime.toString(),
    );
  };

  const postAuthenticate = async (authCode: string) => {
    try {
      const data: HyattAuthResponse = await authenticateHyattUser(authCode);
      const { accessToken } = data.data;
      const { expiresIn } = data.data;
      setHyattAccessToken(accessToken, expiresIn);
      removeCodeFromURL();
      toast({
        type: 'success',
        title: 'Sign in successful',
      });
    } catch (error) {
      toast({
        type: 'failure',
        title: 'Sign in failed',
      });
    }
  };

  const getCodeFromURL = (url: string) => {
    const parsedUrl = new URL(url);
    const params = new URLSearchParams(parsedUrl.search);
    return params.get('code');
  };

  const checkForAuthCode = () => {
    if (!hyattLMSEnabled) return null;
    const code = getCodeFromURL(href);
    if (!code) return null;
    return code;
  };

  const validateToken = () => {
    const expiryTime = sessionStorage.getItem(
      HYATT_ACCESS_TOKEN_EXPIRY_TIME_SESSION_KEY,
    );
    if (!expiryTime) {
      return false;
    }
    if (Date.now() > Number(expiryTime)) {
      return false;
    }
    return true;
  };

  const getAccessToken = () => {
    const authMock = getCookieValue('auth-mock');
    if (authMock === 'true') return 'qwertyusdfghjk';
    return validateToken()
      ? sessionStorage.getItem(HYATT_ACCESS_TOKEN_SESSION_KEY)
      : null;
  };

  const verifySession = () => {
    const authMock = getCookieValue('auth-mock');
    return authMock === 'true' || (hyattLMSEnabled && !!getAccessToken());
  };

  return {
    checkForAuthCode,
    verifySession,
    getAccessToken,
    getMemberInfo,
    postAuthenticate,
    handleSignOut,
    session: verifySession(),
  };
};

export default useHyattAuth;
