import {
  useSearchParamsObject,
  consolidateEmbeddedCategoryWithParams,
} from '../url';
import getEmbeddedCategoryId from '../embeddedScript/getEmbeddedCategoryId';
import useSupportedLanguage from './useSupportedLanguage';

const useListingsV2Params = () => {
  const languageCode = useSupportedLanguage();
  const searchParamObject = useSearchParamsObject();
  const categoryId = getEmbeddedCategoryId();
  const consolidatedParams = {
    ...consolidateEmbeddedCategoryWithParams(categoryId, searchParamObject),
    limit: Number(searchParamObject.limit) || 24,
    language: languageCode,
    isUnlisted: (searchParamObject.isUnlisted === 'true' ? 'true' : 'false') as
      | 'true'
      | 'false',
  };
  return consolidatedParams;
};

export default useListingsV2Params;
