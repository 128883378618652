import useSWR from 'swr';
import fetchWaitList from './fetchWaitList';
import buildWaitlistUrl from './buildWaitlistUrl';

const useWaitList = (waitlistId?: string | null, brandId?: string) => {
  const url =
    waitlistId && brandId ? buildWaitlistUrl(waitlistId, brandId) : null;
  const swr = useSWR(
    url ? [url, { brandId: brandId as string }] : null,
    ([fetchUrl, params]) => fetchWaitList(fetchUrl, params),
  );
  return { ...swr };
};

export default useWaitList;
