import { BASE_URL } from 'consts';
import { HYATT_AUTH_REDIRECT_URL } from 'consts/HyattLMSConsts';

export const getHyattMemberProfileUrl = () => {
  return `${BASE_URL}/v2/hyatt/member/profile`;
};

export const getHyattAuthenticateUrl = () => {
  return `${BASE_URL}/v2/hyatt/authenticate`;
};

export const getHyattSignInUrl = () => {
  const clientId = process.env.HYATT_CLIENT_ID;
  const hyattSignInUrl = process.env.HYATT_SIGN_IN_URL;
  if (!clientId || !hyattSignInUrl) {
    throw new Error('HYATT_CLIENT_ID or HYATT_SIGN_IN_URL is not defined.');
  }
  const redirectURL = new URL(hyattSignInUrl);
  const params = new URLSearchParams(redirectURL.search);
  params.set('client_id', clientId);
  params.set('response_type', 'code');
  params.set('redirect_uri', HYATT_AUTH_REDIRECT_URL);

  redirectURL.search = params.toString();
  return redirectURL;
};

export const getHyattValidateListingsUrl = () => {
  return `${BASE_URL}/v1/credit-codes/validate-listings`;
};
