import React, { FC, Fragment, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { ProductLine } from '@kouto/types';
import { useAppState } from 'AppProvider';
import DynamicGrid from 'components/DynamicGrid/DynamicGrid';
import DynamicGridLoading from 'components/DynamicGrid/DynamicGridLoading';

import { useLandingPageRouteParams } from 'hooks/useLandingPageRouteParams';
import { ListingV2 } from 'types/listings';
import { ReducerState } from 'types/reducer';
import ListingItemCard from './ListingItemCard';
import { useGridConfig } from '../hooks/useGridConfig';

interface Props {
  listings: ListingV2[];
  loadMore: () => void;
  isLoading: boolean;
  isError?: boolean;
}

export const DynamicListingsGrid: FC<Props> = ({
  listings,
  loadMore,
  isLoading,
  isError,
}) => {
  const brandSettings = useAppState((s: ReducerState) => s.brand.settings);
  const gridConfig = useGridConfig();
  const containerRef = useRef<HTMLDivElement>(null);
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const { isResults, product } = useLandingPageRouteParams();

  useEffect(() => {
    const loadMoreRefCurrent = loadMoreRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLoading && !isError) {
            loadMore();
          }
        });
      },
      {
        root: null,
        rootMargin: '0px 0px 200px 0px', // Load more when 200px from bottom
        threshold: 0.1,
      },
    );

    if (loadMoreRefCurrent) {
      observer.observe(loadMoreRefCurrent);
    }

    return () => {
      if (loadMoreRefCurrent) {
        observer.unobserve(loadMoreRefCurrent);
      }
    };
  }, [isLoading, isError, loadMore]);

  const listingsByMonth = useMemo(
    () =>
      listings.reduce((acc, listing) => {
        if (!listing.dates?.[0]) return acc;
        const year = new Date(listing.dates?.[0]).getFullYear();
        const month = new Date(listing.dates?.[0]).getMonth() + 1;
        if (!year || !month) return acc;

        const key = `${year}-${month}`;

        if (!acc[key]) {
          acc[key] = [];
        }

        acc[key].push(listing);

        return acc;
      }, {} as Record<string, ListingV2[]>),
    [listings],
  );

  if (isLoading && listings.length === 0) {
    return (
      <DynamicGridLoading
        className={`listing-grid-${product ?? 'all'}-loading`}
        config={gridConfig}
        skeletonCount={5}
      />
    );
  }

  if (!isResults || product !== ProductLine.ACTIVATE) {
    return (
      <DynamicGrid
        className={`listing-grid-${product ?? 'all'}`}
        refProp={containerRef}
        config={gridConfig}
        count={listings?.length}
      >
        {listings.map((listing, index) => (
          <ListingItemCard
            key={`${listing.id}-${getFirstListingDate(listing.dates)}`}
            gridArea={`A${index}`}
            listing={listing}
            brandSettings={brandSettings}
          />
        ))}
        <div ref={loadMoreRef} style={{ height: 10 }} />
      </DynamicGrid>
    );
  }

  const availableMonths = Object.keys(listingsByMonth).sort();
  const currentYear = new Date().getFullYear();

  return (
    <ListByMonthWrapper
      className="listing-grid-month-container"
      ref={containerRef}
    >
      {availableMonths.map((yearAndMonth) => {
        const monthFormatted = moment(yearAndMonth, 'YYYY-MM').format('MMMM');
        const cardYear = parseInt(yearAndMonth.split('-')[0], 10);
        const showYear = cardYear !== currentYear;
        return (
          <Fragment key={yearAndMonth}>
            <h2 className="listing-grid-month-title">
              {monthFormatted} {showYear ? cardYear : ''}
            </h2>
            <DynamicGrid
              className="listing-grid-activate-month"
              config={gridConfig}
              count={listingsByMonth[yearAndMonth]?.length}
              style={{
                marginBottom: 80,
              }}
            >
              {listingsByMonth[yearAndMonth].map((listing, index) => (
                <ListingItemCard
                  key={`${listing.id}-${getFirstListingDate(listing.dates)}`}
                  gridArea={`A${index}`}
                  listing={listing}
                  showYear={showYear}
                  brandSettings={brandSettings}
                />
              ))}
            </DynamicGrid>
          </Fragment>
        );
      })}
      <div ref={loadMoreRef} style={{ height: 10 }} />
    </ListByMonthWrapper>
  );
};

const ListByMonthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 12px;

  & > h2 {
    margin-bottom: 6px;
    margin-top: 0px;
  }

  & > * {
    width: 100%;
  }
`;

const getFirstListingDate = (dates: Date[] | null) => {
  return dates?.[0] ?? '';
};
