const minutesToIso = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  return `PT${hours ? `${hours}H` : ''}${
    remainingMinutes ? `${remainingMinutes}M` : ''
  }`;
};

export default minutesToIso;
