import React, { FC, PropsWithChildren, RefObject, CSSProperties } from 'react';
import styled from 'styled-components';

export type DynamicGridConfig = {
  columnGap?: number;
  rowGap?: number;
  templateRows: {
    height: number | string;
    areas: number[];
  }[];
};

interface DynamicGridProps {
  refProp?: RefObject<HTMLDivElement>;
  style?: CSSProperties;
  config: DynamicGridConfig;
  className?: string;
  count: number;
}

const DynamicGrid: FC<PropsWithChildren<DynamicGridProps>> = ({
  refProp,
  config,
  style,
  className,
  children,
  count,
}) => {
  return (
    <Grid
      ref={refProp}
      config={config}
      style={style}
      className={className}
      count={count}
    >
      {children}
    </Grid>
  );
};

export default DynamicGrid;

const Grid = styled.div<{
  config: DynamicGridConfig;
  count: number;
}>`
  display: grid;
  column-gap: ${({ config }) => config.columnGap || 0}px;
  row-gap: ${({ config }) => config.rowGap || 0}px;
  grid-template: ${({ config, count }) => formatGridTemplateRows(config, count)} / ${({
      config,
    }) => formatGridTemplateColumns(config)};
`;

const formatGridTemplateRows = (config: DynamicGridConfig, count: number) => {
  const maxIndexInConfig =
    Math.max(...config.templateRows.map((row) => Math.max(...row.areas))) + 1;

  const gridTemplateIterations = Math.ceil(count / maxIndexInConfig);

  return Array(gridTemplateIterations)
    .fill('')
    .map((_, iterationIndex) => {
      return config.templateRows
        .map((row) => {
          // Generate area names for this iteration
          const areaNames = row.areas.map(
            (area) => `A${area + maxIndexInConfig * iterationIndex}`,
          );

          // Determine if any area in the row is within count
          const isRowValid = row.areas.some(
            (area) => area + maxIndexInConfig * iterationIndex < count,
          );

          // eslint-disable-next-line no-nested-ternary
          const rowHeight = isRowValid
            ? typeof row.height === 'number'
              ? `${row.height}px`
              : row.height
            : '0px';

          // Construct the row definition
          const rowDefinition = `'${areaNames.join(' ')}' ${rowHeight}`;

          return rowDefinition;
        })
        .filter((row) => !row.endsWith(' 0px')) // Remove empty rows
        .join('\n');
    })
    .join('\n');
};

const round = (value: number, precision: number) => {
  const multiplier = 10 ** precision;
  return Math.round(value * multiplier) / multiplier;
};

const formatGridTemplateColumns = (config: DynamicGridConfig) => {
  return config.templateRows[0].areas
    .map(
      (_, __, arr) =>
        `calc(${round(100 / arr.length, 2)}% - ${
          arr.length === 1
            ? 0
            : round(
                ((config.columnGap || 0) * (arr.length - 1)) / arr.length,
                0,
              )
        }px)`,
    )
    .join(' ');
};
