import { setCartId } from 'actions/cart';
import { useBrandId, useDispatch } from 'AppProvider';
import usePrevious from 'hooks/usePrevious';
import { useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import usePaymentIntent from './usePaymentIntent';

const MAX_PAYMENT_INTENT_ATTEMPTS = 3;

const useCartPaymentIntent = (
  cartTotal: number,
  selectedPaymentMethod: string,
) => {
  const dispatch = useDispatch();
  const brandId = useBrandId();
  const previousCartTotal = usePrevious(cartTotal);
  const attemptsRef = useRef(0);

  const { paymentIntent, paymentIntentSecret, isLoading, error } =
    usePaymentIntent(selectedPaymentMethod);

  const previousIsLoading = usePrevious(isLoading);

  useEffect(() => {
    if (
      typeof previousCartTotal !== 'undefined' &&
      previousCartTotal > 0 &&
      cartTotal === 0
    ) {
      dispatch(
        setCartId({
          brandId,
          cartId: uuidv4(),
        }),
      );
    }
  }, [brandId, cartTotal, dispatch, previousCartTotal]);

  useEffect(() => {
    if (
      previousIsLoading &&
      !isLoading &&
      !paymentIntentSecret &&
      cartTotal > 0 &&
      attemptsRef.current < MAX_PAYMENT_INTENT_ATTEMPTS &&
      !error
    ) {
      setTimeout(async () => {
        await dispatch(
          setCartId({
            brandId,
            cartId: uuidv4(),
          }),
        );
        attemptsRef.current += 1;
      }, 1000);
    }
  }, [
    brandId,
    cartTotal,
    dispatch,
    isLoading,
    paymentIntentSecret,
    previousIsLoading,
    error,
  ]);

  return {
    paymentIntent,
    paymentIntentSecret,
    isLoading,
    error,
  };
};

export default useCartPaymentIntent;
