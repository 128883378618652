import React from 'react';
import styled from 'styled-components';

const ExclusiveTagOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  font-style: italic;
  font-weight: normal;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  letter-spacing: -0.02em;
  color: var(--way-palette-white-100);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    var(--way-palette-black-100) 100%
  );
  opacity: 1;
  padding: 40% 0 10%;
`;

interface FeaturedExclusiveOverlayProps {
  exclusiveTag?: string;
}

const FeaturedExclusiveOverlay = ({
  exclusiveTag,
}: FeaturedExclusiveOverlayProps) => {
  return (
    <ExclusiveTagOverlay className="featured-experience__item-image-exclusive-overlay">
      {exclusiveTag}
    </ExclusiveTagOverlay>
  );
};

export default FeaturedExclusiveOverlay;
