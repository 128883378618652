import { SessionStatus } from '@kouto/types';
import { isNil } from 'lodash';
import moment from 'moment';

type PartialSession = {
  experienceId: string;
  participantCount: number;
  capacity: number | null;
  startDateTime: string;
  duration: number;
  status: SessionStatus;
  isPrivateEvent?: boolean;
  priceTiers: {
    id: string;
    name: string;
    maxQuantity: number | null; // Ticket limit
    price: number;
  }[];
};

const populateSessionsWithMaxAllowedToBook = ({
  experiences,
  sessions,
}: {
  experiences: {
    id: string;
    maximumAllowedToBook: number | null;
    minimumRequiredToBook: number | null;
  }[];
  sessions: PartialSession[];
}) => {
  return sessions.reduce(
    (sessionsWithMinAndMaxAllowedToBook, session) => {
      const { maximumAllowedToBook, minimumRequiredToBook } = experiences.find(
        ({ id }) => session.experienceId === id,
      ) ?? { maximumAllowedToBook: null, minimumRequiredToBook: null };

      sessionsWithMinAndMaxAllowedToBook.push({
        ...session,
        maximumAllowedToBook,
        minimumRequiredToBook,
      });

      return sessionsWithMinAndMaxAllowedToBook;
    },
    [] as (PartialSession & {
      maximumAllowedToBook: number | null;
      minimumRequiredToBook: number | null;
    })[],
  );
};

export const getMaxQuantitiesPerTiersLists = ({
  experiences,
  sessions,
  priceTiersSelection,
  waitlistEnabled,
}: {
  experiences: {
    id: string;
    maximumAllowedToBook: number | null;
    minimumRequiredToBook: number | null;
  }[];
  sessions: PartialSession[];
  priceTiersSelection: { id: string; numberOfParticipants: number }[];
  waitlistEnabled?: boolean;
}) => {
  const sessionsWithMinAndMaxAllowedToBook =
    populateSessionsWithMaxAllowedToBook({ experiences, sessions });

  const maximumAllowedToBook = sessionsWithMinAndMaxAllowedToBook.reduce(
    (sum, session) => {
      if (session.maximumAllowedToBook == null) {
        return sum;
      }

      return sum == null
        ? session.maximumAllowedToBook
        : Math.max(sum, session.maximumAllowedToBook);
    },
    null as number | null,
  );
  const minimumRequiredToBook = sessionsWithMinAndMaxAllowedToBook.reduce(
    (sum, session) => {
      if (session.minimumRequiredToBook == null) {
        return sum;
      }

      return sum == null
        ? session.minimumRequiredToBook
        : Math.min(sum, session.minimumRequiredToBook);
    },
    null as number | null,
  );

  const selectedParticipantsCount = priceTiersSelection.reduce(
    (sum, priceTier) => {
      return sum + priceTier.numberOfParticipants;
    },
    0,
  );

  const priceTiersWithMaximumAllowedToBook =
    sessionsWithMinAndMaxAllowedToBook.reduce(
      (priceTiersWithMaximumAllowedToBook, session) => {
        const { priceTiers, capacity, participantCount } = session;

        priceTiers.forEach((priceTier) => {
          const selectedPriceTier = priceTiersSelection.find(
            ({ id }) => priceTier.id === id,
          );
          const priceTierIndex = priceTiersWithMaximumAllowedToBook.findIndex(
            ({ id }) => id === priceTier.id,
          );
          const maxParticipantsPotentialValues = [
            (capacity ?? 0) -
              participantCount -
              selectedParticipantsCount +
              (selectedPriceTier?.numberOfParticipants ?? 0),
          ];

          if (priceTier.maxQuantity != null) {
            maxParticipantsPotentialValues.push(priceTier.maxQuantity);
          }

          if (maximumAllowedToBook != null) {
            maxParticipantsPotentialValues.push(
              maximumAllowedToBook -
                selectedParticipantsCount +
                (selectedPriceTier?.numberOfParticipants ?? 0),
            );
          }

          if (priceTierIndex < 0) {
            priceTiersWithMaximumAllowedToBook.push({
              ...priceTier,
              maxParticipants: waitlistEnabled
                ? maximumAllowedToBook ?? Infinity
                : Math.min(...maxParticipantsPotentialValues),
              minParticipants: minimumRequiredToBook ?? 0,
            });
          } else {
            const currentMaxParticipants =
              priceTiersWithMaximumAllowedToBook[priceTierIndex]
                .maxParticipants;

            // eslint-disable-next-line no-param-reassign
            priceTiersWithMaximumAllowedToBook[priceTierIndex].maxParticipants =
              waitlistEnabled
                ? maximumAllowedToBook ?? Infinity
                : Math.max(
                    currentMaxParticipants,
                    Math.min(...maxParticipantsPotentialValues),
                  );
          }
        });

        return priceTiersWithMaximumAllowedToBook;
      },
      [] as (PartialSession['priceTiers'][number] & {
        maxParticipants: number;
        minParticipants: number;
      })[],
    );

  const bookableSessions = sessionsWithMinAndMaxAllowedToBook.filter(
    (session) => {
      if (!session.capacity) {
        return false;
      }

      const respectsCapacity =
        session.capacity >=
        selectedParticipantsCount + session.participantCount;
      const respectsMinBooking =
        isNil(session.minimumRequiredToBook) ||
        session.minimumRequiredToBook <= selectedParticipantsCount;
      const respectsMaxBooking =
        isNil(session.maximumAllowedToBook) ||
        session.maximumAllowedToBook >= selectedParticipantsCount;
      const respectsPriceTierMaxQuantity = session.priceTiers.every(
        (priceTier) => {
          const selectedPriceTier = priceTiersSelection.find(
            ({ id }) => priceTier.id === id,
          );

          if (!selectedPriceTier || isNil(priceTier.maxQuantity)) {
            return true;
          }

          return (
            selectedPriceTier.numberOfParticipants <= priceTier.maxQuantity
          );
        },
      );

      const isNotBookable =
        session.status === SessionStatus.OVERLAPPED ||
        (session.status === SessionStatus.CONFIRMED && session.isPrivateEvent);

      const isBookable = !isNotBookable;

      return (
        respectsCapacity &&
        respectsMinBooking &&
        respectsMaxBooking &&
        respectsPriceTierMaxQuantity &&
        isBookable
      );
    },
  );

  const waitlistSessions = waitlistEnabled
    ? sessionsWithMinAndMaxAllowedToBook.filter((session) => {
        if (session.capacity === null) {
          return false;
        }

        const respectsMinBooking =
          isNil(session.minimumRequiredToBook) ||
          session.minimumRequiredToBook <= selectedParticipantsCount;
        const respectsMaxBooking =
          isNil(session.maximumAllowedToBook) ||
          session.maximumAllowedToBook >= selectedParticipantsCount;
        const isBookable = bookableSessions.some(
          (bookableSession) =>
            bookableSession.startDateTime === session.startDateTime &&
            bookableSession.duration === session.duration,
        );

        return respectsMinBooking && respectsMaxBooking && !isBookable;
      })
    : [];

  return {
    priceTiers: priceTiersWithMaximumAllowedToBook,
    availableStartTimes: Array.from(
      new Set(
        bookableSessions.map((session) =>
          moment(session.startDateTime).format('HH:mm:ss'),
        ),
      ),
    ),
    waitlistTimes: Array.from(
      new Set(
        waitlistSessions.map((session) =>
          moment(session.startDateTime).format('HH:mm:ss'),
        ),
      ),
    ),
  };
};

export const getNumberOfParticipantsFromPriceTier = (
  priceTier: { id: string; numberOfParticipants: number }[],
) => priceTier.reduce((total, item) => total + item.numberOfParticipants, 0);
