import React from 'react';
import styled from 'styled-components';

type PlusIconProps = Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
  color?: string;
  scale?: number;
};

const StyledSvg = styled.svg.withConfig({
  shouldForwardProp: (prop) => prop !== 'scale',
})<{ scale?: number }>`
  color: var(--way-colors-svgColor);
  height: ${({ scale = 1 }) => 16 * scale}px;
  width: ${({ scale = 1 }) => 17 * scale}px;
`;

const PlusIcon = ({ color, scale = 1, ...props }: PlusIconProps) => (
  <StyledSvg
    fill="none"
    scale={scale}
    style={{ color }}
    viewBox="0 0 17 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.1673 7.3335H9.16732V3.3335H7.83398V7.3335H3.83398V8.66683H7.83398V12.6668H9.16732V8.66683H13.1673V7.3335Z"
      style={{
        fill: 'currentColor',
        stroke: 'none',
      }}
    />
  </StyledSvg>
);

PlusIcon.displayName = 'PlusIcon';

export default PlusIcon;
