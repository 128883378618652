import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CollectionPageSkeleton } from 'features/Reserve/pages/Skeleton';
import {
  ANALYTICS_EVENT,
  ANALYTICS_PROPERTY,
  ListingVersion,
} from '@kouto/types';
import { getProductFromResourceGroups } from 'features/analytics/services/analytics.helpers';
import { usePageViewEvent } from 'hooks/usePageViewEvent';
import useListing from 'api/listing/useListing';
import useSearchQueryParams from 'hooks/use-search-params';
import { Redirect, useParams } from 'react-router-dom';
import { NonMappedCollectionPage } from './NonMapped';
import { MappedCollectionPage } from './Mapped';

export const CollectionPage: React.FC = () => {
  const { t: translate } = useTranslation();
  const { searchParams } = useSearchQueryParams();
  const params = useParams<{ collectionId?: string }>();

  const { data: collection, isLoading: isLoadingCollection } = useListing(
    params.collectionId,
    {
      version: searchParams.latest
        ? ListingVersion.LATEST
        : ListingVersion.PUBLISHED,
    },
  );

  const resourceGroups = collection?.resourceGroups ?? [];
  const isSingleResourceGroup = resourceGroups.length === 1;

  const analyticsItems = useMemo(() => {
    const items = getProductFromResourceGroups({
      groups: collection?.resourceGroups ?? [],
      categoryName: collection?.category?.name,
    });

    const data = items.reduce(
      (acc, item) => {
        acc.ids.push(item.id ?? '');
        acc.titles.push(item.title ?? '');

        acc.categories.push(item.category ?? '');
        return acc;
      },
      {
        ids: [] as string[],
        titles: [] as string[],
        categories: [] as string[],
      },
    );

    return { items, data };
  }, [collection?.resourceGroups, collection?.category?.name]);

  usePageViewEvent({
    eventName: ANALYTICS_EVENT.VIEW_COLLECTION,
    payload: {
      [ANALYTICS_PROPERTY.AllExperiences]:
        analyticsItems.data.titles.join(';') || '',
      [ANALYTICS_PROPERTY.Products]: JSON.stringify(analyticsItems.items),
    },
    optionalPayload: {
      [ANALYTICS_PROPERTY.ExperienceCount]:
        analyticsItems.items?.length?.toString(),
      [ANALYTICS_PROPERTY.AllCategories]:
        analyticsItems.data.categories.join(';') || '',
      [ANALYTICS_PROPERTY.ListId]: collection?.id,
      [ANALYTICS_PROPERTY.ListName]: collection?.title,
    },
    isNotReady: isLoadingCollection || !collection,
  });

  if (isLoadingCollection && !collection) {
    return <CollectionPageSkeleton />;
  }

  if (!collection) {
    return <h1>{translate('noCollectionFound')}</h1>;
  }

  if (collection.map) {
    if (isSingleResourceGroup) {
      return (
        <Redirect
          to={`/collection/${collection.id}/group/${resourceGroups[0].id}`}
        />
      );
    }

    return <MappedCollectionPage />;
  }

  return <NonMappedCollectionPage />;
};
