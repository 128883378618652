import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  height: 18px;
`;

const WeChatPayIcon = () => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 16"
    fill="none"
    role="presentation"
    focusable="false"
  >
    <g clipPath="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m15.416 3.973-.015-.017C13.82 2.129 11.291 1 8.51 1 3.999 1 0 4.016 0 8.142c0 2.102 1.086 3.96 2.716 5.236l-.07.254a237.8 237.8 0 0 0-.175.664 1.602 1.602 0 0 0-.072.442v.002a1.24 1.24 0 0 0 1.933 1.045l1.522-.874a9.847 9.847 0 0 0 2.638.356c4.49 0 8.508-3.015 8.508-7.142v-.007a6.317 6.317 0 0 0-.829-3.083"
        fill="#fff"
      />
      <path
        d="M6.458 10.223a.49.49 0 0 1-.226.051.492.492 0 0 1-.435-.256l-.034-.068-1.374-2.934a.213.213 0 0 1-.017-.103.23.23 0 0 1 .07-.17.24.24 0 0 1 .173-.068c.056 0 .111.019.157.05l1.616 1.127c.126.075.27.116.417.12.09-.001.178-.019.261-.052l7.58-3.31C13.27 3.024 11.028 2 8.508 2 4.355 2 1 4.747 1 8.142c0 1.842 1.008 3.514 2.59 4.64a.44.44 0 0 1 .174.547c-.122.46-.33 1.212-.33 1.245a.603.603 0 0 0-.035.188.23.23 0 0 0 .07.17.24.24 0 0 0 .173.068.2.2 0 0 0 .139-.051l1.634-.939a.838.838 0 0 1 .4-.12c.076.004.152.015.225.035a8.853 8.853 0 0 0 2.451.342c4.137 0 7.509-2.747 7.509-6.142a5.322 5.322 0 0 0-.852-2.849L6.51 10.189l-.052.034Z"
        fill="#1AAD19"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h17v16H0z" />
      </clipPath>
    </defs>
  </StyledSvg>
);

WeChatPayIcon.displayName = 'WeChatPayIcon';

export default WeChatPayIcon;
