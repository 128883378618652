import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { queryParamsToObject } from 'utils';

/**
 * @deprecated Use `useQueryParamsObject` in utils/url instead.
 */
const useQueryParams = <T>(): T => {
  const { search } = useLocation();

  const queryParams = useMemo(() => queryParamsToObject(search), [search]);

  return queryParams;
};

export default useQueryParams;
