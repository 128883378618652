import { createSlice } from '@reduxjs/toolkit';
import { fetchRewardProgramIntegration } from '../actions/rewardProgramIntegration';
import { RewardProgramIntegrationData } from '../types/rewardProgramIntegration';

interface RewardProgramState {
  rewardProgramIntegration: RewardProgramIntegrationData | null;
  loading: boolean;
  error: string | null;
}

const initialState: RewardProgramState = {
  rewardProgramIntegration: null,
  loading: false,
  error: null,
};

export const rewardProgramIntegrationSlice = createSlice({
  name: 'rewardProgramIntegration',
  initialState,
  reducers: {
    clearRewardProgramIntegration: (state) => ({
      ...state,
      rewardProgramIntegration: null,
      loading: false,
      error: null,
    }),
    setSignInSuccess: (state) => {
      return {
        ...state,
        rewardProgramIntegration: state.rewardProgramIntegration
          ? { ...state.rewardProgramIntegration, isSignedIn: true }
          : null,
      };
    },
    setSignOutSuccess: (state) => {
      return {
        ...state,
        rewardProgramIntegration: state.rewardProgramIntegration
          ? { ...state.rewardProgramIntegration, isSignedIn: false }
          : null,
      };
    },
    setAuthCode: (state, action) => {
      return {
        ...state,
        rewardProgramIntegration: state.rewardProgramIntegration
          ? { ...state.rewardProgramIntegration, authCode: action.payload }
          : null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRewardProgramIntegration.pending, (state) => ({
        ...state,
        loading: true,
        error: null,
      }))
      .addCase(fetchRewardProgramIntegration.fulfilled, (state, action) => ({
        ...state,
        rewardProgramIntegration: action.payload,
        loading: false,
      }))
      .addCase(fetchRewardProgramIntegration.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.payload as string,
      }));
  },
});

export const {
  clearRewardProgramIntegration,
  setAuthCode,
  setSignInSuccess,
  setSignOutSuccess,
} = rewardProgramIntegrationSlice.actions;

export default rewardProgramIntegrationSlice.reducer;
