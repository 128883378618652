import React from 'react';
import { useAppState, useDispatch } from 'AppProvider';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { SkeletonLine } from 'components/theme/Skeleton/SkeletonLine';
import { Wrapper } from 'components/theme/Wrapper';
import { NavigationHeader } from 'components/NavigationHeader';
import WaitlistCartSummary from 'features/ShoppingCart/components/WaitlistCartSummary/WaitlistCartSummary';
import { STATUS_PENDING } from 'types/app';
import * as Styled from 'features/ShoppingCart/utils/styles';
import { useExperienceFilterParam } from 'hooks/use-experience-filter-param';
import { usePageViewEvent } from 'hooks/usePageViewEvent';
import { ANALYTICS_EVENT } from '@kouto/types';
import { IWaitlistState } from 'types/waitlist';
import { cleanWaitlist } from 'actions/waitlist';
import { useTotalCartAmountTracker } from 'hooks/useTotalCartAmountTracker';
import { useTrackCartParticipants } from 'features/ShoppingCart/hooks';
import { useAnalyticsReadyForCartData } from 'hooks/use-analytics-ready-for-cart-data';
import JoinWaitlistPage from './JoinWaitlistPage';

// This is the equivalent of the "checkout page" for the waitlist flow
const JoinWaitlist = () => {
  const dispatch = useDispatch();
  const trackingReady = useAnalyticsReadyForCartData();
  const { t: translate } = useTranslation();
  useTotalCartAmountTracker();
  useTrackCartParticipants(true);
  const history = useHistory();
  usePageViewEvent({
    eventName: ANALYTICS_EVENT.VIEW_CHECKOUT,
    isNotReady: !trackingReady,
  });

  const { item: waitlistItem, experience: waitlistExperience }: IWaitlistState =
    useAppState((state: Record<string, unknown>) => state.waitlist);
  const { fetchSettingsStatus, settings } = useAppState(
    (state: Record<string, unknown>) => state.brand,
  );

  const experienceFilterParam = useExperienceFilterParam();

  if (!waitlistItem || !waitlistExperience) {
    dispatch(cleanWaitlist(undefined));
    history.push({
      pathname: '/e',
    });
    return null;
  }

  if (fetchSettingsStatus === STATUS_PENDING) {
    return (
      <Wrapper>
        <SkeletonLine style={{ height: 100, width: 600 }} translucent />
        <SkeletonLine style={{ height: 300, width: 600 }} translucent />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Helmet>
        {settings?.name && <title>Join Waitlist | {settings?.name}</title>}
      </Helmet>
      <NavigationHeader
        preventDefaultBack
        showCart={false}
        goBackLabel={translate('browseAllExperieces')}
        onGoBack={() => {
          dispatch(cleanWaitlist(undefined));
          history.push({
            pathname: '/e',
            search: `?${experienceFilterParam}`,
          });
        }}
      />
      <Styled.FormWrapper>
        <JoinWaitlistPage
          waitlistItem={waitlistItem}
          waitlistExperience={waitlistExperience}
        />
        <WaitlistCartSummary />
      </Styled.FormWrapper>
    </Wrapper>
  );
};

export default JoinWaitlist;
