import checkSessionsOverlap from 'features/Reserve/utils/checkSessionsOverlap';
import useCartItems from 'hooks/useCartItems';
import moment from 'moment';
import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useResourceBookingSessionPropsFromUrl from './useResourceBookingSessionPropsFromUrl';
import useResourceBookingSessionSelectedData from './useResourceBookingSessionSelectedData';
import { getSessionTimeOptions } from './useSessionTimeOptions';

interface BookingProps<D> {
  collectionId?: string;
  groupId?: string;
  resourceId?: string;
  date?: string;
  time?: string;
  latest?: boolean;
  duration?: D;
  updateUrl?: boolean;
}

const formatNextBookingUrl = (props: BookingProps<string>) => {
  let url = '';
  const searchParams = new URLSearchParams();

  if (props.collectionId) {
    url += `/collection/${props.collectionId}`;
    if (props.groupId) {
      url += `/group/${props.groupId}`;
      if (props.resourceId) {
        url += `/resource/${props.resourceId}`;
      }
    }
    if (props.date || props.time || props.duration) {
      if (props.date && props.updateUrl) {
        searchParams.set('date', props.date);
      }
      if (props.time && props.updateUrl) {
        searchParams.set('time', props.time);
      }
      if (props.duration && props.updateUrl) {
        searchParams.set('duration', props.duration);
      }
      if (props.latest && props.updateUrl) {
        searchParams.set('latest', 'true');
      }
    } else if (props.latest && props.updateUrl) {
      searchParams.set('latest', 'true');
    }
  }

  const search = searchParams.toString();
  return search ? `${url}?${search}` : url;
};

const useResourceBookingSession = ({
  updateUrl = true,
}: {
  updateUrl?: boolean;
} = {}) => {
  const location = useLocation();
  const history = useHistory();

  /* eslint-disable prefer-const */
  // 1 - get props from url
  let { collectionId, groupId, resourceId, date, time, duration, latest } =
    useResourceBookingSessionPropsFromUrl();

  // 2 - get data using props from url
  let {
    isLoading,
    isLoadingCollection,
    isLoadingSessions,
    isLoadingCancellationPolicy,
    collection,
    resourceGroup,
    resource,
    sessions,
    selectedSession,
    cancellationPolicy,
  } = useResourceBookingSessionSelectedData({
    collectionId,
    groupId,
    resourceId,
    date,
    time,
    duration,
    latest,
  });
  /* eslint-enable prefer-const */

  const { cartItems } = useCartItems();
  const resourcesInCart = Object.values(cartItems);

  // 3 - if the given ids are not found, reset the data
  if (!collection) {
    collectionId = undefined;
    groupId = undefined;
    resourceId = undefined;
    date = undefined;
    time = undefined;
    duration = undefined;
    sessions = [];
    selectedSession = undefined;
  } else if (date) {
    if (
      collection.firstAvailableDate?.scheduledDate &&
      moment(collection.firstAvailableDate.scheduledDate).isAfter(date)
    ) {
      date = collection.firstAvailableDate.scheduledDate;
    }
  } else if (collection.firstAvailableDate?.scheduledDate) {
    date = collection.firstAvailableDate.scheduledDate;
  } else {
    date = moment().format('YYYY-MM-DD');
  }

  if (!resourceGroup) {
    groupId = undefined;
    resourceId = undefined;
    selectedSession = undefined;
    time = undefined;
    duration = undefined;
  } else {
    groupId = resourceGroup.id;
  }
  if (!resource) {
    resourceId = undefined;
  } else {
    resourceId = resource.id;
  }

  // 4 - make sure that what we have already put in the cart is not available anymore, to prevent double booking
  if (collection) {
    if (collection.map) {
      sessions = sessions.filter(
        (session) =>
          !resourcesInCart.find(
            (cartItem) =>
              cartItem.experienceId === session.experienceId &&
              checkSessionsOverlap(
                cartItem.sessionDateTime,
                moment.duration(cartItem.sessionDuration).asMinutes(),
                session.startDateTime,
                session.duration,
              ),
          ),
      );
    } else if (resourceGroup) {
      const sessionTimeOptions = getSessionTimeOptions(sessions);
      resourcesInCart.forEach((cartItem) => {
        if (resourceGroup && resourceGroup.id === cartItem.groupId) {
          cartItem.participants.forEach(() => {
            sessionTimeOptions.forEach((sessionTimeOption) => {
              const firstResouceSessionInCartIndex = sessions.findIndex(
                (session) =>
                  resourceGroup &&
                  !!resourceGroup.experiences.find(
                    (exp) => exp.id === session.experienceId,
                  ) &&
                  session.startDateTime ===
                    sessionTimeOption.original.startDateTime &&
                  session.duration === sessionTimeOption.original.duration &&
                  checkSessionsOverlap(
                    cartItem.sessionDateTime,
                    moment.duration(cartItem.sessionDuration).asMinutes(),
                    session.startDateTime,
                    session.duration,
                  ),
              );
              if (firstResouceSessionInCartIndex >= 0) {
                sessions.splice(firstResouceSessionInCartIndex, 1);
              }
            });
          });
        }
      });
    }
  }

  // 5 - time and duration must be coherent with what's available in sessions[]
  if (selectedSession) {
    time = selectedSession.startDateTime.split('T')[1];
    duration = selectedSession.duration;
  } else {
    if (time && !sessions.find((s) => s.startDateTime.split('T')[1] === time)) {
      time = undefined;
    }
    if (duration && !sessions.find((s) => s.duration === duration)) {
      duration = undefined;
    }
    if (
      time &&
      duration &&
      !sessions.find(
        (s) =>
          s.startDateTime.split('T')[1] === time && s.duration === duration,
      )
    ) {
      time = undefined;
      duration = undefined;
    }

    // preselect time and duration when opening the group page
    if (resourceGroup && !time && !duration) {
      const sessionsOptions = getSessionTimeOptions(sessions);
      time = sessionsOptions[0]?.original.startDateTime.split('T')[1];
      duration = sessionsOptions[0]?.original.duration;
    }

    // preset time and duration if only one option is available
    if (!time) {
      const allAvailableTimes = new Set(
        sessions.map((s) => s.startDateTime.split('T')[1]),
      );
      if (allAvailableTimes.size === 1) {
        time = allAvailableTimes.values().next().value;
      }
    }
    if (!duration) {
      const allAvailableDurations = new Set(sessions.map((e) => e.duration));
      if (allAvailableDurations.size === 1) {
        duration = allAvailableDurations.values().next().value;
      }
    }
  }

  // 6 - format new cleaned url
  const cleanedUrl = formatNextBookingUrl({
    collectionId,
    groupId,
    resourceId,
    date,
    time,
    latest,
    duration: duration
      ? moment.duration(duration, 'minutes').toISOString()
      : undefined,
    updateUrl,
  });

  const setResourceBookingSessionParams = useCallback(
    (changes: BookingProps<number>) => {
      const nextUrl = formatNextBookingUrl({
        collectionId,
        groupId,
        resourceId,
        date,
        time,
        latest,
        ...changes,
        duration: moment
          .duration(changes.duration || duration || 0, 'minutes')
          .toISOString(),
      });

      if (`${location.pathname}${location.search}` !== nextUrl) {
        const [pathname, search] = nextUrl.split('?');
        if (location.pathname !== pathname) {
          history.push({
            pathname,
            search,
          });
        } else {
          history.replace({
            pathname,
            search,
          });
        }
      }
    },
    [
      location,
      history,
      collectionId,
      groupId,
      resourceId,
      date,
      time,
      latest,
      duration,
    ],
  );

  return {
    isLoading,
    isLoadingCollection,
    isLoadingSessions,
    isLoadingCancellationPolicy,
    cleanedUrl,
    collection,
    resourceGroup,
    resource,
    sessions,
    selectedSession,
    cancellationPolicy,
    collectionId,
    groupId,
    resourceId,
    date,
    time,
    duration,
    latest,
    setResourceBookingSessionParams,
  };
};

export default useResourceBookingSession;
