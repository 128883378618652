import {
  ANALYTICS_EVENT,
  ANALYTICS_PROPERTY,
  AVAILABLE_BRAND_FEATURES,
} from '@kouto/types';
import useBrandToggleFeature from 'components/BrandToggleFeature/use-brand-toggle-feature';
import {
  getAnalyticsItemsFromProductsListing,
  getAnalyticsListNameFromProductLine,
} from 'features/analytics';
import useEventBookingSession from 'features/Events/hooks/useEventBookingSession';
import { usePageViewEvent } from 'hooks/usePageViewEvent';
import throttle from 'lodash/throttle';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useIsMobile } from 'WindowDimensionProvider';
import { Redirect, useParams } from 'react-router-dom';
import { useBrandId } from 'AppProvider';
import {
  EventAvailability,
  EventCoverMedia,
  EventDetails,
  EventDetailsHeader,
  EventGallery,
  EventHeadline,
  EventLocation,
} from './components';

const SCROLL_ANIMATION_FPS = 60;
const ANIMATION_PX_LENGTH = 600;

const getAnimationCompletion = (
  scrollFromTop: number,
  maxAnimationScroll: number,
) => {
  let animationCompletion = 0; // %
  if (scrollFromTop < 0) {
    animationCompletion = 100;
  } else if (scrollFromTop < maxAnimationScroll) {
    animationCompletion =
      Math.round(
        ((maxAnimationScroll - scrollFromTop) / maxAnimationScroll) * 10000,
      ) / 100;
  } else {
    animationCompletion = 0;
  }
  return animationCompletion;
};

const EventDetailsPage: FC = () => {
  // =======================================
  // THIS IS A TEMPORARY REDIRECT FOR SILVERADO RESORT
  // TODO: Remove this once the redirect is no longer needed
  const REDIRECT_BRAND_ID = '4f223dfa-6b4a-4568-9009-650fa1465df1';
  const REDIRECT_EVENT_ID_FROM = 'ef65ad01-b63c-4983-aa58-e03f49acdc04';
  const REDIRECT_EVENT_ID_TO = '03c28e79-c056-416c-9c92-836e285e6bd3';

  const { eventId } = useParams<{ eventId: string }>();
  const brandId = useBrandId();

  if (eventId === REDIRECT_EVENT_ID_FROM && brandId === REDIRECT_BRAND_ID) {
    return <Redirect to={`/event/${REDIRECT_EVENT_ID_TO}`} />;
  }
  // =======================================

  const contentRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();
  const [animationCompletion, setAnimationCompletion] = useState(0);
  const disableAnimateScroll = useBrandToggleFeature(
    AVAILABLE_BRAND_FEATURES.DISABLE_ACTIVATE_SCROLLING_ANIMATION,
  );
  const onScroll = useCallback(
    throttle(() => {
      if (contentRef.current) {
        setAnimationCompletion(
          getAnimationCompletion(
            contentRef.current.getBoundingClientRect().top,
            isMobile ? ANIMATION_PX_LENGTH / 2 : ANIMATION_PX_LENGTH,
          ),
        );
      }
    }, Math.round(1000 / SCROLL_ANIMATION_FPS)),
    [contentRef, isMobile],
  );

  useEffect(() => {
    if (!disableAnimateScroll) {
      window.addEventListener('scroll', onScroll);
    }

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [disableAnimateScroll, onScroll]);

  const { isLoading, event } = useEventBookingSession();

  usePageViewEvent({
    eventName: ANALYTICS_EVENT.VIEW_EXPERIENCE_DETAIL,
    payload: {
      [ANALYTICS_PROPERTY.ExperienceId]: event?.id,
      [ANALYTICS_PROPERTY.ExperienceCategory]: event?.category?.name,
      [ANALYTICS_PROPERTY.ListName]: getAnalyticsListNameFromProductLine(
        event?.productLine,
      ),
      [ANALYTICS_PROPERTY.ExperienceTitle]: event?.title,
      [ANALYTICS_PROPERTY.Products]: JSON.stringify(
        getAnalyticsItemsFromProductsListing({
          listings: event ? [event] : [],
          product: event?.productLine,
        }),
      ),
    },
    isNotReady: isLoading || !event,
  });

  return (
    <EventDetailsPageWrapper>
      <EventDetailsHeader />
      <EventCoverMedia scrollAnimationDelay={animationCompletion} />
      <GlassEffect ref={contentRef} active={!disableAnimateScroll}>
        <EventDetailsMain>
          <EventInfo>
            <EventHeadline />
            <EventDetails />
            <EventLocation />
          </EventInfo>
          <EventAvailability />
        </EventDetailsMain>
        <EventGallery />
      </GlassEffect>
    </EventDetailsPageWrapper>
  );
};

export const EventDetailsPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const GlassEffect = styled.div<{
  active: boolean;
}>`
  ${({ active }) =>
    active
      ? `
        width: 100vw;
        position: relative;
        left: 50%;
        transform: translate3d(-50vw, 0px, 0px);
        padding: 0px 12px;
        background: var(--way-colors-coherentColorShades-50);
        backdrop-filter: blur(60px);

        @media (min-width: 1200px) {
          padding: 0px calc((100vw - 1280px) / 2);
        }
      `
      : ``};
`;

export const EventDetailsMain = styled.div`
  width: 100%;
  min-height: 400px;
  padding-top: 48px;
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const EventInfo = styled.div`
  width: calc(100% - 350px);
  display: flex;
  flex-direction: column;
  padding-right: 96px;

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
  }
`;

export default EventDetailsPage;
