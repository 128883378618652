import PrevArrowIcon from 'assets/chevron-left';
import styled from 'styled-components';

export const NavigationHeaderContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  min-height: 50px;
  width: 100%;
`;

export const LeftAligned = styled.div`
  display: flex;
`;

export const RightAligned = styled.div`
  display: flex;
  align-items: center;
  gap: 35px;
`;

export const HoverablePrevArrowIcon = styled(PrevArrowIcon)`
  transition: color 0.3s ease;
  color: var(--way-colors-contrastColorShades-70);

  &:hover {
    color: var(--way-colors-contrastColorShades-100);
  }
`;

export const LinksBack = styled.button`
  background-color: inherit;
  border-style: none;
  align-items: center;
  display: flex;
  font-size: 14px;
`;

export const IconBack = styled.div`
  height: 20px;
  width: 18px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export const BackLabel = styled.span`
  /* skip css namespace */
  cursor: pointer;
  display: inline-block;
  font-weight: 350;
  font-size: 16px;
  line-height: 40px;
  white-space: nowrap;
  text-transform: capitalize;
  transition: color 0.3s ease;
  color: var(--way-colors-contrastColorShades-70);

  &:hover {
    color: var(--way-colors-contrastColorShades-100);
  }
`;
