import { Listing } from 'types/listings';
import { PaymentIntentError } from '../types';
import { checkPaymentIntentErrorHasExperienceId } from './checkPaymentIntentErrorHasExperienceId';

export const validatePaymentIntentError = (
  paymentIntentError: PaymentIntentError,
  listing?: Listing,
) => {
  const { cartItemsError } = paymentIntentError;

  if (!cartItemsError) return null;

  if (checkPaymentIntentErrorHasExperienceId(paymentIntentError)) {
    return (
      cartItemsError.find((item) => item.experienceId === listing?.id) || null
    );
  }

  const { resourceGroups } = listing || {};

  if (!resourceGroups) return null;
  const resourceGroupIds = resourceGroups.map((group) => group.id);

  const hasMatchingError = cartItemsError.some(
    (item) =>
      item?.resourceGroupId && resourceGroupIds.includes(item.resourceGroupId),
  );

  return hasMatchingError ? cartItemsError[0] : null;
};
