import { useAppState } from 'AppProvider';
import useHyattAuth from 'hooks/useHyattAuth';
import usePoints from 'hooks/usePoints';
import { RewardProgramState } from 'reducers/rewardProgramIntegration';

const useIsEnoughPoints = (cartTotal: number) => {
  const { session } = useHyattAuth();
  const { count } = usePoints(cartTotal);
  const { rewardProgramIntegration }: RewardProgramState = useAppState(
    (state: Record<string, unknown>) => state.rewardProgramIntegration,
  );
  const memberPoints = rewardProgramIntegration?.points;
  const pointsNeeded = count;
  const notEnoughPoints =
    !session || !memberPoints || pointsNeeded > +memberPoints;
  return { notEnoughPoints };
};

export default useIsEnoughPoints;
