import { ListingV2 } from 'types/listings';
import { MediaLinkSchema } from '@kouto/types';
import { z } from 'zod';

type Link = z.infer<typeof MediaLinkSchema>;

const getCoverImage = (
  mediaList: ListingV2['medias'],
  resolution: string,
): Link | null => {
  const coverMedia = mediaList.find((media) => media.kind === 'cover');

  if (!coverMedia) return null;

  const link = coverMedia.links.find(
    (link) => link.resolution === resolution && link.type === 'image',
  );

  return link || null;
};

export default getCoverImage;
