import styled from 'styled-components';
import BaseButton from './BaseButton';

const commonStyles = () => {
  return `
    font-style: normal;
    font-weight: normal;
    text-transform:none;
    user-select: none;
  `;
};

export const Button = styled(BaseButton)`
  ${commonStyles()};
  font-size: 15px;
  line-height: 20px;
  padding: 15px 40px;
  border-radius: var(--way-design-borderRadiusDefault);
  font-family: ${({ theme }) => theme.font.secondaryFont};
  min-height: 1px;
  height: auto;
  cursor: pointer;
  transition: all 0.2s;
  background: var(--way-colors-buttonColorShades-80);
  color: var(--way-colors-buttonColorContrastShades-80);

  &:hover {
    background: var(--way-colors-buttonColorShades-100);
    color: var(--way-colors-buttonColorContrastShades-100);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
`;

export const ButtonLink = styled(BaseButton)`
  ${commonStyles()};
  font-size: 15px;
  line-height: 20px;
  padding: 6px 6px;
  border-radius: var(--way-design-borderRadiusDefault);
  background: transparent;
  font-family: ${({ theme }) => theme.font.secondaryFont};
  border: none;
  display: inline-flex;
  cursor: pointer;
  min-height: 1px;
  height: auto;
  &:hover {
    color: var(--way-palette-black-100);
  }
`;

export const ButtonLinkRemove = styled(BaseButton)`
  border: none;
  background: none;
  padding: 0;
  color: #b73131;
  font-family: ${({ theme }) => theme.font.secondaryFont};
  cursor: pointer;
`;

export const ButtonBlock = styled(Button)`
  ${commonStyles()};
  display: block;
  width: 100%;
  border: none;
  padding-left: 6px;
  padding-right: 6px;
  cursor: pointer;
`;

export const ButtonLinkSearch = styled.a`
  ${commonStyles()};
  font-size: 15px;
  line-height: 20px;
  padding: 15px 40px;
  border-radius: var(--way-design-borderRadiusDefault);
  background: var(--way-colors-primaryColorShades-100);
  font-family: ${({ theme }) => theme.font.secondaryFont};
  color: var(--way-colors-primaryColorContrastShades-100);
  cursor: pointer;
`;

export const TagButton = styled(Button)`
  ${commonStyles()};
  font-size: 12px;
  line-height: 15px;
  padding: 6px 12px;
  border-radius: 50px;
  border: none;
  outline: none;
  font-family: ${({ theme }) => theme.font.secondaryFont};
`;

export const TypeButton = styled(Button)`
  ${commonStyles()};
  font-size: 12px;
  line-height: 15px;
  padding: 6px 12px;
  border-radius: 50px;
  border: none;
  background: var(--way-colors-primaryColorShades-100);
  font-family: ${({ theme }) => theme.font.secondaryFont};
  color: var(--way-colors-primaryColorContrastShades-100);

  &:focus {
    outline-offset: 1px;
  }
`;

export const VibeButton = styled(Button)`
  ${commonStyles()};
  font-size: 12px;
  line-height: 15px;
  padding: 6px 12px;
  border-radius: 50px;
  border: none;
  background: var(--way-colors-secondaryColorShades-100);
  color: var(--way-colors-secondaryColorContrastShades-100);
  font-family: ${({ theme }) => theme.font.secondaryFont};

  &:focus {
    outline-offset: 1px;
  }
`;

const standardActionButtonCss = (props) => `
  ${commonStyles()};
  /* skip css namespace */

  font-size: 15px;
  line-height: 20px;
  font-family: ${props.theme.font.secondaryFont};
  position: relative;
  ${props.big ? 'width: 100%' : ''};
  height: ${props.big ? 50 : 40}px;
  min-width: 48px;
  cursor: pointer;
  padding: 0px 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  transition: all 0.2s;
  border-radius: var(--way-design-borderRadiusDefault);
`;

export const PrimaryButton = styled(BaseButton)`
  ${(props) => standardActionButtonCss(props)};
  background-color: var(--way-colors-buttonColorShades-100);
  color: var(--way-colors-buttonColorContrastShades-100);
  border: none;

  &:hover {
    background-color: var(--way-colors-buttonColorShades-90);
    color: var(--way-colors-buttonColorContrastShades-90);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }

  &:disabled:hover {
    background-color: var(--way-colors-buttonColorShades-100);
    color: var(--way-colors-buttonColorContrastShades-100);
  }
`;

export const SecondaryButton = styled(BaseButton)`
  ${standardActionButtonCss};
  border: solid 0.5px
    ${({ selected }) =>
      selected
        ? 'var(--way-colors-primaryColorShades-100)'
        : 'var(--way-colors-borderColor)'};

  background-color: ${({ selected }) =>
    selected ? 'var(--way-colors-primaryColorShades-100)' : 'transparent'};
  color: ${({ selected }) => {
    return selected
      ? 'var(--way-colors-primaryColorContrastShades-100)'
      : 'var(--way-colors-contrastColorShades-80)';
  }};

  &:hover {
    background-color: ${({ selected }) =>
      selected ? 'var(--way-colors-primaryColorShades-90)' : 'transparent'};
    color: ${({ selected }) => {
      return selected
        ? 'var(--way-colors-primaryColorContrastShades-90)'
        : 'var(--way-colors-contrastColorShades-100)';
    }};
    border: solid 0.5px
      ${({ selected }) =>
        selected ? 'transparent' : 'var(--way-colors-borderHoverColor)'};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ selected }) =>
      selected ? 'var(--way-colors-primaryColorShades-50)' : 'transparent'};
    pointer-events: none;
  }
`;

export const GhostButton = styled(BaseButton)`
  ${standardActionButtonCss};
  background-color: transparent;
  border: none;
  outline: 0;

  color: ${({ selected }) => {
    return selected
      ? 'var(--way-colors-contrastColorShades-100)'
      : 'var(--way-colors-contrastColorShades-80)';
  }};

  &:hover {
    color: ${({ selected }) => {
      return selected
        ? 'var(--way-colors-contrastColorShades-90)'
        : 'var(--way-colors-contrastColorShades-100)';
    }};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }
`;
