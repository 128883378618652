import React, { useMemo, useState } from 'react';
import { ListingV2 } from 'types/listings';
import { useAppState } from 'AppProvider';
import {
  AVAILABLE_BRAND_FEATURES,
  ANALYTICS_EVENT,
  ANALYTICS_PROPERTY,
  ProductLine,
} from '@kouto/types';
import { useHistory } from 'react-router-dom';
import { getListingRoute } from 'utils/listings';
import { t } from 'i18next';
import { analyticsManager } from 'features/analytics';
import { useFormatPrice } from 'utils/listingsHelpers';
import { RatingSummary } from 'components/atoms/RatingSummary';
import {
  Body,
  Button,
  FeaturedMeta,
  FeaturedParticipantsTotal,
  Footer,
  ButtonGray,
  PriceTag,
  StyledLink,
  TagButtonWrap,
  Title,
  WraperContent,
  Wrapper,
  WrapText,
} from './styles';
import HostedContainer from './HostedContainer';
import FeaturedImageContainer from './FeaturedImageContainer';

interface FeaturedExperienceItemProps {
  listing: ListingV2;
  isEven: boolean;
}

const FeaturedExperienceItem = ({
  listing,
  isEven,
}: FeaturedExperienceItemProps) => {
  const { settings } = useAppState(
    (state: Record<string, unknown>) => state.brand,
  );
  const exclusiveTagName = settings?.promotion?.exclusiveTag || t('exlusive');
  const [isHovered, setIsHovered] = useState(false);
  const history = useHistory();
  const priceDisplay = useFormatPrice({
    bookingAvailabilityMode: listing.bookingAvailabilityMode,
    maxParticipants: listing.maxParticipants,
    startingPrice: listing.startingPrice,
  });
  const reviewsEnabled = settings?.features?.active?.includes(
    AVAILABLE_BRAND_FEATURES.REVIEWS,
  );

  const href = useMemo(() => {
    return history.createHref(getListingRoute(listing));
  }, [history, listing]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (e && (e.key === 'Enter' || e.key === ' ')) {
      e.preventDefault();
      onExperienceViewClick(e);
    }
  };

  const onExperienceViewClick = (
    e:
      | React.MouseEvent<HTMLAnchorElement>
      | React.KeyboardEvent<HTMLAnchorElement>,
  ) => {
    if (e) e.preventDefault(); // Add this line to handle both synthetic and native events
    analyticsManager.sendEvent(ANALYTICS_EVENT.CLICK_EXPERIENCE, {
      [ANALYTICS_PROPERTY.ExperienceId]: listing.id,
      [ANALYTICS_PROPERTY.ExperienceTitle]: listing.title,
      [ANALYTICS_PROPERTY.ExperienceCategory]: listing.category?.name,
      [ANALYTICS_PROPERTY.Products]: JSON.stringify([
        {
          id: listing.id,
          title: listing.title,
          category: listing.category?.name,
          startingPrice: listing.startingPrice,
          index: 0,
        },
      ]),
    });
    history.push(getListingRoute(listing));
  };

  return (
    <Wrapper
      href={href}
      id={href.replace('#', '')}
      target="_parent"
      className={`featured-experience__wrapper-${isEven ? 'even' : 'odd'}`}
      onMouseOver={() => setIsHovered(true)}
      onFocus={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onBlur={() => setIsHovered(false)}
      tabIndex={0}
      onClick={onExperienceViewClick}
      onKeyDown={handleKeyDown}
    >
      <WraperContent>
        <WrapText className="featured-experience__wrap-text">
          <StyledLink className="featured-experience__styled-link">
            <Title className="featured-experience__item-title">
              {listing.title}
            </Title>
            <Body className="featured-experience__item-headline">
              {listing.summary}
            </Body>
            <TagButtonWrap className="featured-experience__tag-button tags">
              <Button className="featured-experience__item-category-button">
                {listing.category?.name}
              </Button>
              {listing.vibes
                ?.filter((vibe) => vibe)
                .map((vibe) => (
                  <ButtonGray
                    className="featured-experience__item-vibe-button"
                    id={`featured-experience-${listing?.id}-vibe-${vibe?.id}`}
                    key={`featured-view-vibe-${vibe?.id}`}
                  >
                    {vibe.name}
                  </ButtonGray>
                ))}
            </TagButtonWrap>
            <PriceTag className="featured-experience__item-price-tag">
              <FeaturedMeta className="featured-experience__meta">
                <FeaturedParticipantsTotal className="featured-experience__participants-total">
                  {!listing.hidePrice && priceDisplay}
                  {reviewsEnabled && <RatingSummary rating={listing.rating} />}
                </FeaturedParticipantsTotal>
              </FeaturedMeta>
            </PriceTag>
            {listing.productLine !== ProductLine.RESERVE &&
            listing.hostedById ? (
              <HostedContainer hostedById={listing.hostedById} />
            ) : (
              <Footer />
            )}
          </StyledLink>
        </WrapText>
      </WraperContent>
      <FeaturedImageContainer
        exclusiveTag={exclusiveTagName}
        isExclusive={listing.isExclusive}
        isHovered={isHovered}
        title={listing.title}
        medias={listing.medias}
      />
    </Wrapper>
  );
};

export default FeaturedExperienceItem;
