import useSWR from 'swr';
import { useBrandId } from 'AppProvider';
import { ListingVersion, PublicGetListingEndpoint } from '@kouto/types';
import fetchListing from './fetchListing';

const fetchListingSWR = ([url, { brandId }]: [string, { brandId: string }]) =>
  fetchListing(url, { brandId });

const useListing = (
  listingId: string | null | undefined,
  { version }: { version: ListingVersion },
) => {
  const brandId = useBrandId();

  const endpoint = listingId
    ? PublicGetListingEndpoint.url({
        listingId,
        query: {
          version,
        },
      })
    : null;

  return useSWR(
    endpoint ? [`${process.env.KOUTO_API_URL}${endpoint}`, { brandId }] : null,
    fetchListingSWR,
  );
};

export default useListing;
