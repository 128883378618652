import React from 'react';
import getSameMonthDateLabels from 'features/LandingPage/getSameMonthDateLabels';
import { Day, InfoBox, Month } from './styles';

interface EventInfoBoxProps {
  dates: Date[] | null;
}

export const EventInfoBox = ({ dates }: EventInfoBoxProps) => {
  const nextDateLabels = getSameMonthDateLabels(dates);

  if (!nextDateLabels) {
    return null;
  }

  return (
    <InfoBox className="listing-carousel-card-details" asColumn>
      <Month>{nextDateLabels.month}</Month>
      <Day>{nextDateLabels.day}</Day>
    </InfoBox>
  );
};
