import {
  validateHyattExperienceIsEligible,
  ValidateListingsResponse,
} from 'api/hyatt';
import useSWR from 'swr';

const useValidateHyattEligibility = (
  exchangeIds: string[] | null,
  brandId: string,
  isHyattLMSEnabled: boolean,
) => {
  const { data, error } = useSWR(
    exchangeIds && exchangeIds.length > 0 && brandId && isHyattLMSEnabled
      ? [exchangeIds, brandId]
      : null,
    async ([ids, brand]) => {
      const response: ValidateListingsResponse =
        await validateHyattExperienceIsEligible(ids, brand);
      return response;
    },
  );

  if (error) {
    console.error('Error validating Hyatt experience:', error);
    return { isEligible: false, isLoading: false };
  }

  return {
    isEligible: data ? isAllListingsValid(data) : false,
    isLoading: !data,
  };
};

const isAllListingsValid = (response: ValidateListingsResponse): boolean => {
  return (
    Array.isArray(response.data.invalidListingIds) &&
    response.data.invalidListingIds.length === 0
  );
};

export default useValidateHyattEligibility;
