import React from 'react';
import styled from 'styled-components';

type XIconProps = Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
  color?: string;
  scale?: number;
};

const StyledSvg = styled.svg.withConfig({
  shouldForwardProp: (prop) => prop !== 'scale',
})<{ scale?: number }>`
  color: var(--way-colors-svgColor);
  height: ${({ scale = 1 }) => 20 * scale}px;
  width: ${({ scale = 1 }) => 20 * scale}px;
`;

const XIcon = ({ color, scale = 1, ...props }: XIconProps) => (
  <StyledSvg
    fill="none"
    scale={scale}
    style={{ color }}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.3421 4L9.99997 9.38227L4.65781 4L4 4.66275L9.29751 10L4.00007 15.3373L4.65788 16L10 10.6177L15.3422 16L16 15.3373L10.7025 9.99996L15.9999 4.66275L15.3421 4Z"
      style={{
        fill: 'currentColor',
        stroke: 'none',
      }}
    />
  </StyledSvg>
);

XIcon.displayName = 'XIcon';

export default XIcon;
