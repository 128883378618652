import React, { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { formatDateShort } from 'utils/dateTime';
import {
  getDateTimesLabelFromSessionsAvailability,
  getDatesFromSessions,
  areSessionsFree,
} from 'utils/session';
import useEventBookingSession from 'features/Events/hooks/useEventBookingSession';
import { SkeletonLineSlim } from 'components/theme/Skeleton/Skeleton';
import InfoIcon from 'assets/bx-info-circle';
import BuyTicketButton, {
  getDateStateFromSessionsAvailability,
} from './BuyTicketButton';

const MAX_DEFAULT_VISIBLE_DATES = 4;

const EventAvailability: FC = () => {
  const { isLoading, event, eventSessions, waitlistEnabled } =
    useEventBookingSession();
  const { t: translate } = useTranslation();
  const [showMore, setShowMore] = useState(false);

  const dates = useMemo(() => {
    const uniqueDates = getDatesFromSessions(eventSessions);
    return uniqueDates.map((date) => {
      return {
        date,
        isFree: areSessionsFree(eventSessions, date),
        label: formatDateShort(date),
        state: getDateStateFromSessionsAvailability(
          eventSessions,
          date,
          waitlistEnabled,
        ),
        timesLabel: getDateTimesLabelFromSessionsAvailability(
          eventSessions,
          date,
          waitlistEnabled,
        ),
      };
    });
  }, [eventSessions, waitlistEnabled]);

  if (isLoading || !event) {
    return (
      <Wrapper>
        <SkeletonLineSlim />
      </Wrapper>
    );
  }

  if (!dates.length) {
    return (
      <Wrapper>
        <NoSessionsMessage>
          <InfoIcon />
          <span>{translate('noDatesAvailable')}</span>
        </NoSessionsMessage>
      </Wrapper>
    );
  }

  return (
    <Wrapper aria-label={translate('bookExperiencePanel')}>
      <ExpandingWrapper>
        {dates
          .slice(0, showMore ? dates.length : MAX_DEFAULT_VISIBLE_DATES)
          .map(({ date, isFree, label, state, timesLabel }) => (
            <DateLine key={date} showAsColumn={dates.length === 1}>
              <DateLabel>
                <span className="activate-date">{label}</span>
                <span className="activate-time">{timesLabel}</span>
              </DateLabel>
              <BuyTicketButton
                className="activate-buy-ticket-button"
                dataTestId={`buy-ticket-on-${date}`}
                date={date}
                isFree={isFree}
                state={state}
              />
            </DateLine>
          ))}
        {!showMore && dates.length > MAX_DEFAULT_VISIBLE_DATES && (
          <DateLine showAsColumn={false}>
            <ShowMoreLabel onClick={() => setShowMore(true)}>
              {translate('seeMoreDates')}
            </ShowMoreLabel>
          </DateLine>
        )}
      </ExpandingWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 350px;
  min-height: 150px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ExpandingWrapper = styled.div`
  width: 100%;
  height: auto;
  border: solid 0.5px var(--way-colors-borderColor);
  padding: 0px 16px;

  @media (min-width: 768px) {
    position: sticky;
    top: 100px;
  }
`;

const DateLine = styled.div<{ showAsColumn: boolean }>`
  width: 100%;
  display: flex;
  height: ${({ showAsColumn }) => (showAsColumn ? 'auto' : '80px')};
  padding: ${({ showAsColumn }) => (showAsColumn ? '16px 0px ' : '0px')};
  flex-direction: ${({ showAsColumn }) => (showAsColumn ? 'column' : 'row')};
  gap: ${({ showAsColumn }) => (showAsColumn ? '12px' : '0px')};
  align-items: center;
  border-bottom: solid 0.5px var(--way-colors-borderColor);

  & > *:first-child {
    width: ${({ showAsColumn }) => (showAsColumn ? '100%' : '50%')};
    height: ${({ showAsColumn }) => (showAsColumn ? '54px' : '44px')};
  }

  & > *:last-child {
    width: ${({ showAsColumn }) => (showAsColumn ? '100%' : '50%')};
    height: 44px;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const DateLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > *:first-child {
    color: var(--way-colors-primaryTextColor);
    font-weight: bold;
    font-size: 16px;
  }

  & > *:last-child {
    color: var(--way-colors-contrastColorShades-60);
    font-size: 14px;
  }
`;

const ShowMoreLabel = styled.span`
  width: 100%;
  font-weight: bold;
  cursor: pointer;
  color: var(--way-colors-contrastColorShades-60);
`;

const NoSessionsMessage = styled.div`
  width: 100%;
  height: 48px;
  border: solid 0.5px var(--way-colors-borderColor);
  background-color: var(--way-palette-black-5);
  user-select: none;

  & * {
    display: inline-block;
  }

  & svg {
    margin: 14px;

    & path {
      stroke: none;
      fill: var(--way-colors-svgColor);
    }
  }

  & span {
    line-height: 48px;
    color: var(--way-colors-contrastColorShades-60);
    vertical-align: top;
  }

  @media (max-width: 768px) {
    font-size: 15px;
  }
`;

export default EventAvailability;
