import { SignOut } from 'components/RewardProgramSignOut';
import useHyattAuth from 'hooks/useHyattAuth';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const TertiaryText = styled.span`
  color: var(--way-colors-tertiaryTextColor);
`;

const HyattSignOut = () => {
  const { t: translate } = useTranslation();
  const { handleSignOut, getMemberInfo, verifySession, getAccessToken } =
    useHyattAuth();

  const [memberInfo, setMemberInfo] = React.useState<{
    name: string | undefined;
    points: string | undefined;
  }>({
    name: '',
    points: '',
  });

  const signOutOptions = [
    { label: translate('signOut'), value: 'signout', action: handleSignOut },
  ];

  useEffect(() => {
    const isSessionAcive = verifySession();
    if (!isSessionAcive) return;
    const token = getAccessToken();
    if (!token) return;
    const fetchData = async () => {
      try {
        const memberData = await getMemberInfo(token);
        if (memberData) {
          setMemberInfo({
            name: memberData.firstName,
            points: memberData.points,
          });
        }
      } catch (error) {
        if (error instanceof Error) {
          console.error('Error fetching member info:', error.message);
        } else {
          console.error('Unexpected error:', error);
        }
      }
    };
    fetchData();
  }, []);

  if (!memberInfo.points) return null;

  return (
    <SignOut signOutOptions={signOutOptions}>
      <>
        <span>{memberInfo.name}</span>
        <TertiaryText>{`${memberInfo.points} ${translate(
          'points',
        )}`}</TertiaryText>
      </>
    </SignOut>
  );
};

export default HyattSignOut;
