import { CartItemTotalDetails } from '@kouto/cart-prices';
import { AVAILABLE_BRAND_FEATURES } from '@kouto/types';
import { useAppState, useBrandId, useDispatch } from 'AppProvider';
import { useToastContext } from 'ToastProvider';
import { resetCartError } from 'actions/booking';
import { removeExperienceFromCart } from 'actions/cart';
import TrashIcon from 'assets/icon-trash';
import useBrandToggleFeature from 'components/BrandToggleFeature/use-brand-toggle-feature';
import ResponsiveImage from 'components/common/ResponsiveImage/ResponsiveImage';
import {
  ClearButton,
  DimmedText,
  Flex,
  LinkText,
} from 'components/common/styled/common-styled';
import { Error, ErrorContainer } from 'components/theme/Form/Form';
import { useBrandCurrency } from 'hooks/useBrandCurrency';
import * as R from 'ramda';
import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ICartExperience } from 'types/cart';
import { Listing } from 'types/listings';
import {
  addMinutesToDate,
  currencyFormat,
  formatDateForDisplay,
  formatDateForReq,
  formatTimeInAMPM,
  ISO_DATE_FORMAT,
  TIME_FORMAT,
} from 'utils';
import { useDebugLog } from 'utils/debug';
import { convertIntervalToMinutes } from 'utils/formatDuration';
import CartSummaryItemPrice from './CartSummaryItemPrice';
import {
  CartError,
  CartSummaryItemSection,
  CartSummaryItemTitle,
  ExperienceImage,
} from './styles';

const CartSummaryItem: FC<{
  cartItem: ICartExperience;
  cartItemTotalDetails: CartItemTotalDetails;
  listing?: Listing;
  canDelete?: boolean;
  canEditParticipants?: boolean;
  isPayingWithPoints?: boolean;
}> = ({
  cartItem,
  cartItemTotalDetails,
  listing,
  canDelete = true,
  canEditParticipants = true,
  isPayingWithPoints,
}) => {
  const debug = useDebugLog();
  const { showToast } = useToastContext();
  const currency = useBrandCurrency();
  const { t } = useTranslation();
  const brandId = useBrandId();
  const dispatch = useDispatch();

  const { cartError } = useAppState(
    (state: Record<string, unknown>) => state.booking,
  );
  const isFlexibleDurationEnabled = useBrandToggleFeature(
    AVAILABLE_BRAND_FEATURES.FLEXIBLE_DURATION,
  );

  const getEndDateTime = () => {
    const mins = convertIntervalToMinutes(cartItem.sessionDuration);
    const endDateTime = addMinutesToDate(mins)(cartItem.sessionDateTime);
    return formatTimeInAMPM(endDateTime, ISO_DATE_FORMAT);
  };

  const cartItemErrorMessage = useMemo(() => {
    if (!cartError) {
      return null;
    }

    debug('error', { cartError });
    return cartError.find(
      (item: Record<string, unknown>) => item.experienceId === listing?.id,
    );
  }, [debug, cartError, listing]);

  useEffect(() => {
    return () => {
      dispatch(resetCartError());
    };
  }, []);

  if (!listing) {
    return null;
  }

  const remove = () => {
    dispatch(
      removeExperienceFromCart({
        cartItemId: cartItem.cartItemId,
        persistMeta: {
          brandId,
        },
      }),
    );
    showToast({
      title: 'Success',
      message: 'Item removed from cart',
    });
  };

  const renderPrice = () => {
    const [sessionDate, sessionTime] = [
      formatDateForReq(cartItem.sessionDateTime),
      formatDateForReq(cartItem.sessionDateTime, TIME_FORMAT),
    ];

    return (
      <Flex style={{ flexDirection: 'column', alignItems: 'start' }} gap={8}>
        {canEditParticipants && (
          <LinkText
            to={`/e/${listing.id}/participants/${cartItem.cartItemId}?mode=${cartItem.bookingMode}&sessionDate=${sessionDate}&sessionTime=${sessionTime}`}
            size={14}
            aria-label={t('editParticipantsFor', {
              esperienceTitle: cartItem.groupTitle || listing.title,
            })}
          >
            {t('editParticipants')}
          </LinkText>
        )}
        {cartItemTotalDetails.selectedPriceTiers.map((priceTier) => (
          <Flex
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}
            key={priceTier.name}
          >
            <DimmedText size={14}>{t(priceTier.name)}</DimmedText>
            <CartSummaryItemPrice
              hidePrice={listing.hidePrice}
              isPrivateBooking={cartItemTotalDetails.isPrivateBooking}
              price={priceTier.price}
              selectedNumber={priceTier.selectedNumber}
              isPayingWithPoints={isPayingWithPoints}
            />
          </Flex>
        ))}
        {cartItem.addOns?.map((addOn) => (
          <Flex
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}
            key={addOn.id}
          >
            <DimmedText size={14}>{addOn.label}</DimmedText>
            <DimmedText size={14}>
              {addOn.price === 0 && !listing.hidePrice
                ? t('complimentary')
                : currencyFormat(currency)(addOn.price)}
              {` x ${addOn.selectedNumber}`}
            </DimmedText>
          </Flex>
        ))}
        {cartItemErrorMessage && (
          <CartError>
            <ErrorContainer>
              <Error>{cartItemErrorMessage?.error?.message}</Error>
            </ErrorContainer>
          </CartError>
        )}
      </Flex>
    );
  };

  return (
    <div>
      <Flex direction="row">
        <CartSummaryItemTitle>
          {cartItem.groupTitle || listing.title}
        </CartSummaryItemTitle>
        {canDelete && (
          <ClearButton
            onClick={remove}
            aria-label={t('deleteReservation', {
              esperienceTitle: cartItem.groupTitle || listing.title,
            })}
          >
            <TrashIcon />
          </ClearButton>
        )}
      </Flex>
      <CartSummaryItemSection justifyContent="normal" gap={24}>
        <ResponsiveImage
          uriObject={R.path(['coverPicture', 'uri'], listing)}
          defaultSize="384w"
          CustomRenderer={ExperienceImage}
          alt={listing.title}
          viewType="small"
        />
        <Flex direction="column" gap={8} alignItem="initial">
          <DimmedText size={16}>
            {formatDateForDisplay(cartItem.sessionDateTime)}
          </DimmedText>
          <Flex direction="row" gap={8} alignItem="initial">
            <DimmedText size={16}>
              {formatTimeInAMPM(cartItem.sessionDateTime, ISO_DATE_FORMAT)}
            </DimmedText>
            {isFlexibleDurationEnabled && (
              <DimmedText size={16}>- {getEndDateTime()}</DimmedText>
            )}
          </Flex>
        </Flex>
      </CartSummaryItemSection>
      {renderPrice()}
    </div>
  );
};

export default CartSummaryItem;
