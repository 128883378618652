import { Sep } from 'components/common/styled/common-styled';
import { PrimaryButton, SecondaryButton } from 'components/theme/Button';
import useResourceBookingSession from 'features/Reserve/hooks/useResourceBookingSession';
import { useBrandCurrency } from 'hooks/useBrandCurrency';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PriceTierRadioSelector } from '../Booking/PriceTier';
import ResourceAccessCode from './ResourceAccessCode';
import { ResourceMeta } from './ResourceMeta';

interface Props {
  isExclusive: boolean;
  onAddToCart: (code: string) => void;
  onCheckout: (code: string) => void;
  onContinue?: (code: string) => void;
  selectedPriceTierId?: string;
  onSessionSelect: (time: string, duration: number) => void;
  onTierSelect: (tierId: string) => void;
  accessCodeError: string;
}

export const ResourceDetailContainer: React.FC<Props> = ({
  onCheckout,
  onAddToCart,
  onContinue,
  selectedPriceTierId,
  onSessionSelect,
  onTierSelect,
  isExclusive,
  accessCodeError,
}) => {
  const { date, isLoading, resource, sessions, selectedSession } =
    useResourceBookingSession();

  const currency = useBrandCurrency();
  const { t: translate } = useTranslation();

  if (!currency || !resource || !selectedSession || !sessions || !date) {
    return null;
  }

  return (
    <Container className="resource-detail-container">
      <ResourceTitle className="resource-detail-title">
        {resource.title}
      </ResourceTitle>
      <Content className="resource-detail-content">
        <ResourceMeta
          date={date}
          selectedSession={selectedSession}
          sessions={sessions}
          onSessionSelect={onSessionSelect}
        />
        <Sep />
        <PriceTierRadioSelector
          value={selectedPriceTierId}
          priceTiers={selectedSession.priceTiers || []}
          currency={currency.code || ''}
          onChange={onTierSelect}
        />
        <Sep />
      </Content>
      <ResourceAccessCodeWrapper isExclusive={isExclusive}>
        <ResourceAccessCode
          errorMessage={accessCodeError}
          fullWidth
          isExclusive={isExclusive}
          renderActionButtons={(accessCode) => {
            return (
              <ActionButtonWrapper className="resource-detail-action-buttons">
                {onContinue && (
                  <PrimaryButton
                    big
                    style={{ width: '100%' }}
                    className="continue-button"
                    onClick={() => onContinue(accessCode)}
                  >
                    {translate('continue')}
                  </PrimaryButton>
                )}
                {!onContinue && (
                  <>
                    <SecondaryButton
                      big
                      disabled={isLoading}
                      onClick={() => onAddToCart(accessCode)}
                      className="add-to-cart-button"
                    >
                      {translate('addToCart')}
                    </SecondaryButton>

                    <PrimaryButton
                      big
                      className="checkout-button"
                      disabled={isLoading}
                      onClick={() => onCheckout(accessCode)}
                    >
                      {translate('checkout')}
                    </PrimaryButton>
                  </>
                )}
              </ActionButtonWrapper>
            );
          }}
        />
      </ResourceAccessCodeWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: min(100%, 846px);
  gap: 8px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 42px;
`;

const ActionButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  margin-top: 16px;
  width: 100%;
`;

const ResourceTitle = styled.span`
  font-size: 28px;
  line-height: 40px;
  font-weight: 700;
  white-space: normal;
  overflow: hidden;
`;

const ResourceAccessCodeWrapper = styled.div<{ isExclusive: boolean }>`
  margin-top: ${({ isExclusive }) => (isExclusive ? '40px' : '0px')};
`;
