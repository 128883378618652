import React from 'react';
import { ProductLine } from '@kouto/types';
import { useBrandId } from 'AppProvider';
import { HostInfoBox, ProfilePicture } from './HostInfoBox';
import { EventInfoBox } from './EventInfoBox';
import { BrandInfoBox } from './BrandInfoBox';

interface HostData {
  id: string;
  firstName: string;
  lastName: string;
  description: string | null;
  profilePicture: ProfilePicture | null;
}
interface ListingInfoBoxProps {
  dates: Date[] | null;
  hostData?: HostData[];
  hostLoading: boolean;
  productLine: ProductLine;
  listingBrandId: string | null;
}

export const ListingInfoBox = ({
  dates,
  hostData,
  hostLoading,
  productLine,
  listingBrandId,
}: ListingInfoBoxProps) => {
  const brandId = useBrandId();
  if (brandId !== listingBrandId) {
    return <BrandInfoBox brandId={listingBrandId} />;
  }

  if (productLine === ProductLine.HOST && !hostLoading && hostData) {
    return (
      <HostInfoBox
        firstName={hostData[0]?.firstName}
        profilePicture={hostData[0]?.profilePicture}
      />
    );
  }

  if (productLine === ProductLine.ACTIVATE && dates?.length) {
    return <EventInfoBox dates={dates} />;
  }

  return null;
};
