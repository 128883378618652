import { Session } from 'types/listings';
import { pipe } from 'ramda';
import filterSessionsByDateString from './filterSessionsByDateString';
import filterSessionsByTime from './filterSessionsByTime';
import filterSessionsByDuration from './filterSessionsByDuration';

const applyFilterIfAny =
  <T>(
    filterValue: T | null | undefined,
    filterFn: (value: T, items: Session[]) => Session[],
  ) =>
  (sessions: Session[]): Session[] => {
    if (!filterValue) return sessions;

    const filtered = filterFn(filterValue, sessions);
    return filtered.length > 0 ? filtered : sessions;
  };

const findSessionByFilters = (
  sessions: Session[],
  criteria: {
    date?: string | null;
    time?: string | null;
    duration?: string | null;
  } = {},
): Session | undefined => {
  const { date, time, duration } = criteria;

  const filterSessions = pipe(
    applyFilterIfAny(date, filterSessionsByDateString),
    applyFilterIfAny(time, filterSessionsByTime),
    applyFilterIfAny(duration, filterSessionsByDuration),
  );

  const filteredSessions = filterSessions(sessions);
  return filteredSessions[0];
};

export default findSessionByFilters;
