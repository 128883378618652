import { get } from '../api';
import ApiResponse from '../types/ApiResponse';

export type Brand = {
  id: string;
  name: string;
  countryCode: string;
  currency: string;
  location: string | null;
  organizationId: string | null;
  externalReferenceId: string | null;
};

const fetchBrandById = async (
  url: string,
  { brandId }: { brandId: string },
) => {
  const response = await get<ApiResponse<Brand>>(url, {
    headers: {
      'Kouto-Brand-Id': brandId,
    },
  });
  return response.data;
};

export default fetchBrandById;
