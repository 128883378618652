import React from 'react';
import { CalendarDayShape } from 'react-dates';
// @ts-expect-error:
import CustomizableCalendarDay from 'react-dates/lib/components/CustomizableCalendarDay';

const DayStyleShape = {
  width: ' 39px',
  height: '39px',
  borderRadius: '0px',
  background: 'rgba(45, 54, 87, 0.25)',
  border: '3px solid var(--way-palette-white-100)',
  padding: 0,
  margin: 0,
  lineHeight: '33px',
  fontWeight: 'normal',
  fontSize: '14px',
};

const hoveredStyles = {
  background: 'rgba(45, 54, 87, 0.4)',
  border: '3px solid var(--way-palette-white-100)',
  color: '#051E39',
  borderRadius: '0px',
};

const selectedStyles = {
  background: 'rgba(45, 54, 87, 0.4)',
  border: '3px solid var(--way-palette-white-100)',
  color: '#051E39',
  borderRadius: '0px',
};

const selectedSpanStyles = {
  background: 'rgba(45, 54, 87, 0.25)',
  border: '3px solid var(--way-palette-white-100)',
  color: '#051E39',
  borderRadius: '0px',

  hover: {
    background: 'rgba(45, 54, 87, 0.4)',
    border: '3px solid var(--way-palette-white-100)',
    color: '#051E39',
  },
};

const blockedCalendarStyles = {
  background: 'rgba(45, 54, 87, 0.09)',
  border: '3px solid var(--way-palette-white-100)',
  color: '#051E39',
  borderRadius: '0px',
};

const blockedOutOfRangeStyles = {
  background: 'rgba(45, 54, 87, 0.09)',
  border: '3px solid var(--way-palette-white-100)',
  color: 'rgba(5, 30, 57,.33)',
  borderRadius: '0px',
};

const highlightedCalendarStyles = {
  background: 'rgba(45, 54, 87, 0.25)',
  border: '3px solid var(--way-palette-white-100)',
  color: '#051E39',
  borderRadius: '0px',
  hover: {
    background: 'rgba(45, 54, 87, 0.4)',
    border: '3px solid var(--way-palette-white-100)',
    color: '#051E39',
  },
};

const outsideStyles = {
  background: 'rgba(45, 54, 87, 0.25)',
  border: '3px solid var(--way-palette-white-100)',
  color: '#051E39',
  borderRadius: '0px',
};

const customDayStyles = {
  defaultStyles: {
    ...DayStyleShape,
  },
  selectedStartStyles: {
    ...selectedStyles,
  },
  selectedEndStyles: {
    ...selectedStyles,
  },
  hoveredSpanStyles: {
    ...hoveredStyles,
  },
  selectedSpanStyles,
  selectedStyles: {
    ...selectedStyles,
  },
  highlightedCalendarStyles,
  outsideStyles,
  blockedCalendarStyles,
  blockedOutOfRangeStyles,
};

const CalendarDay = (dayProps: CalendarDayShape) => (
  <CustomizableCalendarDay {...dayProps} {...customDayStyles} />
);

export default CalendarDay;
