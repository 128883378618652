import React, { FC } from 'react';
import { SkeletonLine } from 'components/theme/Skeleton/Skeleton';
import styled from 'styled-components';
import DynamicGrid, { DynamicGridConfig } from './DynamicGrid';

interface DynamicGridLoadingProps {
  config: DynamicGridConfig;
  skeletonCount: number;
  className?: string;
}

const DynamicGridLoading: FC<DynamicGridLoadingProps> = ({
  config,
  skeletonCount,
  className,
}) => {
  const getKey = (index: number) => `skeleton-${index}`;

  return (
    <DynamicGrid className={className} config={config} count={skeletonCount}>
      {Array.from({ length: skeletonCount }).map((_, index) => (
        <CardSkeleton key={getKey(index)} gridArea={`A${index}`} />
      ))}
    </DynamicGrid>
  );
};

export default DynamicGridLoading;

const CardSkeleton = styled(SkeletonLine)<{ gridArea: string }>`
  border-radius: var(--way-design-borderRadiusDefault);
  width: 100%;
  height: 100%;
  grid-area: ${(props) => props.gridArea};
`;
