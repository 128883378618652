const POINTS_CONVERSION_RATE = 0.014;

const usePoints = (price?: number) => {
  const getPoints = (amount: number) => {
    return Math.round(amount / POINTS_CONVERSION_RATE);
  };
  const count = price !== undefined ? getPoints(price) : 0;

  return { getPoints, count };
};

export default usePoints;
