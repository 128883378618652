import styled from 'styled-components';
import { P } from 'components/theme/Typography';
import { VibeButton, TypeButton } from 'components/theme/Button';

const commonStyles = () => {
  return `
      font-style: normal;
      font-weight: normal;
      text-transform:none;
    `;
};

export const Title = styled.h3`
  ${commonStyles()};
  color: var(--way-colors-primaryTextColor);
  font-family: ${({ theme }) => theme.font.primaryFont};
  font-size: 24px;
  font-weight: normal;
  line-height: 32px;
  margin-bottom: 15px;
  margin-top: 0;
  text-transform: none;
  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 44px;
  }
`;

export const Wrapper = styled.a`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column-reverse;
  text-decoration: none;

  @media (min-width: 768px) {
    align-items: center;
    margin-bottom: 87px;
    display: flex;
    flex-direction: row;
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
`;

export const WraperContent = styled.div`
  position: relative;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  text-align: left;
  &:nth-child(even) {
    flex-direction: row-reverse;
    @media (min-width: 768px) {
      padding-right: 10px;
    }
  }

  @media (min-width: 768px) {
    width: 50%;
    padding-left: 10px;
  }
`;

export const Body = styled(P)`
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 15px;
`;

export const WrapText = styled.div`
  max-width: 400px;
`;

export const StyledLink = styled.div`
  cursor: pointer;
  color: var(--way-colors-primaryTextColor);
`;

export const TagButtonWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 7px;
`;

export const Button = styled(TypeButton)`
  margin-right: 8px;
  margin-bottom: 8px;
`;

export const ButtonGray = styled(VibeButton)`
  margin-right: 8px;
  margin-bottom: 8px;
`;

export const PriceTag = styled.div`
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 17px;
`;

export const FeaturedMeta = styled.div`
  display: flex;
  align-items: center;
`;

export const FeaturedParticipantsTotal = styled.div`
  margin-right: 24px;
`;

export const Footer = styled.div`
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
  border-bottom-color: var(--way-colors-borderColor);
  padding-bottom: 24px;
`;

export const Title4 = styled.h4`
  ${commonStyles()};
  color: var(--way-colors-primaryTextColor);
  display: block;
  font-family: ${({ theme }) => theme.font.primaryFont};
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 24px;
  margin-top: 0;
  @media (min-width: 768px) {
    font-size: 14px;
    font-weight: normal;
    line-height: 17px;
    margin-bottom: 15px;
  }
`;

export const HostedBy = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const HostedByImageWrap = styled.div`
  width: 48px;
  height: 48px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  margin-right: 8px;
`;

export const HostedByContent = styled.div`
  font-size: 12px;
  line-height: 15px;
  width: calc(100% - 55px);
`;

export const DefaultUserAvatar = styled.div`
  background-color: #dedcdc6e;
  position: relative;
  width: 48px;
  height: 48px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 8px;
  & > * {
    position: absolute;
    top: 20%;
    left: 25%;
  }
`;

export const WrapperImage = styled.div`
  position: relative;
  @media (min-width: 768px) {
    width: 50%;
    padding-right: 10px;
    &:nth-child(even) {
      flex-direction: row-reverse;
      padding-left: 10px;
    }
  }
`;

export const WrapperImageRatio = styled.div`
  padding-bottom: 56.25%;
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;
