import React from 'react';
import styled from 'styled-components';

type UnmutedIconProps = Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
  color?: string;
  scale?: number;
};

const StyledSvg = styled.svg.withConfig({
  shouldForwardProp: (prop) => prop !== 'scale',
})<{ scale?: number }>`
  color: var(--way-colors-svgColor);
  height: ${({ scale = 1 }) => 20 * scale}px;
  width: ${({ scale = 1 }) => 20 * scale}px;
`;

const UnmutedIcon = ({ color, scale = 1, ...props }: UnmutedIconProps) => (
  <StyledSvg
    fill="none"
    scale={scale}
    style={{ color }}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8333 2.5V17.5L5.91639 13.105H2.5V6.89498H5.91639L10.8333 2.5ZM10.119 4.0992L6.18806 7.61288H3.21429V12.3871H6.18806L10.119 15.9008V4.0992Z"
      style={{
        fill: 'currentColor',
        fillOpacity: '0.8',
        stroke: 'none',
      }}
    />
    <path
      d="M16.7857 10C16.7857 7.55501 14.8136 5.57296 12.381 5.57296V4.85506C15.2081 4.85506 17.5 7.15853 17.5 10C17.5 12.8415 15.2081 15.145 12.381 15.145V14.4271C14.8136 14.4271 16.7857 12.445 16.7857 10Z"
      style={{
        fill: 'currentColor',
        fillOpacity: '0.8',
        stroke: 'none',
      }}
    />
    <path
      d="M12.381 7.48736C13.7617 7.48736 14.881 8.61231 14.881 10C14.881 11.3877 13.7617 12.5127 12.381 12.5127V13.2306C14.1562 13.2306 15.5952 11.7842 15.5952 10C15.5952 8.21582 14.1562 6.76946 12.381 6.76946V7.48736Z"
      style={{
        fill: 'currentColor',
        fillOpacity: '0.8',
        stroke: 'none',
      }}
    />
  </StyledSvg>
);

UnmutedIcon.displayName = 'UnmutedIcon';

export default UnmutedIcon;
