import { PublicGetWaitlistEndpoint } from '@kouto/types';

const buildWaitlistUrl = (waitlistId: string, brandId: string) => {
  const url = PublicGetWaitlistEndpoint.url({
    brandId,
    waitlistId,
  });
  return url;
};

export default buildWaitlistUrl;
