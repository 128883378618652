import {
  DayPickerSingleDateController,
  DayPickerSingleDateControllerShape,
} from 'react-dates';
import React from 'react';
import styled from 'styled-components';
import CalendarDay from '../CalendarDay/CalendarDay';
import 'components/theme/ReactDate';
import 'react-dates/initialize';

const Wrapper = styled.div`
  div {
    box-shadow: none;
  }
`;

export const SingleDatePicker = (props: DayPickerSingleDateControllerShape) => (
  <Wrapper>
    <DayPickerSingleDateController
      enableOutsideDays={false}
      hideKeyboardShortcutsPanel
      keepOpenOnDateSelect={false}
      renderCalendarDay={CalendarDay}
      {...props}
    />
  </Wrapper>
);
