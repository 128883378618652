export class FetchError extends Error {
  public status: number;

  constructor(status: number, message: string) {
    super(message);
    this.name = 'FetchError';
    this.status = status;
  }
}

const handleResponseError = async (response: Response): Promise<never> => {
  const errorDetails = await response.text();

  throw new FetchError(response.status, errorDetails);
};

const post = async <T>(url: string, options: RequestInit = {}): Promise<T> => {
  const response = await fetch(url, {
    method: 'POST',
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
  });

  if (!response.ok) {
    await handleResponseError(response);
  }

  return response.json() as Promise<T>;
};

const get = async <T>(url: string, options: RequestInit = {}): Promise<T> => {
  const response = await fetch(url, {
    ...options,
  });

  if (!response.ok) {
    await handleResponseError(response);
  }

  return response.json() as Promise<T>;
};

export { get, post };
