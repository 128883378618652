import { BookingAvailabilityModeValue } from '@kouto/types';
import { ReducerState } from 'types/reducer';
import { useAppState } from 'AppProvider';
import { useTranslation } from 'react-i18next';
import { getCurrencyFromCode, currencyFormat } from 'utils';

interface PriceDisplayProps {
  bookingAvailabilityMode: BookingAvailabilityModeValue | null;
  maxParticipants: number | null;
  startingPrice: number | null;
}

const usePriceDisplay = ({
  bookingAvailabilityMode,
  maxParticipants,
  startingPrice,
}: PriceDisplayProps) => {
  const { t } = useTranslation();
  const { settings } = useAppState((state: ReducerState) => state.brand);
  const currency = getCurrencyFromCode(settings.currency);
  const pricing =
    bookingAvailabilityMode === 'private'
      ? (startingPrice || 0) * (maxParticipants || 0)
      : startingPrice || 0;

  const displayedPrice =
    pricing > 0 ? currencyFormat(currency)(pricing) : t('complimentary');

  return displayedPrice;
};

export default usePriceDisplay;
