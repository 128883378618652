import React, { useState, FC, ReactNode, useId } from 'react';
import styled from 'styled-components';
import { GhostButton } from '../theme/Button/Button';

interface TabsProps {
  tabsLabel: string[];
  children: ReactNode[];
  asBtn?: boolean;
}

const Tabs: FC<TabsProps> = ({ tabsLabel, children, asBtn }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const id = useId();

  if (tabsLabel.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <TabsHeader role="tablist">
        {tabsLabel.map((label, index) =>
          asBtn ? (
            <TabLabelBtn
              className="tab-label"
              role="tab"
              key={label}
              aria-selected={selectedTabIndex === index}
              aria-controls={id}
              selected={index === selectedTabIndex}
              clickable={index !== selectedTabIndex && tabsLabel.length > 1}
              onClick={() => setSelectedTabIndex(index)}
            >
              {label}
            </TabLabelBtn>
          ) : (
            <TabLabel
              aria-selected={selectedTabIndex === index}
              role="tab"
              key={label}
              aria-controls={id}
              selected={index === selectedTabIndex}
              clickable={index !== selectedTabIndex && tabsLabel.length > 1}
              onClick={() => setSelectedTabIndex(index)}
            >
              {label}
            </TabLabel>
          ),
        )}
      </TabsHeader>
      <Content role="tabpanel" id={id}>
        {tabsLabel.length === 1
          ? children[0] || children
          : children[selectedTabIndex] || null}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const TabsHeader = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const TabLabel = styled.span<{
  selected: boolean;
  clickable: boolean;
}>`
  /* skip css namespace */
  user-select: none;
  margin: 0px 12px;
  padding-bottom: 6px;
  font-size: 16px;
  font-weight: 900;
  border-bottom: ${({ selected }) =>
    selected
      ? 'solid 1px var(--way-colors-borderColor)'
      : 'solid 1px transparent'};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  color: ${({ selected, theme }) =>
    `${theme.colors.primaryTextColor}${selected ? 'ff' : '99'}`};

  &:first-child {
    margin-left: 0px;
  }
`;

const TabLabelBtn = styled(GhostButton)<{
  selected: boolean;
  clickable: boolean;
}>`
  margin: 0px 12px;

  &:first-child {
    margin-left: 0;
  }
  height: 32px;
  padding: 0;
  min-width: 32px;
  border-bottom: ${({ selected }) =>
    selected ? '1px solid var(--way-colors-contrastColorShades-100)' : 'none'};
`;

const Content = styled.div`
  padding: 16px;
  padding-left: 0px;
  min-height: 50px;
`;

export default Tabs;
