export default {
  reviewCompleteHeader: 'Review and Complete',
  participants: 'Participants',
  enterParticipantInfoText:
    'Please enter the required details to proceed to checkout.',
  purchaser: 'Purchaser',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  optionalEmail: 'Email: (optional)',
  'phone:': 'Phone',
  participantFieldPlaceholder: 'Add new or select existing participant',
  reservationSummary: 'Reservation Summary',
  editParticipantsDetails: 'Edit Participant Details',
  participantDetails: 'Details',
  duplicatePurchaserText: 'Same as Purchaser',
  phone: 'Phone',
  addParticipant: 'Additional {{tierLabel}}',
  participant: 'Participant',
  removeParticipant: 'Remove',
  experienceLimitReached:
    'Sorry, this experience has reached its maximum participant count',
  payment: 'Payment',
  duration: 'Duration',
  discountCode: 'Discount Code',
  removeCode: 'Remove Code',
  invalidCodeValidation: 'Invalid Coupon Code',
  networkErrorDiscountCode: 'Something went wrong on our side',
  discount: 'Discount',
  promo: 'Promo',
  promoCode: 'Promo code',
  apply: 'Apply',
  total: 'Total',
  subTotal: 'Subtotal',
  includes: 'Includes',
  and: 'and',
  addHostNotes: 'Add notes for your host',
  policyAggreementText:
    'I agree to the <0>Terms of Service</0> and the <1>Privacy Notice</1>',
  brandAggreementText:
    'I agree to, or I otherwise certify that I am authorized on behalf of the participant(s) to agree to, the terms and conditions of the',
  termsAggreementValidation: 'Please agree to the terms',
  firstNameValidation: 'First Name is required',
  lastNameValidation: 'Last Name is required',
  emailValidation: {
    emailRequiredText: 'Email is required',
    invalidEmail: 'Invalid Email Address',
    not_specified: 'Incorrect Email Address',
  },
  phoneValidation: {
    'Invalid Phone Number': 'Invalid Phone Number',
    not_specified: 'Invalid',
    phoneRequiredText: 'Phone number is required',
  },
  participantFirstNameValidation: 'Pariticipant First Name is required',
  participantLastNameValidation: 'Participant Last Name is required',
  complete: 'Complete',
  '404Message': 'Not Found',
  waiting: 'Waiting...',
  complimentary: 'Complimentary',
  confirmationHeader: 'Your experience has been booked!',
  confirmationText:
    'A confirmation email was sent to {{email}} with additional details',
  viewAll: 'View all Experiences',
  paymentMethod: 'Payment Method',
  creditCard: 'Credit Card',
  roomCharge: 'Room Charge',
  memberCharge: 'Member Charge',
  roomOrReservationNumber: 'Room Number or Confirmation Number',
  roomNumber: 'Room Number',
  roomNumberLegacy: 'Room Number / Confirmation Code',
  memberNumber: 'Member Number',
  kicc: 'Easy Pay',
  chargeValidation: 'Room Number and Last name is required',
  memberNumberValidation: 'Member Number is required',
  invalidRoomNumber: 'Room number is invalid',
  invalidRoomNumberLegacy: 'Room number or confirmation code is invalid',
  invalidMemberNumber: 'Member number is invalid',
  invalidPhoneNumber: 'Phone number is invalid',
  requiredPhoneNumber: 'Phone number is required',
  guestNameIsRequired: 'Guest name is required',
  roomNumberIsRequired: 'Room Number is required',
  roomNumberIsRequiredLegacy: 'Room Number or confirmation Code is required',
  roomOrReservationNumberIsRequired:
    'Room number or confirmation number is required',
  cardNumberIncomplete: 'Card Number Incomplete',
  lastNameValidationError: 'Last name is required',
  isReqd: 'is required',
  reservationCode: 'Reservation/Confirmation Number',
  nonHotelGuest: 'Non Hotel Guest',
  onPropertyGuest: 'On Property Guest',
  preArrivalGuest: 'Pre Arrival Guest',
  cardNumber: 'Card number',
  pmsValidationErrorMessage:
    'Invalid last name or room number or confirmation number',
  pmsDateValidationErrorMessage:
    'Sorry! The experience date does not fall between your arrival and departure date.',
  fullName: 'Full Name',
  checkout: 'Checkout',
  editParticipants: 'Edit Participants',
  private: 'Private',
  browseAllExperieces: 'Browse all experiences',
  payWithCreditCard: 'Pay with Credit Card',
  payWithRoomCharge: 'Pay with Room Charge',
  payWithMemberCharge: 'Pay with Member Charge',
  payWith: 'Pay with',
  bookBulkErrorByCard:
    'There was an issue confirming your booking. Your card was not charged.',
  bookBulkErrorByRoomCharge: 'Invalid last name and room number.',
  bookBulkErrorByMemberCharge: 'Invalid last name and member number.',
  reviewCancellation: 'Review Cancellation',
  selectAReservationToCancel: 'Select a reservation to cancel',
  confirmCancellation: 'Confirm cancellation',
  cancellable: 'Cancellable',
  cancelled: 'Cancelled',
  deadlinePassed: 'Deadline passed',
  noCancellations: 'No cancellations',
  youPaid: 'You paid',
  refund: 'Refund',
  yourRefund: 'Your refund',
  yourRefundWillBeProcessedToTheOriginalPayment:
    'Your refund will be processed to the original payment.',
  thisReservationCanNoLongerBeCancelledPleaseEmail:
    'This reservation can no longer be cancelled. Please email {{brandEmail}}',
  reservationCancelled: 'Reservation cancelled',
  thisReservationCannotBeCancelled: 'This reservation cannot be cancelled',
  thisReservationHasAlreadyBeenCancelled:
    'This reservation has already been cancelled',
  theHotel: 'the Hotel',
  cancelling: 'Cancelling',
  somethingWentWrong: 'Something went wrong',
  previouslyRefunded: 'Previously refunded',
  remainingBalance: 'Remaining Balance',
  notEnoughPoints: 'Not enough points',
  notEnoughPointsDescription:
    'You do not have enough World of Hyatt Points to complete this purchase.',
  currentPointsBalance: 'Current points balance: {{count, number}}',
  points: 'points',
  pointsWithCount_one: '{{count, number}} point',
  pointsWithCount_other: '{{count, number}} points',
  payWithHyattPoints: 'Pay with Hyatt Points',
  pleaseSignIn: 'Please Sign In',
  worldOfHyattPoints: 'World of Hyatt Points',
  bookBulkErrorByNotEnoughSeats:
    'Not enough seats available for the session. Please try another session instead.',
};
