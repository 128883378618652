import CalendarIcon from 'assets/calendar';
import { SecondaryButton } from 'components/theme/Button/Button';
import { Moment } from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SingleDatePicker } from 'components/datepickers/SingleDatePicker/SingleDatePicker';
import useClickOutside from 'utils/useClickOutside';
import { DayPickerSingleDateControllerShape } from 'react-dates';

export type DatePickerPopoverPosition =
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right';

type DayPickerForwardedProps = Pick<
  DayPickerSingleDateControllerShape,
  'isDayBlocked' | 'onPrevMonthClick' | 'onNextMonthClick'
>;

interface DatePickerButtonProps extends DayPickerForwardedProps {
  date: Moment | null;
  initialVisibleMonth?: (() => Moment) | null;
  onApplyButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
  onButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
  onCancel?: () => void;
  onCancelButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
  onClearButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
  onDateChange?: (date: Moment | null) => void;
  onOpenChange?: (open: boolean) => void;
  open: boolean;
  position: DatePickerPopoverPosition;
  renderButton?: (
    props: React.ComponentPropsWithRef<'button'>,
  ) => React.ReactElement<HTMLButtonElement>;
  showClearButton?: boolean;
}

const defaultRenderButton = (props: React.ComponentProps<'button'>) => (
  <SecondaryButton onClick={props.onClick}>
    <CalendarIcon />
    {props.children}
  </SecondaryButton>
);

const DatePickerButton = ({
  date: dateProp,
  initialVisibleMonth = null,
  isDayBlocked,
  onApplyButtonClick,
  onButtonClick,
  onCancel,
  onCancelButtonClick,
  onClearButtonClick,
  onDateChange,
  onNextMonthClick,
  onOpenChange,
  onPrevMonthClick,
  open,
  position,
  renderButton = defaultRenderButton,
  showClearButton = false,
}: DatePickerButtonProps) => {
  const { t: translate } = useTranslation();
  const datePickerRef = useRef<HTMLDivElement>(null);
  const lastFocusedElementRef = useRef<HTMLElement | null>(null);
  const [datePickerDate, setDatePickerDate] = useState<Moment | null>(dateProp);

  useEffect(() => {
    setDatePickerDate(dateProp);
  }, [dateProp]);

  useClickOutside(datePickerRef, () => {
    onOpenChange?.(false);
  });

  useEffect(() => {
    if (!open) return () => {};

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onCancel?.();
        lastFocusedElementRef.current?.focus();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onCancel, open]);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleOpenButtonClick: React.MouseEventHandler<HTMLButtonElement> = (
    event,
  ) => {
    onButtonClick?.(event);
    onOpenChange?.(true);
  };

  const handleCancelButtonClick: React.MouseEventHandler<HTMLButtonElement> = (
    event,
  ) => {
    onCancelButtonClick?.(event);
    onOpenChange?.(false);
    setDatePickerDate(dateProp);
  };

  const handleApplyButtonClick: React.MouseEventHandler<HTMLButtonElement> = (
    event,
  ) => {
    onApplyButtonClick?.(event);
    onDateChange?.(datePickerDate);
    onOpenChange?.(false);
  };

  const handleDateChange = (date: Moment | null) => {
    setDatePickerDate(date);
  };

  const dateText = dateProp ? dateProp.format('MMM DD') : translate('date');

  return (
    <Wrapper>
      {renderButton({
        ref: buttonRef,
        onClick: handleOpenButtonClick,
        children: dateText,
      })}
      {open && (
        <InputDatesWrapper
          aria-label={translate('dateRangeSelector')}
          position={position}
          ref={datePickerRef}
          role="dialog"
        >
          <SingleDatePicker
            date={datePickerDate}
            focused={open}
            initialVisibleMonth={initialVisibleMonth}
            isDayBlocked={isDayBlocked}
            numberOfMonths={1}
            onDateChange={handleDateChange}
            onFocusChange={() => {}}
            onNextMonthClick={onNextMonthClick}
            onPrevMonthClick={onPrevMonthClick}
          />
          <Footer>
            {showClearButton && (
              <CancelButton onClick={onClearButtonClick} disabled={!dateProp}>
                {translate('clear')}
              </CancelButton>
            )}
            <FooterSpacer />
            <CancelButton type="button" onClick={handleCancelButtonClick}>
              {translate('cancel')}
            </CancelButton>
            <ConfirmButton
              type="button"
              onClick={handleApplyButtonClick}
              disabled={!datePickerDate}
            >
              {translate('apply')}
            </ConfirmButton>
          </Footer>
        </InputDatesWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const InputDatesWrapper = styled.div<{
  position: DatePickerPopoverPosition;
}>`
  position: absolute;
  background-color: var(--way-palette-white-100);
  box-shadow: var(--way-design-boxShadow-l);
  bottom: ${({ position }) => (position.includes('top-') ? '115%' : undefined)};
  left: ${({ position }) => (position.includes('-right') ? '0' : undefined)};
  right: ${({ position }) => (position.includes('-left') ? '0' : undefined)};
  top: ${({ position }) => (position.includes('bottom-') ? '115%' : undefined)};
  z-index: 999999;

  strong {
    font-weight: normal;
  }
  td {
    color: var(--way-palette-black-100);
  }
`;

const Footer = styled.div`
  display: flex;
  padding: 14px;
  padding-top: 0px;
  gap: 12px;
`;

const FooterSpacer = styled.div`
  flex: 1;
`;

const CancelButton = styled.button`
  height: 40px;
  padding: 10px 18px;
  border: none;
  background-color: var(--way-palette-white-100);
  color: var(--way-colors-primaryColorShades-100);
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const ConfirmButton = styled.button`
  height: 40px;
  padding: 10px 14px;
  border: none;
  background-color: var(--way-colors-buttonColorShades-100);
  color: var(--way-palette-white-100);
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export default DatePickerButton;
