import TRANSLATION_ACCESSIBILITY_IT from './accessibility/translation';
import TRANSLATION_BOOKING_IT from './booking/translation';
import TRANSLATION_EVENTS_IT from './events/translation';
import TRANSLATION_EXPERIENCES_IT from './experiences/translation';
import TRANSLATION_FORM_IT from './form/translation';
import TRANSLATION_LANDING_PAGES_IT from './landingPages/translation';
import TRANSLATION_RESERVE_IT from './reserve/translation';
import TRANSLATION_SIGNIN_IT from './singin/translation';
import TRANSLATION_DATES_IT from './weekdays/translation';

export default {
  ...TRANSLATION_DATES_IT,
  ...TRANSLATION_BOOKING_IT,
  ...TRANSLATION_FORM_IT,
  ...TRANSLATION_EXPERIENCES_IT,
  ...TRANSLATION_LANDING_PAGES_IT,
  ...TRANSLATION_EVENTS_IT,
  ...TRANSLATION_RESERVE_IT,
  ...TRANSLATION_ACCESSIBILITY_IT,
  ...TRANSLATION_SIGNIN_IT,
};
