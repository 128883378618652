import initialState from 'store/initial-state';
import * as appTypes from 'types/app';
import * as types from 'types/booking';

export default function bookingReducer(state = initialState.booking, action) {
  switch (action.type) {
    case types.SET_BOOKING:
      return {
        ...state,
        booking: { ...state.booking, ...action.payload },
      };
    case types.RESET_BOOKING:
      return {
        ...state,
        booking: null,
      };
    case types.CREATE_BOOKING_REQUEST:
      return {
        ...state,
        error: '',
        responseStatus: null,
        createBookingStatus: appTypes.STATUS_PENDING,
      };
    case types.CREATE_BOOKING_FAILURE:
      return {
        ...state,
        createBookingStatus: appTypes.STATUS_FAILURE,
        responseStatus: action.payload.responseStatus,
        validationMessages: action.payload.validationMessages,
        error: action.error,
      };
    case types.CREATE_BOOKING_SUCCESS:
      return {
        ...state,
        booking: action.payload.booking,
        response: action.payload.response,
        createBookingStatus: appTypes.STATUS_SUCCESS,
      };
    case types.CREATE_PMS_VALIDATION_REQUEST:
      return {
        ...state,
        validatingStatus: appTypes.STATUS_PENDING,
      };
    case types.CREATE_PMS_VALIDATION_SUCCESS:
      return {
        ...state,
        validatingStatus: appTypes.STATUS_SUCCESS,
        validationData: action.payload.response,
      };
    case types.CREATE_PMS_VALIDATION_FAILURE:
      return {
        ...state,
        validatingStatus: appTypes.STATUS_FAILURE,
        validationMessages: [],
        error: null,
      };
    case types.CREATE_BULK_BOOKING_REQUEST:
      return {
        ...state,
        error: '',
        responseStatus: null,
        createBulkBookingStatus: appTypes.STATUS_PENDING,
        cartError: null,
      };
    case types.CREATE_BULK_BOOKING_FAILURE:
      return {
        ...state,
        createBulkBookingStatus: appTypes.STATUS_FAILURE,
        responseStatus: action.payload.responseStatus,
        validationMessages: action.payload.validationMessages,
        error: action.error,
        cartError: action.cartItemsError,
      };
    case types.CREATE_BULK_BOOKING_SUCCESS:
      return {
        ...state,
        booking: action.payload.booking,
        response: action.payload.response,
        createBulkBookingStatus: appTypes.STATUS_SUCCESS,
      };
    case types.RESET_CART_ERROR:
      return {
        ...state,
        cartError: null,
      };
    case types.GET_BOOKING_CONTEXT_REQUEST:
      return {
        ...state,
        contextFetchStatus: appTypes.STATUS_PENDING,
        bookingContext: null,
      };
    case types.GET_BOOKING_CONTEXT_SUCCESS:
      return {
        ...state,
        contextFetchStatus: appTypes.STATUS_SUCCESS,
        bookingContext: action.payload.response,
      };
    case types.GET_BOOKING_CONTEXT_FAILURE:
      return {
        ...state,
        contextFetchStatus: appTypes.STATUS_FAILURE,
      };
    default:
      return state;
  }
}
