import { useAppState, useDispatch } from 'AppProvider';

import { SignIn } from 'components/RewardProgramSignIn';
import { SignOut } from 'components/RewardProgramSignOut';
import { CartIcon } from 'features/ShoppingCart/components/CartIcon/CartIcon';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  setAuthCode,
  setSignInSuccess,
} from '../../reducers/rewardProgramIntegration';
import {
  BackLabel,
  HoverablePrevArrowIcon,
  IconBack,
  LeftAligned,
  LinksBack,
  NavigationHeaderContainer,
  RightAligned,
} from './NavigationHeader.styles';
import { useNavigationBack } from './useNavigationBack';

interface IntegrationApiData {
  name: string;
  url: string;
}

type Props = {
  goBackLabel?: string;
  showCart?: boolean;
  onGoBack?: () => void;
  preventDefaultBack?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

export const NavigationHeader: FC<Props> = ({
  goBackLabel,
  showCart = true,
  preventDefaultBack,
  className,
  onGoBack,
  style,
}) => {
  const { rewardProgramIntegration } = useAppState();
  const { rewardProgramIntegration: integration } =
    rewardProgramIntegration || {};

  const dispatch = useDispatch();

  const { t: translate } = useTranslation();
  const label = useMemo(
    () => goBackLabel || translate('back'),
    [goBackLabel, translate],
  );
  const goBack = useNavigationBack();

  const handleBackClick = useCallback(() => {
    onGoBack?.();
    if (!preventDefaultBack) goBack();
  }, [preventDefaultBack, onGoBack, goBack]);

  const baseSignInURL = integration?.apis.find(
    (api: IntegrationApiData) => api.name === 'signup',
  )?.url;

  const handleHyattSignInSuccess = (params: URLSearchParams) => {
    if (params.has('auth_code')) {
      const authCode = params.get('auth_code');
      if (authCode) {
        dispatch(setSignInSuccess());
        dispatch(setAuthCode(authCode));
        // get user info
      }
    }
  };

  const onSignIn = () => {
    const searchParams = window.location.search;
    const params = new URLSearchParams(searchParams);
    if (integration?.organization === 'Hyatt') {
      handleHyattSignInSuccess(params);
    }
  };

  return (
    <NavigationHeaderContainer
      role="navigation"
      style={style}
      className={className}
    >
      <LeftAligned>
        <LinksBack
          tabIndex={0}
          role="button"
          aria-label={translate('goBack')}
          title={label}
          className="header__back-button"
          onClick={handleBackClick}
        >
          <IconBack className="header__back-button-icon">
            <HoverablePrevArrowIcon />
          </IconBack>
          <BackLabel>{label}</BackLabel>
        </LinksBack>
      </LeftAligned>
      <RightAligned>
        {integration?.enabled &&
          (integration?.isSignedIn ? (
            <SignOut />
          ) : (
            <SignIn
              onSignIn={onSignIn}
              targetBaseUrl={baseSignInURL}
              signInLabel={translate('signIn')}
            />
          ))}
        {showCart && <CartIcon />}
      </RightAligned>
    </NavigationHeaderContainer>
  );
};
