import { useAppState } from 'AppProvider';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RewardProgramState } from 'reducers/rewardProgramIntegration';
import styled from 'styled-components';

export const DimmedText = styled.div`
  font-size: 14px;
  color: var(--waw-colors-contrastColorShades-70);
`;
const NotEnoughPointsMessage = () => {
  const { t } = useTranslation();

  const { rewardProgramIntegration }: RewardProgramState = useAppState(
    (state: Record<string, unknown>) => state.rewardProgramIntegration,
  );
  return (
    <>
      <DimmedText>{t('notEnoughPointsDescription')}</DimmedText>
      <DimmedText>
        {t('currentPointsBalance', {
          count: Number(rewardProgramIntegration?.points),
        })}
      </DimmedText>
    </>
  );
};

export default NotEnoughPointsMessage;
