import { getEmbedConfig } from 'features/EmbedConfig';
import * as types from 'types/brand';
import fetchApi from 'utils/fetch';
import * as helpers from './helpers';
/**
 * Read
 */

const fetchBrandSettingsRequest = ({ id }) => ({
  type: types.FETCH_SETTINGS_REQUEST,
  payload: {
    id,
  },
});

const fetchBrandSettingsSuccess = ({ settings }) => ({
  type: types.FETCH_SETTINGS_SUCCESS,
  payload: {
    settings,
  },
});

export const fetchBrandSettings = (id) => async (dispatch) => {
  dispatch(fetchBrandSettingsRequest({ id }));
  try {
    const { data } = await fetchApi.get(`v1/brands/${id}/settings`).json();
    return dispatch(fetchBrandSettingsSuccess({ settings: data }));
  } catch (errorResponse) {
    return dispatch(
      helpers.errorAction(types.FETCH_SETTINGS_FAILURE, errorResponse),
    );
  }
};

const fetchBrandTermsRequest = ({ id }) => ({
  type: types.FETCH_TERMS_REQUEST,
  payload: {
    id,
  },
});

const fetchBrandTermsSuccess = ({ terms }) => ({
  type: types.FETCH_TERMS_SUCCESS,
  payload: {
    terms,
  },
});

export const fetchBrandTerms = (id) => async (dispatch) => {
  dispatch(fetchBrandTermsRequest({ id }));
  try {
    const { items } = await fetchApi
      .get(`v1/brands/${id}/terms-and-conditions`)
      .json();
    return dispatch(fetchBrandTermsSuccess({ terms: items }));
  } catch (errorResponse) {
    return dispatch(
      helpers.errorAction(types.FETCH_TERMS_FAILURE, errorResponse),
    );
  }
};

/**
 * List Categories/Types
 */
const searchCategoryRequest = () => ({
  type: types.SEARCH_CATEGORY_REQUEST,
});

const searchCategorySuccess = ({ categories }) => ({
  type: types.SEARCH_CATEGORY_SUCCESS,
  payload: {
    categories,
  },
});

export const searchCategories =
  ({ query }, options) =>
  async (dispatch) => {
    if (!options.brandId) {
      throw new Error('options.brandId not set or invalid');
    }
    dispatch(searchCategoryRequest());
    try {
      const { items } = await fetchApi
        .get(`v1/brands/${options.brandId}/categories`, {
          searchParams: query,
        })
        .json();
      return dispatch(searchCategorySuccess({ categories: items }));
    } catch (errorResponse) {
      return dispatch(
        helpers.errorAction(types.SEARCH_CATEGORY_FAILURE, errorResponse),
      );
    }
  };

/**
 * List Vibes
 */
const searchVibesRequest = () => ({
  type: types.SEARCH_VIBES_REQUEST,
});

const searchVibesSuccess = ({ vibes }) => ({
  type: types.SEARCH_VIBES_SUCCESS,
  payload: {
    vibes,
  },
});

export const searchVibes =
  ({ query }, options) =>
  async (dispatch) => {
    if (!options.brandId) {
      throw new Error('options.brandId not set or invalid');
    }
    dispatch(searchVibesRequest());
    try {
      const { items } = await fetchApi
        .get(`v1/brands/${options.brandId}/vibes`, {
          searchParams: helpers.filterEmpty(query),
        })
        .json();
      return dispatch(searchVibesSuccess({ vibes: items }));
    } catch (errorResponse) {
      return dispatch(
        helpers.errorAction(types.SEARCH_VIBES_FAILURE, errorResponse),
      );
    }
  };

const getCalendarEventsRequest = () => ({
  type: types.FETCH_CALENDAR_EVENTS_REQUEST,
});

const getCalendarEventsSuccess = ({ data }) => ({
  type: types.FETCH_CALENDAR_EVENTS_SUCCESS,
  payload: {
    data,
  },
});

export const getCalendarEvents =
  (brandId, from, to, query = {}) =>
  async (dispatch) => {
    dispatch(getCalendarEventsRequest());

    const embedConfig = getEmbedConfig();

    try {
      const { data } = await fetchApi
        .get(`v2/brands/${brandId}/sessions/${from}/${to}`, {
          searchParams: helpers.filterEmpty({
            ...query,
            categoryIds: embedConfig?.categoryId
              ? [embedConfig.categoryId]
              : query.categoryIds ?? [],
          }),
        })
        .json();
      return dispatch(
        getCalendarEventsSuccess({
          data,
        }),
      );
    } catch (errorResponse) {
      return dispatch(
        helpers.errorAction(types.FETCH_CALENDAR_EVENTS_FAILURE, errorResponse),
      );
    }
  };

const getCalendarFirstAvailableDateRequest = () => ({
  type: types.FETCH_CALENDAR_FIRST_AVAILABLE_DATE_REQUEST,
});

const getCalendarFirstAvailableDateSuccess = ({ data }) => ({
  type: types.FETCH_CALENDAR_FIRST_AVAILABLE_DATE_SUCCESS,
  payload: {
    data,
  },
});

export const getCalendarFirstAvialbleDate =
  (brandId, query) => async (dispatch) => {
    dispatch(getCalendarFirstAvailableDateRequest());
    try {
      const { data } = await fetchApi
        .get(`v2/brands/${brandId}/first-available-session`, {
          searchParams: query,
        })
        .json();
      return dispatch(
        getCalendarFirstAvailableDateSuccess({
          data,
        }),
      );
    } catch (errorResponse) {
      return dispatch(
        helpers.errorAction(
          types.FETCH_CALENDAR_FIRST_AVAILABLE_DATE_FAILURE,
          errorResponse,
        ),
      );
    }
  };

const fetchBrandIntegrationsRequest = ({ id }) => ({
  type: types.FETCH_INTEGRATIONS_REQUEST,
  payload: {
    id,
  },
});

const fetchBrandIntegrationsSuccess = ({ integrations }) => ({
  type: types.FETCH_INTEGRATIONS_SUCCESS,
  payload: {
    integrations,
  },
});

export const fetchBrandIntegrations = (id) => async (dispatch) => {
  dispatch(fetchBrandIntegrationsRequest({ id }));
  try {
    const { items } = await fetchApi.get(`v1/brands/${id}/integrations`).json();
    return dispatch(fetchBrandIntegrationsSuccess({ integrations: items }));
  } catch (errorResponse) {
    return dispatch(
      helpers.errorAction(types.FETCH_INTEGRATIONS_FAILURE, errorResponse),
    );
  }
};
