import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FormProvider, useForm } from 'hook-form';
import { SkeletonLine } from 'components/theme/Skeleton/SkeletonLine';
import { PaymentMethod } from 'types/payment';
import { PrimaryButton } from 'components/theme/Button/Button';
import { useAppState, useBrandId, useDispatch } from 'AppProvider';
import { PurchaserSection } from 'features/ShoppingCart/components/PurchaserSection';
import * as Styled from 'features/ShoppingCart/utils/styles';
import {
  ICheckoutConfig,
  IWaitlistExperience,
  JoinWaitlistRequest,
} from 'features/ShoppingCart/types';
import { useToastContext } from 'ToastProvider';
import useSearchQueryParams from 'hooks/use-search-params';
import { ANALYTICS_EVENT } from '@kouto/types';
import { joinWaitlistAction, cleanWaitlist } from 'actions/waitlist';
import { usePurchaserFromEmbedConfig } from 'features/EmbedConfig';
import useCustomHistory from 'hooks/use-custom-history';
import { ICartExperience } from 'types/cart';
import useEventBookingSessionSelectedData from 'features/Events/hooks/useEventBookingSessionSelectedData';

interface ICheckoutProps {
  waitlistItem: ICartExperience;
  waitlistExperience: IWaitlistExperience;
}

const parseCustomQuestionsForRequest = (
  questions: ICartExperience['additionalCustomQuestionResponses'],
): JoinWaitlistRequest['purchaser']['customQuestionResponses'] => {
  return Object.entries(questions).flatMap(([questionId, answer]) => {
    if (!questionId || !answer || answer.length === 0) return [];
    return {
      questionId,
      answer,
    };
  });
};

const JoinWaitlistPage: React.FC<ICheckoutProps> = ({
  waitlistExperience,
  waitlistItem,
}) => {
  const { showToast } = useToastContext();
  const { push: customPush } = useCustomHistory();
  const brandId = useBrandId();
  const dispatch = useDispatch();
  const { bookingContext } = useAppState(
    (state: Record<string, unknown>) => state.booking,
  );
  const { searchParams } = useSearchQueryParams();
  const embedUserData = usePurchaserFromEmbedConfig();
  const [loading, setLoading] = useState(false);
  const { event } = useEventBookingSessionSelectedData({
    brandId,
    eventId: waitlistItem.listingId,
  });

  const methods = useForm<ICheckoutConfig>({
    mode: 'onChange',
    defaultValues: {
      purchaser: bookingContext?.purchaser ?? embedUserData,
      paymentName:
        bookingContext?.purchaser?.lastName ?? embedUserData?.lastName,
      reservationNumber: embedUserData?.reservationNumber,
    },
  });

  const { t } = useTranslation();

  const onCheckout = async (formData: ICheckoutConfig) => {
    setLoading(true);
    const getWaitlistItemData = () => {
      const additionalCustomQuestionResponses = parseCustomQuestionsForRequest(
        waitlistItem.additionalCustomQuestionResponses || {},
      );

      const participants = waitlistItem.participants.map((data) => {
        const { customQuestionResponses, ...participant } = data;

        const p = {
          ...data,
          ...(data.fullName === 'Resource Purchaser'
            ? {
                firstName: formData.purchaser.firstName,
                fullName: [
                  formData.purchaser.firstName,
                  formData.purchaser.lastName,
                ].join(' '),
                lastName: formData.purchaser.lastName,
              }
            : null),
        };

        if (!customQuestionResponses) {
          return participant;
        }

        return {
          ...p,
          customQuestionResponses: parseCustomQuestionsForRequest(
            customQuestionResponses || {},
          ),
        };
      });

      return {
        ...waitlistItem,
        sessionDuration: moment
          .duration(waitlistItem.sessionDuration)
          .asMinutes(),
        additionalCustomQuestionResponses,
        participants,
      };
    };

    const data = getWaitlistItemData();
    const joinWaitlistData: JoinWaitlistRequest = {
      sessionStartDate: data.sessionDateTime.split('T')[0],
      sessionStartTime: data.sessionDateTime.split('T')[1],
      sessionDuration: data.sessionDuration,
      participants: data.participants,
      purchaser: {
        firstName: formData.purchaser.firstName,
        lastName: formData.purchaser.lastName,
        emailAddress: formData.purchaser.emailAddress,
        phoneNumber: formData.purchaser.phoneNumber,
        customQuestionResponses: data.additionalCustomQuestionResponses,
      },
    };

    const waitlistId = searchParams.waitlistId as string;
    if (waitlistId) {
      const result = await joinWaitlistAction(
        event?.brandId ?? brandId,
        waitlistId,
        joinWaitlistData,
      );

      if (result) {
        showToast({
          title: 'Success',
          message: `Joined waitlist for ${moment(
            data.sessionDateTime.split('T')[1],
            'HH:mm:ss',
          ).format('hh:mm A')}`,
          type: 'success',
        });
        dispatch(cleanWaitlist(undefined));
        customPush({
          pathname: '/',
          search: '',
          unset: [
            'mode',
            'noOfGuests',
            'sessionTime',
            'duration',
            'sessionDate',
            'supportedParticipantsCount',
          ],
        });
      } else {
        showToast({
          title: 'Error',
          message: 'Could not join the waitlist',
          type: 'error',
        });
        setLoading(false);
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <Styled.FormSection onSubmit={methods.handleSubmit(onCheckout)}>
        <div>
          {!waitlistItem ? (
            <>
              <SkeletonLine style={{ height: 100 }} translucent />
              <SkeletonLine style={{ height: 300 }} translucent />
            </>
          ) : (
            <>
              <Styled.HeadTitle>{t('checkout')}</Styled.HeadTitle>
              <PurchaserSection
                experiences={waitlistExperience ? [waitlistExperience] : []}
              />
              <PrimaryButton
                big
                className="cart__form-button-full cart__complete-checkout-button"
                type="submit"
                disabled={
                  loading ||
                  String(searchParams.latest).toLowerCase() === 'true'
                }
                analyticEvent={ANALYTICS_EVENT.CLICK_COMPLETE_PAYMENT_BUTTON}
                analyticData={{
                  paymentMethod:
                    methods.getValues('activeOption') ??
                    PaymentMethod.CREDIT_CARD,
                }}
              >
                {loading ? `${t('joining')}...` : t('joinWaitlist')}
              </PrimaryButton>
            </>
          )}
        </div>
      </Styled.FormSection>
    </FormProvider>
  );
};

export const PaymentContainer = styled.div``;

export const PaymentValidation = styled.div`
  margin: 15px 0;
`;

export default JoinWaitlistPage;
