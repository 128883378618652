import { useHistory, useLocation } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import useSearchParams from './useSearchParams';

const useSetSearchParam = () => {
  const searchParams = useSearchParams();
  const history = useHistory();
  const { pathname } = useLocation();

  const setSearchParam = (
    key: string,
    value: string | string[] | null,
    options: { replace?: boolean } = { replace: false },
  ) => {
    if (value === null) {
      searchParams.delete(key);
    } else if (Array.isArray(value)) {
      searchParams.set(key, value.join(','));
    } else {
      searchParams.set(key, value);
    }

    const newSearch = searchParams.toString();

    const historyUpdate: LocationDescriptor = {
      pathname,
      search: newSearch || undefined,
    };

    const method = options.replace ? 'replace' : 'push';
    history[method](historyUpdate);
  };

  return setSearchParam;
};

export default useSetSearchParam;
