import { GetHostsEndpoint } from '@kouto/types';
import { z } from 'zod';
import { get } from '../api';
import ApiResponse from '../types/ApiResponse';

export type Hosts = z.infer<typeof GetHostsEndpoint.responseSchema>;

const fetchHosts = async (url: string, { brandId }: { brandId: string }) => {
  const response = await get<ApiResponse<Hosts>>(url, {
    headers: {
      'Kouto-Brand-Id': brandId,
    },
  });
  return response.data;
};

export default fetchHosts;
