import React from 'react';
import styled from 'styled-components';

type UserIconProps = Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
  color?: string;
  scale?: number;
};

const StyledSvg = styled.svg.withConfig({
  shouldForwardProp: (prop) => prop !== 'scale',
})<{ scale?: number }>`
  color: var(--way-colors-svgColor);
  height: ${({ scale = 1 }) => 24 * scale}px;
  width: ${({ scale = 1 }) => 24 * scale}px;
`;

const UserIcon = ({ color, scale = 1, ...props }: UserIconProps) => (
  <StyledSvg
    role="img"
    fill="none"
    scale={scale}
    style={{ color }}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>User icon</title>
    <desc>default user icon</desc>
    <path
      d="M12 12.8571C16.497 12.8571 20.1429 16.503 20.1429 21H21C21 16.0294 16.9706 12 12 12C7.02943 12 3 16.0294 3 21H3.85714C3.85714 16.503 7.50279 12.8571 12 12.8571Z"
      style={{
        fill: 'currentColor',
        stroke: 'none',
      }}
    />
    <path
      d="M12 3C9.27793 3 7.07143 5.2065 7.07143 7.92857C7.07143 10.6506 9.27793 12.8571 12 12.8571C14.7221 12.8571 16.9286 10.6506 16.9286 7.92857C16.9286 5.2065 14.7221 3 12 3ZM12 12C9.75129 12 7.92857 10.1773 7.92857 7.92857C7.92857 5.67986 9.75129 3.85714 12 3.85714C14.2487 3.85714 16.0714 5.67986 16.0714 7.92857C16.0714 10.1773 14.2487 12 12 12Z"
      style={{
        fill: 'currentColor',
        stroke: 'none',
      }}
    />
  </StyledSvg>
);

UserIcon.displayName = 'UserIcon';

export default UserIcon;
