import React from 'react';
import styled from 'styled-components';

type PauseIconProps = Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
  color?: string;
  scale?: number;
};

const StyledSvg = styled.svg.withConfig({
  shouldForwardProp: (prop) => prop !== 'scale',
})<{ scale?: number }>`
  color: var(--way-colors-svgColor);
  height: ${({ scale = 1 }) => 20 * scale}px;
  width: ${({ scale = 1 }) => 20 * scale}px;
`;

const PauseIcon = ({ color, scale = 1, ...props }: PauseIconProps) => (
  <StyledSvg
    fill="none"
    scale={scale}
    style={{ color }}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 16L6 4L7 4L7 16L6 16Z"
      style={{
        fill: 'currentColor',
        stroke: 'none',
      }}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 16L13 4L14 4L14 16L13 16Z"
      style={{
        fill: 'currentColor',
        stroke: 'none',
      }}
    />
  </StyledSvg>
);

PauseIcon.displayName = 'PauseIcon';

export default PauseIcon;
