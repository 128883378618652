import React, { ReactNode, forwardRef } from 'react';
import { ANALYTICS_EVENT, ANALYTICS_PROPERTY } from '@kouto/types';
import { analyticsManager } from 'features/analytics';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  analyticEvent?: ANALYTICS_EVENT;
  analyticData?: Partial<Record<ANALYTICS_PROPERTY, string>>;
  isPrimary?: boolean;
  big?: boolean;
  selected?: boolean;
}

const BaseButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, onClick, analyticEvent, analyticData, ...props }, ref) => (
    <button
      // eslint-disable-next-line react/button-has-type
      type={props.type || 'button'}
      ref={ref}
      {...props}
      onClick={(e) => {
        if (analyticEvent) {
          analyticsManager.sendEvent(analyticEvent, analyticData);
        }
        onClick?.(e);
      }}
    >
      {children}
    </button>
  ),
);

export default BaseButton;
