import React, { ReactNode } from 'react';

import { Flex } from 'components/common/styled/common-styled';
import styled from 'styled-components';

const StyledFlex = styled(Flex)`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ChipList = ({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) => (
  <StyledFlex
    as="ul"
    className={className}
    flexWrap="wrap"
    gap={6}
    justifyContent="flex-start"
  >
    {children}
  </StyledFlex>
);

export default ChipList;
