import i18n from 'i18next';
import { cloneDeep } from 'lodash';
import get from 'lodash/get';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import styled from 'styled-components';

import { searchCategories, searchVibes } from 'actions/brand';
import { searchExperiences } from 'actions/experience';
import { useAppState, useBrandId, useDispatch } from 'AppProvider';
import PrevArrowIcon from 'assets/chevron-left';
import NextArrowIcon from 'assets/chevron-right';
import { CollectionItem } from 'components/CollectionItem';
import DateRangePicker from 'components/DateRangePicker';
import EventItem from 'components/EventItem/EventItem';
import ExperienceItem from 'components/ExperienceItem';
import { ButtonBlock, ButtonLink } from 'components/theme/Button';
import { IconBackLight } from 'components/theme/IconBackLight';
import { SearchAllWrapperStart } from 'components/theme/SearchAllWrapperStart';
import { MainTitle } from 'components/theme/Typography';
import { Wrapper } from 'components/theme/Wrapper';
import ToggleFeature from 'components/ToggleFeature';
import { useIsNarrowView } from 'ContainerDimensionProvider';
import { HomePageAnalyticsTracker } from 'features/analytics/components/HomePageAnalyticsTracker';
import { CartIcon } from 'features/ShoppingCart/components/CartIcon/CartIcon';
import useCustomHistory from 'hooks/use-custom-history';
import { useExperienceFilterParam } from 'hooks/use-experience-filter-param';
import useSearchQueryParams from 'hooks/use-search-params';
import { formatDateForMonth, formatDateForReq, serializeParams } from 'utils';
import { getListingCoverPictures } from 'utils/collections';
import { findFirstDateInThefuture } from 'utils/dateTime';
import { getUniquePricesForFirstAvailableDate } from 'utils/listings';
import { useIsMobile } from 'WindowDimensionProvider';
import { CollectionItemSkeleton } from '../CollectionItem/CollectionItemSkeleton';
import { useSelectStyles } from './select-styles';

const Experiences = ({
  classId,
  hidePagination,
  limit,
  showTitle,
  redirectToSearch,
  asHomePage,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isNarrowView = useIsNarrowView();
  const brandId = useBrandId();
  const selectStyles = useSelectStyles();
  const { searchParams, replaceParams } = useSearchQueryParams();
  const { push: customPush } = useCustomHistory();

  const { categories, vibes, settings } = useAppState((state) => state.brand);
  const { experiences, pagination, searchError } = useAppState(
    (state) => state.experience,
  );

  const exclusiveTagName =
    get(settings, 'promotion.exclusiveTag') ?? 'Exclusive';
  const brandName = get(settings, 'name');

  const [dateRange, setDateRange] = useState({
    startDateCalendar: null,
    endDateCalendar: null,
  });
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState(null);
  const [selectedVibes, setSelectedVibes] = useState(null);
  const experienceFilterParam = useExperienceFilterParam();
  const { startDateCalendar, endDateCalendar } = dateRange;
  const [currentPage, setCurrentPage] = useState(1);

  const getRangeFormattedDate = (startDate, endDate) => {
    return `${startDate}--${endDate}`;
  };

  const getDateRangeInputvalue = () => {
    const formatedStartDate = startDateCalendar
      ? formatDateForReq(startDateCalendar)
      : '';
    const formatedEndDate = endDateCalendar
      ? formatDateForReq(endDateCalendar)
      : '';
    if (!formatedStartDate && !formatedEndDate) return '';
    if (!formatedEndDate)
      return getRangeFormattedDate(formatedStartDate, formatedStartDate);
    return getRangeFormattedDate(formatedStartDate, formatedEndDate);
  };
  const getRangeFormattedDateForView = (startDate, endDate) => {
    return `${startDate} - ${endDate}`;
  };

  const getDateRangeInputvalueForView = () => {
    const formatedStartDate = startDateCalendar
      ? formatDateForMonth(startDateCalendar)
      : '';
    const formatedEndDate = endDateCalendar
      ? formatDateForMonth(endDateCalendar)
      : '';
    if (!formatedStartDate && !formatedEndDate) return '';
    if (!formatedEndDate)
      return getRangeFormattedDateForView(formatedStartDate, formatedStartDate);
    return getRangeFormattedDateForView(formatedStartDate, formatedEndDate);
  };

  const handleOnDateChange = ({ startDate, endDate }) => {
    setDateRange({
      startDateCalendar: moment(startDate),
      endDateCalendar:
        startDate.isAfter(endDate) || endDate === null ? null : moment(endDate),
    });
  };

  const onClear = () => {
    setDateRange({
      startDateCalendar: null,
      endDateCalendar: null,
    });
  };

  const renderItems = () => {
    if (Array.isArray(searchError)) {
      return <div>{searchError[0]}</div>;
    }

    if (searchError) {
      return <div>{searchError}</div>;
    }

    if (!experiences.length || experiences[0]?.loading) {
      return [0, 1, 2].map((v) => <CollectionItemSkeleton key={v} />);
    }

    return (
      <>
        {experiences.map((listingItem, index) => {
          if (listingItem.kind === 'resource') {
            return (
              <CollectionItem
                key={listingItem.id}
                id={listingItem.id}
                index={index + 1}
                title={listingItem.title}
                categoryName={listingItem.category?.name}
                image={listingItem.coverPicture}
                preselectGroupOnClick={!!listingItem.map}
                collectionResourceGroups={listingItem.resourceGroups}
                exclusiveTagName={
                  listingItem.isExclusive ? exclusiveTagName : null
                }
                priceTiers={listingItem.priceTiers}
              />
            );
          }

          if (listingItem.kind === 'event') {
            return (
              <EventItem
                key={listingItem.id}
                id={listingItem.id}
                title={listingItem.title}
                image={getListingCoverPictures(listingItem)?.[0]}
                dates={[
                  listingItem?.firstAvailableDate?.scheduledDate ||
                    findFirstDateInThefuture(
                      listingItem?.dates,
                      listingItem?.startTimes,
                    ) ||
                    '',
                ]}
                prices={getUniquePricesForFirstAvailableDate(listingItem)}
                exclusiveTagName={
                  listingItem.isExclusive ? exclusiveTagName : null
                }
              />
            );
          }

          if (!listingItem.currency) {
            return null;
          }

          return (
            <ExperienceItem
              key={`experience-item-${listingItem.id}`}
              experience={listingItem}
              order={index + 1}
            />
          );
        })}
      </>
    );
  };

  const selectTheme = (theme) => ({
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      primary25: 'gray',
      primary: 'black',
    },
  });

  const searchOptions = {
    brandId,
  };

  const filterExperiences = (query) => {
    const listingsQuery = cloneDeep(query);
    if (listingsQuery.range) {
      const [from, to] = listingsQuery.range.split('--');
      listingsQuery.from = from;
      listingsQuery.to = to;
      delete listingsQuery.range;
    } else {
      delete listingsQuery.range;
    }
    if (!listingsQuery.categoryIds || !listingsQuery.categoryIds[0]) {
      delete listingsQuery.categoryIds;
    }
    if (!listingsQuery.vibeIds || !listingsQuery.vibeIds[0]) {
      delete listingsQuery.vibeIds;
    }
    dispatch(
      searchExperiences(
        { ...listingsQuery, bypassFirstAvailableDate: 'true' },
        searchOptions,
      ),
    );
  };

  const translateLang = (translateKey) =>
    t(translateKey, { returnObjects: true });

  const setCategoryFromUrl = (categoryIds) => {
    if (Array.isArray(categoryIds)) {
      setSelectedCategories(searchParams.categoryIds);
      return;
    }
    setSelectedCategories([searchParams.categoryIds]);
  };

  const setVibesFromUrl = (vibeIds) => {
    if (Array.isArray(vibeIds)) {
      setSelectedVibes(searchParams.vibeIds);
      return;
    }
    setSelectedVibes([searchParams.vibeIds]);
  };

  const queryBrandExperiences = () => {
    filterExperiences({
      limit,
      range:
        searchParams.startDate &&
        searchParams.endDate &&
        getRangeFormattedDate(searchParams.startDate, searchParams.endDate),
      categoryIds: [searchParams.categoryIds].flat(),
      vibeIds: [searchParams.vibeIds].flat(),
    });
  };

  useEffect(() => {
    moment.updateLocale('en', {
      weekdaysMin: translateLang('weekdays'),
      months: translateLang('months'),
      monthsShort: translateLang('formattedShortMonths'),
    });
  }, [i18n.language]);

  useEffect(() => {
    dispatch(searchCategories({}, searchOptions));
    dispatch(searchVibes({}, searchOptions));
  }, []);

  useEffect(() => {
    if (searchParams?.categoryIds) {
      setCategoryFromUrl(searchParams.categoryIds);
    }
    if (searchParams?.vibeIds) {
      setVibesFromUrl(searchParams.vibeIds);
    }
    queryBrandExperiences();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (searchParams?.startDate && searchParams?.endDate) {
      setDateRange({
        startDateCalendar: searchParams.startDate,
        endDateCalendar: searchParams.endDate,
      });
    }
  }, []);

  const onSearchClick = () => {
    const formatedStartDate = startDateCalendar
      ? formatDateForReq(startDateCalendar)
      : '';

    const formatedEndDate = endDateCalendar
      ? formatDateForReq(endDateCalendar)
      : '';

    const selectedSearchParams = {
      ...(formatedStartDate ? { startDate: formatedStartDate } : {}),
      ...(formatedEndDate || formatedStartDate
        ? { endDate: formatedEndDate || formatedStartDate }
        : {}),
      categoryIds: selectedCategories,
      vibeIds: selectedVibes,
      lang: searchParams?.lang,
    };
    replaceParams(selectedSearchParams);

    if (redirectToSearch) {
      customPush({
        pathname: `/experiences`,
        search: serializeParams(selectedSearchParams),
      });
    }

    filterExperiences({
      limit,
      range: getDateRangeInputvalue(),
      categoryIds: selectedCategories,
      vibeIds: selectedVibes,
    });
  };

  const mapSelect = (list) =>
    list.map((listItem) => ({
      value: listItem.id,
      label: listItem.name,
    }));

  const loadCategories = () => mapSelect(categories);

  const getCategoryNameFromId = (categoryList, id) => {
    return categoryList.find((category) => category.id === id)?.name;
  };

  const getVibeNameFromId = (vibeList, id) => {
    return vibeList.find((vibe) => vibe.id === id)?.name;
  };

  const getSelectedCategoriesObject = (categoryIds) => {
    return categoryIds?.map((categoryId) => ({
      value: categoryId,
      label: getCategoryNameFromId(categories, categoryId),
    }));
  };

  const getSelectedVibesObject = (vibeIds) => {
    return vibeIds?.map((vibeId) => ({
      value: vibeId,
      label: getVibeNameFromId(vibes, vibeId),
    }));
  };

  const loadVibes = () => mapSelect(vibes);

  // const loadLocations = () => {
  //   // TODO fetch locations from google
  // };

  const mapSelectValue = (valueObject) => {
    return valueObject.value;
  };

  const onCategoriesChangeHandler = (values) => {
    const categoryIds = values?.map(mapSelectValue);
    setSelectedCategories(categoryIds);
  };

  const onVibesChangeHandler = (values) => {
    const vibeIds = values?.map(mapSelectValue);
    setSelectedVibes(vibeIds);
  };

  // const onLocationChangeHandler = (value) => {
  //   setSelectedLocation(value.value);
  // };

  const onPrev = () => {
    if (currentPage === 1) return;
    const prevPage = currentPage - 1;
    setCurrentPage(prevPage);
    filterExperiences({
      limit,
      range: getDateRangeInputvalue(),
      page: prevPage,
      categoryIds: selectedCategories,
      vibeIds: selectedVibes,
      // location: selectedLocation,
    });
    window.scrollTo(0, 0);
  };

  const onNext = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    filterExperiences({
      limit,
      range: getDateRangeInputvalue(),
      page: nextPage,
      categoryIds: selectedCategories,
      vibeIds: selectedVibes,
      // location: selectedLocation,
    });
    window.scrollTo(0, 0);
  };

  const handleNavigateToCalendar = () => {
    customPush({
      pathname: `/calendar`,
      search: serializeParams({
        lang: searchParams?.lang,
        debug: searchParams?.debug,
      }),
    });
  };

  const handleNavigateToHomePage = () => {
    customPush({
      pathname: `/`,
      search: serializeParams(searchParams),
    });
  };

  const onSearchAllExperiences = () => {
    customPush({
      pathname: `/experiences/`,
      search: serializeParams({ lang: searchParams?.lang }),
    });
  };

  return (
    <div>
      {asHomePage && <HomePageAnalyticsTracker experiences={experiences} />}
      <Helmet>
        {brandName ? (
          <title>
            {showTitle
              ? `Experiences | ${brandName}`
              : `Browse Experiences | ${brandName}`}
          </title>
        ) : null}
      </Helmet>
      {showTitle && (
        <Title id="experiences-title">{t('searchAllExperiences')}</Title>
      )}
      {!showTitle && (
        <SearchAllWrapperStart>
          <LinksBack
            id="experiences__back-button-link"
            tabIndex={0}
            role="link"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleNavigateToHomePage();
              }
            }}
            onClick={handleNavigateToHomePage}
          >
            <IconBackLight>
              <PrevArrowIcon />
            </IconBackLight>
            &nbsp;
            {t('back')}
          </LinksBack>
          <CartIcon searchParam={experienceFilterParam} />
        </SearchAllWrapperStart>
      )}
      <ExperienceSearchWrapper className="experiences__search-wrapper">
        <SearchWrap
          id="experience-date-filter"
          className="experiences__search-field-wrapper"
        >
          <SearchLabel
            id="date-label-id"
            htmlFor="dates-input-id"
            className="experiences__search-field-label"
          >
            {t('date')}
          </SearchLabel>
          <InputDates
            id="dates-input-id"
            name="dates"
            aria-labelledby="date-label-id"
            readOnly
            placeholder={t(isNarrowView ? 'filterExpDate' : 'chooseExpDate')}
            value={getDateRangeInputvalueForView()}
            onClick={() => {
              setShowDatePicker(!showDatePicker);
              onClear();
            }}
            onChange={() => {}}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                // Handle Enter or Space
                setShowDatePicker(!showDatePicker);
                onClear();
              }
            }}
          />
          <ClearButton
            className="experiences__date-filter-clear-button"
            type="button"
            onClick={onClear}
          >
            X
          </ClearButton>
          {showDatePicker && (
            <InputDatesWrapper>
              <DateRangePicker
                startDate={startDateCalendar}
                endDate={endDateCalendar}
                onChange={handleOnDateChange}
                onOutsideClick={() => setShowDatePicker(false)}
                onClose={() => setShowDatePicker(false)}
                orientation={isMobile ? 'vertical' : 'horizontal'}
                navPrev={
                  <PrevIconCalendar hidden>
                    <PrevArrowIcon scale={1.4} />
                  </PrevIconCalendar>
                }
                navNext={
                  <NextIconCalendar>
                    <NextArrowIcon scale={1.4} />
                  </NextIconCalendar>
                }
              />
            </InputDatesWrapper>
          )}
        </SearchWrap>
        <SearchWrap
          id="experience-type-filter"
          className="experiences__search-field-wrapper"
        >
          <SearchLabel className="experiences__search-field-label">
            {t('experienceType')}
          </SearchLabel>
          <Select
            placeholder={t('filterExperienceType')}
            name="category"
            aria-label={t('experienceType')}
            options={loadCategories()}
            onChange={onCategoriesChangeHandler}
            value={getSelectedCategoriesObject(selectedCategories)}
            styles={selectStyles}
            theme={selectTheme}
            isClearable
            isMulti
            closeMenuOnSelect={false}
          />
        </SearchWrap>
        <SearchWrap
          id="experience-vibe-filter"
          className="experiences__search-field-wrapper"
        >
          <SearchLabel className="experiences__search-field-label">
            {t('experienceVibe')}
          </SearchLabel>
          <Select
            placeholder={t('filterExperienceVibe')}
            name="vibe"
            aria-label={t('filterExperienceVibe')}
            options={loadVibes()}
            onChange={onVibesChangeHandler}
            value={getSelectedVibesObject(selectedVibes)}
            styles={selectStyles}
            theme={selectTheme}
            isClearable
            isMulti
            closeMenuOnSelect={false}
          />
        </SearchWrap>
        <SearchButton
          id="experiences__search-button"
          type="button"
          onClick={onSearchClick}
        >
          {t('search')}
        </SearchButton>
        <ToggleFeature featureId="calendarView">
          <SearchButton
            id="experiences__calendar-view-button"
            type="button"
            onClick={handleNavigateToCalendar}
          >
            {t('viewCalendar')}
          </SearchButton>
        </ToggleFeature>
      </ExperienceSearchWrapper>

      <ExperienceWrapper className={classId || 'home-page-experiences'}>
        {renderItems()}
      </ExperienceWrapper>
      <SearchAllWrapper>
        {!hidePagination ? (
          <>
            <ButtonLinks onClick={onPrev} disabled={currentPage === 1}>
              <IconBack>
                <PrevArrowIcon width="20" />
              </IconBack>
              {t('previous')}
            </ButtonLinks>
            <ButtonLinks
              onClick={onNext}
              disabled={pagination?.totalPages === currentPage}
            >
              {t('next')}
              <IconBack>
                <NextArrowIcon width="20" />
              </IconBack>
            </ButtonLinks>
          </>
        ) : (
          <LinkSearch
            className="experience-view__search-all-experiences-button"
            onClick={onSearchAllExperiences}
          >
            {t('searchAllExperiences')}
          </LinkSearch>
        )}
      </SearchAllWrapper>
    </div>
  );
};

Experiences.propTypes = {
  hidePagination: PropTypes.bool,
  limit: PropTypes.number,
  showTitle: PropTypes.bool,
  redirectToSearch: PropTypes.bool,
  classId: PropTypes.string,
  asHomePage: PropTypes.bool,
};

Experiences.defaultProps = {
  hidePagination: false,
  showTitle: false,
  limit: 48,
  redirectToSearch: false,
  classId: '',
  asHomePage: false,
};

export default Experiences;

const LinksBack = styled.a`
  cursor: pointer;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
`;

const ClearButton = styled.button`
  position: absolute;
  right: 10px;
  border: none;
  background: none;
  color: var(--way-palette-black-100);
  padding: 0;
  &:hover {
    border: none;
    background: none;
    color: var(--way-palette-black-100);
    cursor: pointer;
  }
`;

const IconBack = styled.div`
  display: inline-flex;
  margin: 0 4px;
`;

const Title = styled(MainTitle)`
  font-weight: normal;
  text-align: center;
  margin-bottom: 48px;
  display: block;
`;

const ExperienceWrapper = styled(Wrapper)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
  margin-bottom: 40px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 30px;
    margin-bottom: 30px;
  }

  @media (min-width: 992px) {
    grid-gap: 56px;
    margin-bottom: 56px;
  }
`;

const ExperienceSearchWrapper = styled(Wrapper)`
  display: grid;
  position: relative;
  z-index: 5;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    grid-template-columns:
      minmax(20px, 256px) minmax(20px, 256px) minmax(20px, 256px)
      minmax(20px, 256px) minmax(100px, auto);
    grid-gap: 16px;
    margin-bottom: 40px;
    justify-content: center;
  }
  @media (min-width: 992px) {
    grid-template-columns:
      minmax(20px, 256px) minmax(20px, 256px) minmax(20px, 256px)
      minmax(185px, auto) minmax(185px, auto);
  }
`;

const SearchAllWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 56px;
  a {
    color: var(--way-colors-buttonColorContrastShades-80);
    &:hover {
      color: var(--way-colors-buttonColorContrastShades-100);
    }
  }
`;

const LinkSearch = styled.a`
  cursor: pointer;
  font-size: 15px;
  line-height: 20px;
  padding: 15px 40px;
  transition: background 0.3s;
  border-radius: var(--way-design-borderRadiusDefault);
  background: var(--way-colors-buttonColorShades-80);
  box-shadow: var(--way-design-boxShadow-s);
  &:hover {
    background: var(--way-colors-buttonColorShades-100);
  }
`;

const SearchWrap = styled.div`
  display: block;
  border: 0.5px solid var(--way-colors-borderColor);
  border-radius: var(--way-design-borderRadiusDefault);
  padding-top: 3px;
  margin-bottom: 16px;
  position: relative;
  &:first-child {
    z-index: 9;
  }
  @media (min-width: 768px) {
    margin-bottom: 0px;
  }
`;
const SearchLabel = styled.label`
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  text-transform: none;
  padding-left: 12px;
  padding-right: 12px;
  display: block;
`;

const InputDates = styled.input`
  border: none;
  padding: 2px 12px;
  font-size: 14px;
  line-height: 25px;
  font-family: ${({ theme }) => theme.font.primaryFont};
  width: 100%;
  background-color: transparent;
  color: gray;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: gray;
  }
  :-ms-input-placeholder {
    color: gray;
  }

  :focus {
    outline: 0 none;
  }
`;

const InputDatesWrapper = styled.div`
  position: absolute;
  top: 115%;
  z-index: 99;

  strong {
    font-weight: normal;
  }
  td {
    color: var(--way-palette-black-100);
  }
`;

const SearchButton = styled(ButtonBlock)`
  margin-bottom: 16px;
  @media (min-width: 768px) {
    margin-bottom: 0px;
  }
  cursor: pointer;
`;

const PrevIconCalendar = styled.div`
  display: inline-flex;
  background-color: rgba(45, 54, 87, 0.25);
  color: var(--way-palette-black-30);
  font-size: 24px;
  position: absolute;
  margin-top: -2px;
  top: 19px;
  left: 13px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  padding-right: 2px;
  & svg {
    path {
      stroke: none;
    }
  }
`;

const NextIconCalendar = styled.div`
  display: inline-flex;
  background-color: rgba(45, 54, 87, 0.25);
  color: var(--way-palette-black-30);
  font-size: 24px;
  position: absolute;
  margin-top: -2px;
  top: 19px;
  right: 13px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  padding-left: 2px;
  & svg {
    path {
      stroke: none;
    }
  }
`;

const ButtonLinks = styled(ButtonLink)`
  display: flex;
  align-items: center;
  color: var(--way-colors-primaryTextColor);
  margin: 0 40px;
  &:hover {
    color: var(--way-colors-primaryTextColor);
  }
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    &:hover {
      opacity: 0.4;
    }
  }
`;
