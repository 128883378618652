import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'react-dates/initialize';
import { DayPickerRangeController } from 'react-dates';
import 'components/theme/ReactDate';
import CalendarDay from 'components/datepickers/CalendarDay/CalendarDay';

const START_DATE = 'startDate';

const DateRangePicker = ({
  startDate,
  endDate,
  onChange,
  onOutsideClick,
  orientation,
  ...props
}) => {
  const [focus, setFocus] = useState(START_DATE);

  return (
    <div>
      <DayPickerRangeController
        startDate={startDate}
        endDate={endDate}
        onDatesChange={onChange}
        enableOutsideDays={false}
        keepOpenOnDateSelect={false}
        hideKeyboardShortcutsPanel
        focusedInput={focus}
        onFocusChange={(focusedInput) =>
          setFocus(!focusedInput ? START_DATE : focusedInput)
        }
        numberOfMonths={2}
        onOutsideClick={onOutsideClick}
        orientation={orientation}
        renderCalendarDay={CalendarDay}
        {...props}
      />
    </div>
  );
};

DateRangePicker.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onOutsideClick: PropTypes.func.isRequired,
  orientation: PropTypes.string,
};

DateRangePicker.defaultProps = {
  startDate: null,
  endDate: null,
  orientation: 'horizontal',
};

export default DateRangePicker;
