import React from 'react';
import styled from 'styled-components';

type GooglePayIconProps = Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
  color?: string;
  scale?: number;
};

const StyledSvg = styled.svg.withConfig({
  shouldForwardProp: (prop) => prop !== 'scale',
})<{ scale?: number }>`
  color: var(--way-colors-svgColor);
  height: ${({ scale = 1 }) => 18 * scale}px;
  width: ${({ scale = 1 }) => 43 * scale}px;
`;

const GooglePayIcon = ({ color, scale = 1, ...props }: GooglePayIconProps) => (
  <StyledSvg
    fill="none"
    scale={scale}
    style={{ color }}
    viewBox="0 0 48 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.1376 15.4611V9.66067H25.1312C26.3579 9.66067 27.3934 9.24968 28.2375 8.43895L28.44 8.23351C29.9819 6.55502 29.8806 3.94032 28.2375 2.38749C27.4159 1.56533 26.2905 1.12008 25.1312 1.14293H20.3257V15.4611H22.1376ZM22.1378 7.9023V2.90129H25.1769C25.8298 2.90129 26.449 3.15243 26.9104 3.60921C27.8897 4.56826 27.9122 6.16679 26.9667 7.16011C26.5052 7.65116 25.8523 7.92515 25.1769 7.9023H22.1378ZM36.8921 6.42945C36.1155 5.71011 35.0577 5.34472 33.7184 5.34472C31.9965 5.34472 30.7022 5.98409 29.8468 7.25151L31.445 8.26768C32.0302 7.39993 32.8293 6.96609 33.8422 6.96609C34.4837 6.96609 35.1026 7.20591 35.5866 7.63974C36.0593 8.05082 36.3294 8.64449 36.3294 9.27253V9.69494C35.6316 9.3068 34.7538 9.10126 33.6734 9.10126C32.4129 9.10126 31.3999 9.3981 30.646 10.0033C29.8919 10.6084 29.5093 11.4077 29.5093 12.4239C29.4867 13.3488 29.8806 14.2279 30.5785 14.8216C31.2874 15.4611 32.1878 15.7808 33.2457 15.7808C34.4949 15.7808 35.4853 15.2213 36.2394 14.1023H36.3181V15.4611H38.0513V9.42095C38.0513 8.15354 37.6687 7.14879 36.8921 6.42945ZM31.9742 13.657C31.6028 13.3829 31.3777 12.9377 31.3777 12.4582C31.3777 11.9215 31.6254 11.4762 32.1093 11.1222C32.6046 10.7682 33.2237 10.5855 33.9553 10.5855C34.9684 10.5741 35.7563 10.8025 36.3192 11.2592C36.3192 12.0357 36.0152 12.7093 35.4187 13.2802C34.8784 13.8283 34.1467 14.1365 33.3813 14.1365C32.8747 14.148 32.3794 13.9767 31.9742 13.657ZM41.9453 19.7656L48.0002 5.66441H46.0307L43.2284 12.6979H43.1946L40.3247 5.66441H38.3553L42.328 14.833L40.0771 19.7656H41.9453Z"
      style={{
        fill: 'currentColor',
        stroke: 'none',
      }}
    />
    <path
      d="M15.8822 8.40388C15.8822 7.84439 15.8371 7.28489 15.7472 6.73682H8.10547V9.89959H12.4834C12.3033 10.9159 11.7181 11.8293 10.8627 12.4001V14.4554H13.4738C15.0043 13.0282 15.8822 10.9159 15.8822 8.40388Z"
      fill="#4285F4"
    />
    <path
      d="M8.10427 16.4424C10.2876 16.4424 12.1334 15.7117 13.4726 14.4557L10.8616 12.4005C10.1301 12.9029 9.196 13.1883 8.10427 13.1883C5.9885 13.1883 4.19903 11.7382 3.55754 9.79712H0.867676V11.9209C2.24079 14.6955 5.04307 16.4424 8.10427 16.4424Z"
      fill="#34A853"
    />
    <path
      d="M3.55973 9.79663C3.22209 8.78045 3.22209 7.67298 3.55973 6.64528V4.53296H0.869573C-0.289858 6.85082 -0.289858 9.59119 0.869573 11.909L3.55973 9.79663Z"
      fill="#FBBC04"
    />
    <path
      d="M8.10427 3.25457C9.26349 3.23172 10.3777 3.67707 11.2105 4.48771L13.5289 2.13557C12.0545 0.742589 10.1189 -0.0223509 8.10427 0.000497495C5.04307 0.000497495 2.24079 1.75886 0.867676 4.5334L3.55754 6.65715C4.19903 4.70467 5.98849 3.25457 8.10427 3.25457Z"
      fill="#EA4335"
    />
  </StyledSvg>
);

GooglePayIcon.displayName = 'GooglePayIcon';

export default GooglePayIcon;
