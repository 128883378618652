import { useBrandId } from 'AppProvider';
import { useMemo } from 'react';
import useCartItems from './useCartItems';
import useExchangeId from './useExchangeId';
import useIsHyattLMSEnabledFlag from './useIsHyattLMSEnabledFlag';
import useValidateHyattEligibility from './useValidateHyattEligibility';

const useHyattCartValidation = () => {
  const isHyattLMSEnabled = useIsHyattLMSEnabledFlag();
  const { cartItems } = useCartItems();
  const brandId = useBrandId();

  const listingIds = useMemo(
    () =>
      Object.values(cartItems)
        .map((item) => item.listingId)
        .filter((id): id is string => Boolean(id)),
    [cartItems],
  );

  const exchangedIds = useExchangeId(listingIds, brandId);
  const finalExchangeIds = useMemo(() => {
    if (!exchangedIds) return null;
    return Array.isArray(exchangedIds) ? exchangedIds : [exchangedIds];
  }, [exchangedIds]);
  const { isEligible, isLoading } = useValidateHyattEligibility(
    finalExchangeIds,
    brandId,
    isHyattLMSEnabled,
  );

  return { isEligible, isLoading };
};

export default useHyattCartValidation;
