import moment from 'moment';
import { Listing } from 'types/listings';

const getUpdatedSelectedDate = (
  selectedDate: string | null | undefined,
  event: Listing | null | undefined,
  waitlist: { mode: string } | null | undefined,
): string | null | undefined => {
  if (!selectedDate || !event?.firstAvailableDate?.scheduledDate) {
    return selectedDate;
  }

  const setFirstAvailableDate = waitlist ? waitlist.mode === 'NONE' : true;

  const isValidFirstAvailableDate =
    setFirstAvailableDate &&
    moment(event.firstAvailableDate.scheduledDate).isAfter(selectedDate);

  return isValidFirstAvailableDate
    ? event.firstAvailableDate.scheduledDate
    : selectedDate;
};

export default getUpdatedSelectedDate;
