export default {
  featuredExperiences: 'Nuestras Experiencias Destacadas',
  searchAllExperiences: 'Buscar todas las Experiencias',
  searchAll: 'Buscar Todo ',
  groupSize: 'Tamaño del Grupo',
  eventSize: 'Tamaño del Evento',
  upTo: 'Hasta',
  whatWeDo: 'Qué Haremos',
  hostedBy: 'El Anfitrión es',
  whatsIncluded: 'Qué Incluye',
  avail: 'Disponibilidad',
  whereWeAre: 'Dónde Estaremos',
  experienceStartingPrice: 'Desde {{price}}',
  experienceTotalPrice: 'experienceTotalPrice',
  at: 'a las',
  viewPrivateSessAvailability: 'Ver la disponibilidad de las sesiones privadas',
  selectSession: 'Seleccionar Sesión',
  noAvailability: 'No disponible',
  select: 'Seleccionar',
  slotsLeft: 'Quedan {{remainSlots}} espacios disponibles',
  slotLeft: '{{remainSlots}} espacio restante',
  bookExperience: 'Reservar una Experiencia',
  book: 'Reservar',
  apply: 'Aplicar',
  maxPartySizes: 'Máximo de {{maxGroupSize}} espacio por grupo',
  maxPartySize: 'Máximo de {{maxGroupSize}} tiempo por grupo',
  selectSessionValidation: 'Por favor, selecciona sesión',
  selectParticipantValidation: 'Por favor, selecciona los participantes',
  selectDateValidation: 'Por favor, selecciona una fecha',
  reminders: 'Avisos',
  seeOtherDates: 'Ver otras fechas',
  location: 'Ubicación',
  about: 'Detalles',
  soldOut: 'Agotado',
  reviews: 'Reseñas',
  reviewSingular: 'reseña',
  reviewPlural: 'reseñas',
  dateOfExperience: 'Fecha de la experiencia',
  readLess: 'Leer Menos',
  readMore: 'Leer Más',
  seeMore: 'Ver Más',
  details: 'Detalles',
  theFollowingVideoDoesNotContainAnyAudio: 'Este video no contiene audio',
  by: 'Por',
};
