import React from 'react';
import styled from 'styled-components';

type CardIconProps = Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
  color?: string;
  scale?: number;
};

const StyledSvg = styled.svg.withConfig({
  shouldForwardProp: (prop) => prop !== 'scale',
})<{ scale?: number }>`
  color: var(--way-colors-svgColor);
  height: ${({ scale = 1 }) => 16 * scale}px;
  width: ${({ scale = 1 }) => 16 * scale}px;
`;

const CardIcon = ({ color, scale = 1, ...props }: CardIconProps) => (
  <StyledSvg
    fill="none"
    scale={scale}
    style={{ color }}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 4H0V3.25C0 2.56 0.448 2 1 2H15C15.552 2 16 2.56 16 3.25V4ZM16 6.5V13C16 13.2652 15.8946 13.5196 15.7071 13.7071C15.5196 13.8946 15.2652 14 15 14H1C0.734784 14 0.48043 13.8946 0.292893 13.7071C0.105357 13.5196 0 13.2652 0 13V6.5H16ZM4 10C3.73478 10 3.48043 10.1054 3.29289 10.2929C3.10536 10.4804 3 10.7348 3 11C3 11.2652 3.10536 11.5196 3.29289 11.7071C3.48043 11.8946 3.73478 12 4 12H5C5.26522 12 5.51957 11.8946 5.70711 11.7071C5.89464 11.5196 6 11.2652 6 11C6 10.7348 5.89464 10.4804 5.70711 10.2929C5.51957 10.1054 5.26522 10 5 10H4Z"
      style={{
        fill: 'currentColor',
        stroke: 'none',
      }}
    />
  </StyledSvg>
);

CardIcon.displayName = 'CardIcon';

export default CardIcon;
