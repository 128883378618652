import { formatUTCDate } from 'utils';

export const sanitizeDateParam = (value?: string) => {
  const date = formatUTCDate(value, '00:00:00');
  if (!value || date.toLowerCase() === 'invalid date') {
    return undefined;
  }
  return date.substring(0, 10);
};

export const sanitizeDurationParam = (value?: string) => {
  if (!value) return undefined;
  const match = value.match(/PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/);
  if (!match) return undefined;
  const hours = match[1] ? parseInt(match[1], 10) : 0;
  const minutes = match[2] ? parseInt(match[2], 10) : 0;
  const seconds = match[3] ? parseInt(match[3], 10) : 0;
  const totalMinutes = hours * 60 + minutes + seconds / 60;
  return totalMinutes === 0 ? undefined : totalMinutes;
};

export const sanitizeLatestParam = (value?: string) => {
  if (value === 'true') {
    return true;
  }
  return false;
};

export const sanitizeIdParam = (value?: string) => {
  return (
    decodeURIComponent(value ?? '')
      .replace(/"/g, '')
      .split('&')[0] || undefined
  );
};

export const sanitizeTimeParam = (value?: string) => {
  const time = formatUTCDate('2022-12-31', value);
  if (!value || time.toLowerCase() === 'invalid date') {
    return undefined;
  }
  return time.substring(11, 19);
};
