import React from 'react';
import { useTranslation } from 'react-i18next';
import { Name, HostPhoto, InfoBox } from './styles';

export type ProfilePicture = {
  id: string;
  uri: {
    '48w': string;
    '256w': string;
    original: string;
  };
};

export interface HostInfoBoxProps {
  firstName: string | undefined;
  profilePicture: ProfilePicture | null;
}

export const HostInfoBox = ({
  firstName,
  profilePicture,
}: HostInfoBoxProps) => {
  const { t } = useTranslation();

  if (firstName === 'No Host' || !firstName) return null;

  return (
    <InfoBox className="listing-carousel-card-details">
      {profilePicture && (
        <HostPhoto
          src={profilePicture.uri['256w']}
          alt={t('profileImageOfHost', { hostName: firstName })}
          className="listing-card-host-photo"
        />
      )}
      <Name>
        {t('hostedBy')} {firstName}
      </Name>
    </InfoBox>
  );
};
