import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { DATE_FORMAT } from 'utils';
import useEventBookingSession from 'features/Events/hooks/useEventBookingSession';
import { SkeletonLine } from 'components/theme/Skeleton/Skeleton';
import { filterNonBlockedSessions } from 'utils/session';
import { getUniquePricesForFirstAvailableDate } from 'utils/listings';
import useBrandById from 'api/brand/useBrandById';
import { useBrandId } from 'AppProvider';
import { useTranslation } from 'react-i18next';
import useWaitList from 'api/waitlist/useWaitList';
import { WaitlistMode } from '@kouto/types';
import BuyTicketButton, {
  getDateStateFromSessionsAvailability,
} from './BuyTicketButton';

const EventDetailsHeader: FC = () => {
  const { t } = useTranslation();
  const { isLoading, event, preselectedDate, eventSessions } =
    useEventBookingSession();
  const { data: waitlist } = useWaitList(event?.waitlistId, event?.brandId);
  const waitlistEnabled = !!waitlist && waitlist?.mode !== WaitlistMode.NONE;

  const brandId = useBrandId();

  const { data } = useBrandById(event?.brandId ?? null);
  const promoterBrand = event?.brandId !== brandId ? data?.name : null;
  const firstAvailableDate = useMemo(() => {
    const today = moment().format(DATE_FORMAT);
    if (preselectedDate && preselectedDate >= today) {
      return preselectedDate;
    }

    return (
      event?.firstAvailableDate?.scheduledDate ||
      (event?.dates
        ? event.dates
            .filter((date) => date === today || date > today)
            .sort((d1, d2) => (d1 > d2 ? 1 : -1))[0]
        : today)
    );
  }, [event, preselectedDate]);

  const firstDateLabel = useMemo(
    () => moment(firstAvailableDate).format('MMMM Do'),
    [firstAvailableDate],
  );

  const buttonState = useMemo(
    () =>
      getDateStateFromSessionsAvailability(
        filterNonBlockedSessions(eventSessions),
        firstAvailableDate,
        waitlistEnabled,
      ),
    [eventSessions, firstAvailableDate, waitlistEnabled],
  );

  if (isLoading || !event) {
    return (
      <Wrapper>
        <FirstDate>
          <SkeletonLine style={{ marginBottom: 0 }} />
        </FirstDate>
        <EventTitle>
          <SkeletonLine style={{ marginBottom: 0 }} />
        </EventTitle>
        <BuyTicketButtonSkeleton />
      </Wrapper>
    );
  }

  const prices = getUniquePricesForFirstAvailableDate(event);
  const isFree = prices.length === 1 && prices[0] === 0;

  return (
    <Wrapper>
      <FirstDate className="event_details_first_date">
        {firstDateLabel}
      </FirstDate>
      <EventTitle
        className="event_details_title"
        promoterBrand={!!promoterBrand}
      >
        {event.title}
      </EventTitle>
      {promoterBrand && (
        <BrandName>
          {t('by')} {promoterBrand}
        </BrandName>
      )}
      <BuyTicketButton
        className="event_details_header_buy_ticket_button"
        date={firstAvailableDate}
        state={buttonState}
        isFree={isFree}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
`;

const FirstDate = styled.p`
  margin: 0px;
  font-weight: 700;
`;

const EventTitle = styled.h1<{ promoterBrand?: boolean }>`
  text-align: center;
  min-width: 60%;
  font-size: 56px;
  margin-bottom: ${({ promoterBrand }) => (promoterBrand ? '11px' : '')};
  @media screen and (max-width: 768px) {
    min-width: 90%;
    font-size: 40px;
  }
`;

export const BrandName = styled.p`
  color: var(--Text-Tertiary, rgba(0, 0, 0, 0.6));
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.016px;
  margin-top: 0px;
  margin-bottom: 24px;
`;

const BuyTicketButtonSkeleton = styled(SkeletonLine)`
  height: 40px;
  width: 120px;
  margin-bottom: 0px;
`;

export default EventDetailsHeader;
