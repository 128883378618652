import { BASE_URL } from 'consts';
import { HYATT_AUTH_REDIRECT_URL } from 'consts/HyattLMSConsts';

export const getHyattMemberProfileUrl = () => {
  return `${BASE_URL}/v2/hyatt/member/profile`;
};

export const getHyattAuthenticateUrl = () => {
  return `${BASE_URL}/v2/hyatt/authenticate`;
};

export const getHyattSignInUrl = () => {
  const clientId = process.env.HYATT_CLIENT_ID;
  const hyattSignInUrl = process.env.HYATT_SIGN_IN_URL;
  const redirectURL = `${hyattSignInUrl}?client_id=${clientId}&response_type=code&redirect_uri=${HYATT_AUTH_REDIRECT_URL}`;
  return redirectURL;
};

export const getHyattValidateListingsUrl = () => {
  return `${BASE_URL}/v1/credit-codes/validate-listings`;
};
