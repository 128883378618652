import useSWR from 'swr';
import { useBrandId } from 'AppProvider';
import fetchHosts from './fetchHosts';
import buildHostsUrl from './buildHostsUrl';

const fetchHostsSWR = ([url, { brandId }]: [string, { brandId: string }]) =>
  fetchHosts(url, { brandId });

const useHosts = (hostIds: string[]) => {
  const brandId = useBrandId();
  const url =
    hostIds.length === 1 && hostIds[0] === null ? null : buildHostsUrl(hostIds);
  const swr = useSWR(url ? [url, { brandId }] : null, fetchHostsSWR);
  return {
    ...swr,
  };
};

export default useHosts;
