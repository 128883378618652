type DateLabels = {
  year: number;
  month: string;
  day: string | number;
} | null;

const getSameMonthDateLabels = (
  dates: Date[] | string[] | null,
): DateLabels => {
  if (!dates || dates.length === 0) return null;

  const today = new Date();

  const sortedDates = dates
    .map((dateStr) => new Date(dateStr))
    .filter((date) => date >= today)
    .sort((a, b) => a.getTime() - b.getTime());

  if (sortedDates.length === 0) return null;

  const [firstDate, lastDate] = [
    sortedDates[0],
    sortedDates.at(-1) ?? sortedDates[0],
  ];

  return {
    year: firstDate.getUTCFullYear(),
    month: new Intl.DateTimeFormat('en-US', {
      month: 'short',
      timeZone: 'UTC',
    }).format(firstDate),
    day:
      firstDate.getUTCDate() === lastDate.getUTCDate()
        ? `${firstDate.getUTCDate()}`
        : `${firstDate.getUTCDate()}-${lastDate.getUTCDate()}`,
  };
};

export default getSameMonthDateLabels;
