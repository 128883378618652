import TRANSLATION_ACCESSIBILITY_RU from './accessibility/translation';
import TRANSLATION_BOOKING_RU from './booking/translation';
import TRANSLATION_EVENTS_RU from './events/translation';
import TRANSLATION_EXPERIENCES_RU from './experiences/translation';
import TRANSLATION_FORM_RU from './form/translation';
import TRANSLATION_LANDING_PAGES_RU from './landingPages/translation';
import TRANSLATION_RESERVE_RU from './reserve/translation';
import TRANSLATION_SIGNIN_RU from './singin/translation';
import TRANSLATION_DATES_RU from './weekdays/translation';

export default {
  ...TRANSLATION_DATES_RU,
  ...TRANSLATION_BOOKING_RU,
  ...TRANSLATION_FORM_RU,
  ...TRANSLATION_EXPERIENCES_RU,
  ...TRANSLATION_LANDING_PAGES_RU,
  ...TRANSLATION_EVENTS_RU,
  ...TRANSLATION_RESERVE_RU,
  ...TRANSLATION_ACCESSIBILITY_RU,
  ...TRANSLATION_SIGNIN_RU,
};
