import { JoinWaitlistEndpoint, StripePaymentMethods } from '@kouto/types';
import { PaymentIntentResult, StripeError } from '@stripe/stripe-js';
import { ICartExperience } from 'types/cart';
import {
  CustomQuestionResponse,
  IExperienceSettingsResponse,
} from 'types/custom-questions';
import {
  IExperienceFields,
  IFullExperienceFields,
} from 'types/experience.types';
import { Listing } from 'types/listings';
import { z } from 'zod';

export interface ICartExperienceItem {
  [uid: string]: ICartExperience;
}

export type SubmitCardPaymentCallback = () => Promise<{
  error?: StripeError;
}> | null;
export type ConfirmCardPaymentCallback = (
  paymentIntentSecret: string,
) => Promise<PaymentIntentResult> | null;

export interface IParams {
  addedItem: {
    experience: IExperienceFields;
    sessionDateTime: string;
    duration: string;
  };
  cartExperienceItems: ICartExperienceItem;
  experienceList: IExperienceFields[];
  isHyattLMSEnabled?: boolean;
  isCartHyattEligible?: boolean;
}

export interface IOverlapCheckParams {
  selectedList: ICartExperience[];
  addedItem: {
    experience: IExperienceFields;
    sessionDateTime: string;
    duration: string;
  };
}

export interface IFieldTitleProps {
  isMultipleTier: boolean;
}

export interface IFormItemProps {
  isLastItem: boolean;
}

export interface IActionBtnProps {
  isPrimary: boolean;
}

export interface IFooterProps {
  isError: boolean;
}

export enum CreditCardGuestType {
  NON_HOTEL_GUEST = 'nonHotelGuest',
  ON_PROPERTY_GUEST = 'onPropertyGuest',
  PRE_ARRIVAL_GUEST = 'preArrivalGuest',
}

export enum FieldName {
  PAYMENT_NAME = 'paymentName',
  RESERVATION_NUMBER = 'reservationNumber',
  METHOD_NUMBER = 'methodNumber',
  LAST_NAME = 'lastName',
  RESERVATION_CODE = 'reservationCode',
}

export enum RoomChargeGuestType {
  ON_PROPERTY_GUEST = 'onPropertyGuest',
  PRE_ARRIVAL_GUEST = 'preArrivalGuest',
}

export interface ITier {
  selectedNumber: number;
  name: string;
  price: number;
}

export interface IParticipantResult {
  count: number;
  priceTier: string;
  price: number;
}

export interface ICartCurrentParticipant {
  fullName: string;
  terms: boolean;
  priceTierName: string;
  price: number;
}

export interface ISelectOptions {
  label: string;
  value: string;
}

export interface IIntergrationEnabledParams {
  activeOption: string;
  cartItems: ICartExperienceItem;
  listings: {
    [uid: string]: Listing;
  };
}

export interface ICheckoutConfig {
  purchaser: {
    firstName: string;
    lastName: string;
    emailAddress: string;
    primaryContactEmailAddress?: string;
    phoneNumber?: string;
    customQuestionResponses?: CustomQuestionResponse[];
  };
  activeOption: string;
  stripeSelectedMethod: StripePaymentMethods;
  guestType?: string;
  card?: string;
  paymentName: string;
  reservationNumber?: string;
  reservationCode?: string;
}

export interface IParticipantInfo {
  fullName: string | ISelectOptions;
  terms: boolean;
  priceTierName: string;
  price: number;
  emailAddress?: string;
  phoneNumber?: string;
  customQuestionResponses?: {
    [questionId: string]: CustomQuestionResponse[];
  };
}

export interface IParticipantConfig {
  participants: Array<IParticipantInfo>;
  notes: string;
  groupTitle?: string;
  groupId?: string;
  listingId?: string;
  maxParticipantReached: string;
  additionalCustomQuestionResponses: {
    [questionId: string]: CustomQuestionResponse[];
  };
}

export type IBookingError = Array<{
  message: string;
  code?: string;
}> | null;

export interface ICartDiscountParams {
  cartExperienceItems: IParams['cartExperienceItems'];
  discountCode?: Record<string, number | string[]>;
}

export type IExperiencePayload = Array<
  IFullExperienceFields & IExperienceSettingsResponse['data']
>;

export type IWaitlistExperience = IFullExperienceFields &
  IExperienceSettingsResponse['data'];

export type JoinWaitlistRequest = z.infer<
  typeof JoinWaitlistEndpoint.requestSchema
>;
