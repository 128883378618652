import { HYATT_AUTH_REDIRECT_URL } from 'consts/HyattLMSConsts';
import { post } from '../api';
import { getHyattAuthenticateUrl } from './hyattUrls';

export interface HyattAuthResponse {
  data: {
    accessToken: string;
    expiresIn: number;
    tokenType: string;
    clientId: string;
  };
}

const authenticateHyattUser = async (authCode: string) => {
  const url = getHyattAuthenticateUrl();
  const body = {
    code: authCode,
    redirectUrl: HYATT_AUTH_REDIRECT_URL,
  };
  const data = await post<HyattAuthResponse>(url, {
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return data;
};

export default authenticateHyattUser;
