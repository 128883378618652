import React, { FC } from 'react';
import { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/common/styled/common-styled';
import { ResourceHeading2 } from 'components/theme/Typography/ResourceTypography';
import { TruncatedText, TruncationMode } from 'components/TruncatedText';
import Chip from 'components/Chip';
import ChipList from 'components/ChipList';

interface Props {
  headline: string;
  maxLines?: number;
  vibes?: string[];
}

const ResourceGroupHeadline: FC<Props> = ({
  headline,
  maxLines = 4,
  vibes,
}) => {
  const { t: translate } = useTranslation();
  return (
    <Flex
      direction="column"
      gap={16}
      alignItem="flex-start"
      justifyContent="flex-start"
    >
      <ResourceHeading2
        className="resource-group-headline__title"
        fontWeight="350"
      >
        {translate('about')}
      </ResourceHeading2>
      {vibes && vibes?.length > 0 && (
        <ChipList>
          {vibes.map((vibe) => (
            <Chip as="li" key={vibe}>
              {vibe}
            </Chip>
          ))}
        </ChipList>
      )}
      <TruncatedText
        customStyles={headlineTextStyles}
        isRichText
        text={headline}
        maxLines={maxLines}
        mode={TruncationMode.INLINE}
      />
    </Flex>
  );
};

const headlineTextStyles = css`
  & > p {
    color: var(--way-colors-contrastColorShades-80);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
  }
`;

export default ResourceGroupHeadline;
