import React from 'react';
import styled from 'styled-components';

type ChevronDownIconProps = Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
  color?: string;
  scale?: number;
};

const StyledSvg = styled.svg.withConfig({
  shouldForwardProp: (prop) => prop !== 'scale',
})<{ scale?: number }>`
  color: var(--way-colors-svgColor);
  height: ${({ scale = 1 }) => 20 * scale}px;
  width: ${({ scale = 1 }) => 20 * scale}px;
`;

const ChevronDownIcon = ({
  color,
  scale = 1,
  ...props
}: ChevronDownIconProps) => (
  <StyledSvg
    fill="none"
    scale={scale}
    style={{ color }}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.95182 12.2449L15.6943 6.50234L16.4014 7.20945L10.3054 13.3055L9.59827 13.3055L3.5022 7.20945L4.2093 6.50234L9.95182 12.2449Z"
      style={{
        fill: 'currentColor',
        stroke: 'none',
      }}
    />
  </StyledSvg>
);

ChevronDownIcon.displayName = 'ChevronDownIcon';

export default ChevronDownIcon;
