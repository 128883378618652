import { useLocation, useHistory } from 'react-router-dom';
import { queryParamsToObject, serializeParams } from 'utils';

function fixQueryString(url) {
  return `?${url
    .split('?')
    .filter((part) => !!part)
    .join('&')}`;
}

/**
 * @deprecated Use `useQueryParamsObject` in utils/url instead.
 */
const useSearchQueryParams = () => {
  const location = useLocation();
  const history = useHistory();
  // URLSearchParams overwrites params when they are duplicated
  // therefore, if we want params in location.search (from react-router-dom)
  // to take precedence over the ones in window.location.search
  // we need to concat window.location.search before
  const searchParams = queryParamsToObject(
    fixQueryString(`${window.location.search || ''}${location.search || ''}`),
  );

  const setParam = (name, value) => {
    searchParams[name] = value;
    history.replace({
      search: serializeParams(searchParams),
    });
  };

  const replaceParams = (paramsObject) => {
    history.replace({
      search: serializeParams(paramsObject),
    });
  };

  return { searchParams, setParam, replaceParams };
};

export default useSearchQueryParams;
