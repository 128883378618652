import { useLandingPageRouteParams } from 'hooks/useLandingPageRouteParams';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ListingV2 } from 'types/listings';
import {
  getListingProductLine,
  getListingRoute,
  getProductCardButtonLabel,
} from 'utils/listings';
import { useIsMobile } from 'WindowDimensionProvider';
import useHosts from 'api/hosts/useHosts';
import getCoverImage from 'utils/data/medias/getCoverImage';
import { ListingInfoBox } from '../ListingInfoBox';
import {
  CarouselType,
  getCardHeight,
  getCardMaxWidth,
  getCardWidth,
} from './carouselUtils';

interface ListingCarouselCardProps {
  listing: ListingV2;
  isSelected?: boolean;
  type: CarouselType;
}

const ListingCarouselCard: FC<ListingCarouselCardProps> = ({
  listing,
  isSelected = false,
  type,
}) => {
  const { t: translate } = useTranslation();
  const [hovered, setHovered] = useState(false);
  const history = useHistory();
  const isMobile = useIsMobile();
  const { product } = useLandingPageRouteParams();
  const { data: hostData, isLoading: hostLoading } = useHosts([
    listing.hostedById!,
  ]);

  const onActionButtonClick = useCallback(() => {
    const route = getListingRoute(listing);
    history.push(route);
  }, [listing, history]);

  const buttonLabel = getProductCardButtonLabel(
    getListingProductLine(listing),
    translate,
  );

  return (
    <Wrapper
      className={`listing-carousel-card listing-carousel-${product}-card`}
      hovered={hovered}
      role="group"
      aria-label={translate('imageOf', { title: listing.title })}
      isSelected={isSelected}
      bgImage={getCoverImage(listing.medias, '1080')?.url ?? ''}
      type={type}
      isMobile={isMobile}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      {...(isSelected && { onClick: onActionButtonClick })}
    >
      {isSelected && (
        <>
          <ListingInfoBox
            dates={listing.dates}
            hostData={hostData}
            hostLoading={hostLoading}
            productLine={listing.productLine}
            listingBrandId={listing.brandId}
          />
          <Footer className="listing-carousel-card-footer">
            <h1 className="listing-carousel-card-title">{listing.title}</h1>
            <button
              className="listing-carousel-card-button"
              type="button"
              onClick={onActionButtonClick}
            >
              {buttonLabel}
            </button>
          </Footer>
          <ShadowsWrapper className="listing-carousel-card-shawows">
            <ShadowTriangleBottomLeft />
            <ShadowTriangleTopRight />
          </ShadowsWrapper>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  hovered: boolean;
  bgImage: string;
  isSelected?: boolean;
  type: CarouselType;
  isMobile: boolean;
}>`
  position: relative;
  display: flex;
  pointer-events: all;
  justify-content: center;
  align-items: center;
  width: ${({ type, isMobile }) => getCardWidth(type, isMobile)};
  height: ${({ type, isMobile }) => getCardHeight(type, isMobile)};
  max-width: ${({ type, isMobile }) => getCardMaxWidth(type, isMobile)};
  cursor: pointer;
  user-select: none;
  background-image: url(${({ bgImage }) => bgImage});
  background-size: cover;
  background-position: center;
  transition: all 0.2s ease-out;
  scale: ${({ hovered }) => (hovered ? 1.005 : 1)};
  box-shadow: 12px 12px 40px 0 var(--way-palette-black-20),
    60px 60px 110px 0 var(--way-palette-black-20);
`;

const ShadowTriangleBottomLeft = styled.div`
  position: absolute;
  width: 250px;
  top: 100%;
  left: 0px;
  aspect-ratio: 355 / 270;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
  background: linear-gradient(
    105deg,
    var(--way-palette-black-10) 0%,
    rgba(0, 0, 0, 0) 50%
  );
`;

const ShadowsWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  filter: blur(3px);
  pointer-events: none;
`;

const ShadowTriangleTopRight = styled.div`
  position: absolute;
  width: 250px;
  top: 0%;
  left: 100%;
  clip-path: polygon(0% 100%, 0% 0%, 100% 100%);
  aspect-ratio: 370 / 281;
  background: linear-gradient(
    135deg,
    var(--way-palette-black-10) 2.14%,
    rgba(0, 0, 0, 0) 44.68%
  );
`;

const Footer = styled.div`
  position: absolute;
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 24px;
  padding: 24px;
  left: 0px;
  bottom: 0px;
  background: linear-gradient(
    0deg,
    var(--way-palette-black-70) 0%,
    rgba(0, 0, 0, 0) 100%
  );

  @media (max-width: 768px) {
    padding: 16px;
  }

  & > h1 {
    color: var(--way-palette-white-100);
    font-size: 32px;
    margin: 0;

    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
      margin: 0;
      padding: 0;
    }
  }

  & > button {
    padding: 14px 20px;
    background: var(--way-palette-white-100);
    color: var(--way-palette-black-100);
    border: none;
    font-size: 14px;
    line-height: 14px;
    border-radius: var(--way-design-borderRadiusDefault);
    font-weight: 100;
    cursor: pointer;
    height: 40px;
    white-space: nowrap;

    @media (max-width: 768px) {
      height: 36px;
      padding: 8px 12px;
    }
  }
`;

export default ListingCarouselCard;
