import { DimmedText } from 'components/common/styled/common-styled';
import { useBrandCurrency } from 'hooks/useBrandCurrency';
import usePoints from 'hooks/usePoints';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { currencyFormat } from 'utils';

interface CartSummaryItemPriceProps {
  hidePrice: boolean;
  isPrivateBooking: boolean;
  price: number;
  selectedNumber: number;
  isPayingWithPoints?: boolean;
}
const CartSummaryItemPrice = ({
  hidePrice,
  isPrivateBooking,
  price,
  selectedNumber,
  isPayingWithPoints,
}: CartSummaryItemPriceProps) => {
  const { t } = useTranslation();
  const currency = useBrandCurrency();
  const { count } = usePoints(price);

  if (price === 0 && !hidePrice) {
    return (
      <DimmedText size={14} className="cart-summary-item-price">
        {t('complimentary')}
        {!isPrivateBooking && ` x ${selectedNumber}`}
      </DimmedText>
    );
  }
  return (
    <DimmedText size={14} className="cart-summary-item-price">
      {isPayingWithPoints
        ? t('pointsWithCount', { count })
        : currencyFormat(currency)(price)}
      {!isPrivateBooking && ` x ${selectedNumber}`}
    </DimmedText>
  );
};

export default CartSummaryItemPrice;
