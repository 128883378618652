import React from 'react';
import styled from 'styled-components';

type ChevronRightIconProps = Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
  color?: string;
  scale?: number;
};

const StyledSvg = styled.svg.withConfig({
  shouldForwardProp: (prop) => prop !== 'scale',
})<{ scale?: number }>`
  color: var(--way-colors-svgColor);
  height: ${({ scale = 1 }) => 14 * scale}px;
  width: ${({ scale = 1 }) => 8 * scale}px;
`;

const ChevronRightIcon = ({
  color,
  scale = 1,
  ...props
}: ChevronRightIconProps) => (
  <StyledSvg
    fill="none"
    scale={scale}
    style={{ color }}
    viewBox="0 0 8 14"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.29281 6.90393L0.550293 1.16141L1.2574 0.454308L7.35347 6.55038V7.25748L1.2574 13.3536L0.550293 12.6464L6.29281 6.90393Z"
      style={{
        fill: 'currentColor',
        stroke: 'none',
      }}
    />
  </StyledSvg>
);

ChevronRightIcon.displayName = 'ChevronRightIcon';

export default ChevronRightIcon;
