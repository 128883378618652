import useQueryParams from 'hooks/useQueryParams';
import { useRouteMatch } from 'react-router-dom';
import {
  sanitizeDateParam,
  sanitizeDurationParam,
  sanitizeIdParam,
  sanitizeLatestParam,
  sanitizeTimeParam,
} from '../utils/sanitizeParamUtils';

type ParamsType = {
  collectionId?: string;
  groupId?: string;
  resourceId?: string;
};

const useResourceBookingSessionPropsFromUrl = () => {
  const matchCollection = useRouteMatch<ParamsType>(
    '/collection/:collectionId',
  );
  const matchGroup = useRouteMatch<ParamsType>(
    '/collection/:collectionId/group/:groupId',
  );
  const matchResource = useRouteMatch<ParamsType>(
    '/collection/:collectionId/group/:groupId/resource/:resourceId',
  );

  const emptyParams: ParamsType = {};

  const { collectionId, groupId, resourceId } =
    matchResource?.params ??
    matchGroup?.params ??
    matchCollection?.params ??
    emptyParams;

  const { date, time, duration, latest } = useQueryParams<{
    date?: string;
    time?: string;
    duration?: string;
    latest?: string;
  }>();

  return {
    collectionId: sanitizeIdParam(collectionId),
    groupId: sanitizeIdParam(groupId),
    resourceId: sanitizeIdParam(resourceId),
    date: sanitizeDateParam(date),
    time: sanitizeTimeParam(time),
    duration: sanitizeDurationParam(duration),
    latest: sanitizeLatestParam(latest),
  };
};

export default useResourceBookingSessionPropsFromUrl;
