import { CollectionLayout } from 'components/layouts/CollectionLayout';
import { Wrapper } from 'components/theme/Wrapper';
import React, { FC } from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { useIsTablet } from 'WindowDimensionProvider';
import useListing from 'api/listing/useListing';
import { ListingVersion } from '@kouto/types';
import { useSearchParamsObject } from 'utils/url';
import { TwoColumnMapContainer } from './components';
import { CollectionPage, ResourcePage } from './pages';
import { MappedResourceGroupPage } from './pages/ResourceGroupPage/MappedResourceGroupPage';
import { NonMappedResourceGroupPage } from './pages/ResourceGroupPage/NonMappedResourceGroupPage';

export const ReserveCollection: FC = () => {
  const match = useRouteMatch();
  const isTabletView = useIsTablet();
  const searchParams = useSearchParamsObject<{
    latest: string;
  }>();
  const params = useParams<{ collectionId: string }>();

  const { data: collection } = useListing(params.collectionId, {
    version: searchParams.latest
      ? ListingVersion.LATEST
      : ListingVersion.PUBLISHED,
  });

  return (
    <Wrapper>
      <CollectionLayout>
        <TwoColumnMapContainer>
          {!collection?.map ? (
            <Switch>
              <Route
                exact={!isTabletView}
                path={match.path}
                component={CollectionPage}
              />
              {!isTabletView && (
                <Route
                  exact
                  path={`${match.path}/group/:groupId`}
                  component={NonMappedResourceGroupPage}
                />
              )}
            </Switch>
          ) : (
            <Switch>
              <Route exact path={match.path} component={CollectionPage} />
              <Route
                exact
                path={`${match.path}/group/:groupId`}
                component={MappedResourceGroupPage}
              />
              <Route
                exact
                path={`${match.path}/group/:groupId/resource/:resourceId`}
                component={ResourcePage}
              />
            </Switch>
          )}
        </TwoColumnMapContainer>
      </CollectionLayout>
    </Wrapper>
  );
};
