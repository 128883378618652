import { isIntegratedRoomChargeEnabledForExperience } from 'components/helpers';
import { EmbedConfig } from 'features/EmbedConfig';
import {
  ICartCurrentParticipant,
  ICartDiscountParams,
  IIntergrationEnabledParams,
  IOverlapCheckParams,
  IParams,
  IParticipantResult,
  ISelectOptions,
} from 'features/ShoppingCart/types';
import concat from 'lodash/concat';
import difference from 'lodash/difference';
import find from 'lodash/find';
import compose from 'lodash/fp/compose';
import every from 'lodash/fp/every';
import filter from 'lodash/fp/filter';
import intersection from 'lodash/fp/intersection';
import map from 'lodash/fp/map';
import intersec from 'lodash/intersection';
import isNil from 'lodash/isNil';
import values from 'lodash/values';
import moment from 'moment';
import { STATUS_FAILURE } from 'types/app';
import { ICartExperience } from 'types/cart';
import { IExperienceFields } from 'types/experience.types';
import { ResourceGroup } from 'types/listings';
import { isCreditCharge, isRoomCharge, uuidv4 } from 'utils';
import { convertIntervalToMinutes } from 'utils/formatDuration';

export interface AddEvent {
  resourceGroups: ResourceGroup;
  selectedTime: string | null | undefined;
  numberOfParticipants: number;
}

export const findItemById = (
  experienceList: IExperienceFields[],
  id: string,
) => {
  return find(experienceList, (item) => item.id === id);
};

export const isPaymentMethodCompatible = ({
  cartExperienceItems,
  addedItem,
  experienceList,
}: IParams) => {
  return compose(
    every(
      (exp: string[]) =>
        intersection(exp, addedItem.experience.paymentMethods).length >= 1,
    ),
    map((experienceId) => {
      const experienceItem = findItemById(
        experienceList,
        experienceId as string,
      );
      return experienceItem?.paymentMethods || [];
    }),
    map((item) => item.experienceId),
    Object.values,
  )(cartExperienceItems);
};

interface SessionData {
  sessionDateTime: string;
  sessionDurationMinutes: number;
}

function isOverlapped(itemA: SessionData, itemB: SessionData): boolean {
  const startDateTimeA = moment(itemA.sessionDateTime);
  const endDateTimeA = startDateTimeA
    .clone()
    .add(itemA.sessionDurationMinutes, 'minutes');

  const startDateTimeB = moment(itemB.sessionDateTime);
  const endDateTimeB = startDateTimeB
    .clone()
    .add(itemB.sessionDurationMinutes, 'minutes');

  const isOverlap =
    startDateTimeB.isBetween(startDateTimeA, endDateTimeA, null, '[]') ||
    endDateTimeB.isBetween(startDateTimeA, endDateTimeA, null, '[]') ||
    startDateTimeA.isBetween(startDateTimeB, endDateTimeB, null, '[]') ||
    endDateTimeA.isBetween(startDateTimeB, endDateTimeB, null, '[]');

  const isEndEqualStart = endDateTimeB.isSame(startDateTimeA);

  const isStartEqualEnd = startDateTimeB.isSame(endDateTimeA);

  return isOverlap && !isEndEqualStart && !isStartEqualEnd;
}

const checkIsOverlapping = ({
  selectedList,
  addedItem,
}: IOverlapCheckParams) => {
  if (!selectedList.length) {
    return false;
  }

  if (addedItem.experience.isNoHost) {
    return false;
  }

  const isExperienceSessionAdded = selectedList.some(
    ({ sessionDateTime, experienceId, sessionDuration }) => {
      return (
        addedItem.experience.id === experienceId &&
        addedItem.sessionDateTime === sessionDateTime &&
        addedItem.duration === sessionDuration
      );
    },
  );

  if (isExperienceSessionAdded) {
    return false;
  }

  /* eslint-disable-next-line */
  for (const item of selectedList) {
    const { sessionDateTime, sessionDuration } = item;

    if (
      isOverlapped(
        {
          sessionDateTime,
          sessionDurationMinutes: convertIntervalToMinutes(sessionDuration),
        },
        {
          sessionDateTime: addedItem.sessionDateTime,
          sessionDurationMinutes: convertIntervalToMinutes(addedItem.duration),
        },
      )
    ) {
      return true;
    }
  }
  return false;
};

export const isExperienceOverlapping = ({
  cartExperienceItems,
  addedItem,
}: IParams) => {
  if (addedItem.experience?.hostedBy?.allowOverlappingBookings) {
    return false;
  }

  const mutualExperiences = compose(
    filter((item) => !!item && item.experienceId === addedItem.experience.id),
    Object.values,
  )(cartExperienceItems);
  return checkIsOverlapping({
    selectedList: mutualExperiences,
    addedItem,
  });
};

export const isHostOverlapping = ({
  cartExperienceItems,
  addedItem,
  experienceList,
}: IParams) => {
  if (
    addedItem.experience?.hostedBy?.allowOverlappingBookings ||
    addedItem.experience?.loading
  ) {
    return false;
  }

  const sameHostExperiences = Object.values(cartExperienceItems).filter(
    (item) => {
      const currentExperience = findItemById(experienceList, item.experienceId);
      if (!currentExperience) {
        return false;
      }
      return (
        currentExperience?.hostedBy?.id ===
          addedItem?.experience?.hostedBy?.id && !addedItem.experience.isNoHost
      );
    },
  );
  return checkIsOverlapping({
    selectedList: sameHostExperiences,
    addedItem,
  });
};

const getCartItemsWithMatchingSessionTimes = (
  selectedSession: string | null | undefined,
  cartExperienceItems: IParams['cartExperienceItems'],
) => {
  if (!selectedSession) return [];
  return Object.values(cartExperienceItems).filter(({ sessionDateTime }) =>
    sessionDateTime.includes(`T${selectedSession}`),
  );
};

const getAddedItemExperienceIds = (addedItem: ResourceGroup) =>
  new Set(addedItem.experiences.map(({ id }) => id));

const getCartItemIdsAndEntries = (
  cartExperienceItems: IParams['cartExperienceItems'],
) => {
  const cartExperienceEntries = Object.values(cartExperienceItems);
  const cartExperienceIds = new Set(
    cartExperienceEntries.map((item) => item.experienceId),
  );
  return { cartExperienceIds, cartExperienceEntries };
};

const isAddedItemMatchItemInCart = (
  cartExperienceItems: IParams['cartExperienceItems'],
  addedItem: ResourceGroup,
) => {
  const experienceIdsInAddedItem = getAddedItemExperienceIds(addedItem);
  const { cartExperienceIds } = getCartItemIdsAndEntries(cartExperienceItems);

  const hasMatchingExperience = Array.from(experienceIdsInAddedItem).some(
    (id) => cartExperienceIds.has(id),
  );
  return hasMatchingExperience;
};

const sumMaxParticipants = (resourceGroups: ResourceGroup) =>
  resourceGroups?.experiences?.reduce(
    (sum, { maxParticipantsCount }) => sum + (maxParticipantsCount ?? 0),
    0,
  ) || 0;

const calculateParticipantCount = (
  cartExperienceItem: ICartExperience | ICartExperience[],
): number => {
  return (
    Array.isArray(cartExperienceItem)
      ? cartExperienceItem
      : [cartExperienceItem]
  ).reduce((sum, item) => sum + (item.participants?.length || 0), 0);
};

const areExperiencesAndTimeMatching = (
  selectedTime: string | null | undefined,
  addedItem: ResourceGroup,
  cartExperienceItems: IParams['cartExperienceItems'],
): boolean => {
  if (!selectedTime) return false;
  const experienceIdsInAddedItem = getAddedItemExperienceIds(addedItem);
  const { cartExperienceIds, cartExperienceEntries } =
    getCartItemIdsAndEntries(cartExperienceItems);

  const allPresent = Array.from(experienceIdsInAddedItem).every((id) =>
    cartExperienceIds.has(id),
  );

  const allMatch = cartExperienceEntries.every(
    ({ sessionDateTime, bookingMode }) =>
      sessionDateTime.includes(`T${selectedTime}`) && bookingMode === 'private',
  );

  return !!allPresent && !!allMatch;
};

const getReadyCartExperienceItems = (
  cartExperienceItems: IParams['cartExperienceItems'],
) =>
  Object.fromEntries(
    Object.entries(cartExperienceItems).filter(
      ([, item]) => item.state === 'READY',
    ),
  );

export const maxParticipantsReached = (
  { cartExperienceItems }: IParams,
  addedEvent?: AddEvent | undefined,
) => {
  if (
    !addedEvent?.resourceGroups ||
    Object.keys(cartExperienceItems).length === 0
  ) {
    return false;
  }
  const readyCartExperienceItems =
    getReadyCartExperienceItems(cartExperienceItems);

  if (
    areExperiencesAndTimeMatching(
      addedEvent.selectedTime,
      addedEvent.resourceGroups,
      readyCartExperienceItems,
    )
  ) {
    return true;
  }

  const matchingItemInCart = getCartItemsWithMatchingSessionTimes(
    addedEvent.selectedTime,
    readyCartExperienceItems,
  );
  if (
    !matchingItemInCart.length ||
    !isAddedItemMatchItemInCart(
      readyCartExperienceItems,
      addedEvent?.resourceGroups,
    )
  ) {
    return false;
  }
  return (
    calculateParticipantCount(matchingItemInCart) +
      addedEvent.numberOfParticipants >
    sumMaxParticipants(addedEvent.resourceGroups)
  );
};

export const getPaymentMethods = async ({
  cartExperienceItems,
  experienceList,
}: Omit<IParams, 'addedItem'>) => {
  const experiencePaymentMethods = await Promise.all(
    Object.values(cartExperienceItems).map(async (item) => {
      const experience = experienceList.find((e) => e.id === item.experienceId);
      return experience?.paymentMethods ?? [];
    }),
  );
  return intersec(...experiencePaymentMethods);
};

export const getPaymentFields = (guestType: string) => {
  const fields = {
    preArrivalGuest: ['lastName', 'reservationCode'],
    onPropertyGuest: ['lastName', 'methodNumber'],
    nonHotelGuest: [],
  };
  return fields[guestType as keyof typeof fields];
};

export const getSelectOptions = ({
  cartExperienceItems,
}: Pick<IParams, 'cartExperienceItems'>) => {
  return Object.values(cartExperienceItems).reduce<Array<ISelectOptions>>(
    (cart, item) => {
      const currentCartPariticipants = item?.participants?.map((i) => {
        const name = `${i.firstName} ${i.lastName}`;
        return {
          label: name,
          value: `${uuidv4()}T${name}`,
        };
      }) as ISelectOptions[];
      return concat(currentCartPariticipants, cart);
    },
    [],
  );
};

export const initializeFormFields = (
  selectedParticipants: IParticipantResult[],
  embedConfig?: EmbedConfig,
) => {
  return selectedParticipants.reduce(
    (participants, tier) =>
      concat(
        participants,
        new Array(tier.count).fill(null).map((_, index) => ({
          fullName: embedConfig?.participantName?.[index] || '',
          terms: false,
          priceTierName: tier.priceTier,
          id: uuidv4(),
          price: tier.price,
        })),
      ),
    [] as Array<ICartCurrentParticipant>,
  );
};

export const isPMSEnabledForCartExperience = ({
  activeOption,
  cartItems,
  listings,
}: IIntergrationEnabledParams) => {
  const isPMSEnabledForCart = Object.values(cartItems).every((cartItem) => {
    const listing = listings[cartItem.listingId || cartItem.experienceId];
    return isIntegratedRoomChargeEnabledForExperience(listing);
  });
  if (isRoomCharge(activeOption) && !isPMSEnabledForCart) {
    return false;
  }
  if (isCreditCharge(activeOption) && isPMSEnabledForCart) {
    return false;
  }
  return true;
};

export const isFullCartDiscounted = ({
  cartExperienceItems,
  discountCode,
}: ICartDiscountParams) => {
  if (!discountCode?.experienceIds || !cartExperienceItems) {
    return false;
  }
  const cartExpIds = compose(
    map((item) => item.experienceId),
    values,
  )(cartExperienceItems);
  const cartItemDifferences = difference(
    cartExpIds,
    discountCode.experienceIds as string[],
  );
  return cartItemDifferences.length === 0 && discountCode.value === 100;
};

export const getCartIneligibleError = (
  props: IParams,
  fetchExperienceStatus: string,
  addedEvent?: AddEvent | undefined,
) => {
  if (maxParticipantsReached(props, addedEvent)) {
    return 'Error adding to cart. Experience already added';
  }
  if (!isPaymentMethodCompatible(props)) {
    return 'Error adding to cart. Payment method is not compatible';
  }
  if (!props.addedItem.sessionDateTime) {
    return 'Error. Invalid session date and time.';
  }
  if (fetchExperienceStatus === STATUS_FAILURE) {
    return 'Error adding to cart. Please try again later!';
  }
  if (isExperienceOverlapping(props) || isHostOverlapping(props)) {
    return 'Error adding to cart. Experience session is unavailable';
  }

  return null;
};

export const isComplimentary = (
  cartExperienceItems: IParams['cartExperienceItems'],
) => {
  const cartItems = Object.values(cartExperienceItems);

  if (!cartItems || cartItems.some((i) => isNil(i))) {
    return false;
  }

  return cartItems.every((item: ICartExperience) =>
    item.participants.every((tier) => tier.price === 0),
  );
};
