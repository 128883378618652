import useSearchParams from './useSearchParams';

const useSearchParamsObject = <
  T extends Record<string, string | string[]>,
>() => {
  const query = useSearchParams();

  return Object.fromEntries(
    Array.from(query.entries()).map(([key, value]) => [
      key,
      value.includes(',') ? value.split(',') : value,
    ]),
  ) as T;
};

export default useSearchParamsObject;
