import { ListingV2, ListingsV2QueryProps } from 'types/listings';
import useSWRInfinite, { SWRInfiniteKeyLoader } from 'swr/infinite';
import { get } from 'utils/axios';
import type { PaginatedResponse } from '@kouto/types';
import { useBrandId } from 'AppProvider';
import buildListingsV2Url from './buildListingsV2Url';

const buildSWRInfiniteKey = <T extends PaginatedResponse<ListingV2>>(
  url?: string,
  brandId?: string,
): SWRInfiniteKeyLoader<T> => {
  return (index: number, previousPageData: T | null) => {
    if (
      !url ||
      !brandId ||
      (previousPageData?.meta &&
        previousPageData.meta.currentPage >=
          (previousPageData.meta.totalPages ?? 0))
    ) {
      return null;
    }

    const [base, query] = url.split('?');
    const params = new URLSearchParams(query || '');
    params.set('page', (index + 1).toString());
    params.set('brandId', brandId);
    return `${base}?${params}`;
  };
};

const useListingsV2 = (params?: ListingsV2QueryProps) => {
  const url = buildListingsV2Url(params);
  const brandId = useBrandId();

  const swr = useSWRInfinite<PaginatedResponse<ListingV2>>(
    buildSWRInfiniteKey(url, brandId),
    get,
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        if (retryCount >= 3) return; // Stop retrying after 3 attempts
        if (error.status < 500) return; // Only retry if it's a server-side (5xx) error
        setTimeout(() => revalidate({ retryCount }), 2 ** retryCount * 1000); // Exponential backoff
      },
    },
  );

  const items = swr.data?.flatMap((item) => item.items) ?? [];
  const isError = swr.error !== undefined;

  const lastPageData = swr.data?.[swr.data.length - 1];
  const hasMorePages = lastPageData?.meta
    ? lastPageData.meta.currentPage < (lastPageData.meta.totalPages ?? 0)
    : false;

  const canLoadMore = hasMorePages && !swr.isLoading && !swr.isValidating;

  const loadMore = () => {
    if (!isError && canLoadMore) {
      swr.setSize(swr.size + 1);
    }
  };

  return {
    ...swr,
    listings: items,
    loadMore,
    isError,
  };
};

export default useListingsV2;
