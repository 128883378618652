import { useAppState } from 'AppProvider';
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { getRedirectLink } from 'utils/getRedirectLink';

const StyledLink = styled(Link)`
  color: var(--way-colors-contrastColorShades-70);
  font-family: 'Open Sans', sans-serif;
  text-decoration: none;
  transition: color 0.3s ease;
  &:hover {
    color: var(--way-colors-contrastColorShades-100);
  }
`;

interface SignInProps {
  onSignIn: () => void;
  targetBaseUrl: string;
  signInLabel: string;
}

const SignIn = ({ onSignIn, targetBaseUrl, signInLabel }: SignInProps) => {
  const location = useLocation();
  const redirectURL = getRedirectLink(targetBaseUrl);
  const { rewardProgramIntegration } = useAppState();
  const { rewardProgramIntegration: integration } =
    rewardProgramIntegration || {};

  useEffect(() => {
    if (integration?.isSignedIn) return;
    onSignIn();
  }, [location.search]);

  return <StyledLink to={redirectURL}>{signInLabel}</StyledLink>;
};

export default SignIn;
