import { useResourceMapContext } from 'features/Reserve/contexts/ResourceMapContext';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IPriceTier } from 'types/experience.types';
import { DATE_FORMAT } from 'utils';
import useSearchParamsObject from 'utils/url/useSearchParamsObject';
import useSetSearchParam from 'utils/url/useSetSearchParam';
import { useParams } from 'react-router-dom';
import buildReserveResourceUrl from 'router/url/buildReserveResourceUrl';
import useListing from 'api/listing/useListing';
import { ListingVersion } from '@kouto/types';
import useCollectionAvailableSessions from 'features/Reserve/hooks/useCollectionAvailableSessions';
import deriveSessionTime from 'utils/data/sessions/deriveSessionTime';
import deriveSessionDateString from 'utils/data/sessions/deriveSessionDateString';
import findSessionByFilters from 'utils/data/sessions/findSessionByFilters';
import { ResourceGroupPageSkeleton } from '../Skeleton';
import { useSessionTimeOptions } from '../../hooks/useSessionTimeOptions';
import useAvailableDates from '../../hooks/useAvailableDates';
import ResourceGroupLayout from '../../components/ResourceGroupLayout';

type PriceTier = IPriceTier & {
  id: string;
};

export const MappedResourceGroupPage: React.FC = () => {
  const { t: translate } = useTranslation();
  const setSearchParam = useSetSearchParam();

  const { collectionId, groupId } = useParams<{
    collectionId: string;
    groupId: string;
  }>();

  const searchParams = useSearchParamsObject<{
    date?: string;
    time?: string;
    duration?: string;
    latest?: string;
  }>();

  const { data: collection } = useListing(collectionId, {
    version:
      searchParams.latest === 'true'
        ? ListingVersion.LATEST
        : ListingVersion.PUBLISHED,
  });

  const resourceGroup = useMemo(
    () => collection?.resourceGroups?.find((group) => group.id === groupId),
    [collection, groupId],
  );

  const { sessions } = useCollectionAvailableSessions({
    collectionId,
    resourceGroupId: groupId,
    latest: searchParams.latest === 'true',
    from: searchParams.date ?? collection?.firstAvailableDate?.scheduledDate,
    to: searchParams.date ?? collection?.firstAvailableDate?.scheduledDate,
  });

  const selectedSession = findSessionByFilters(sessions, {
    date: searchParams.date,
    time: searchParams.time,
    duration: searchParams.duration,
  });

  const date = deriveSessionDateString(selectedSession);
  const time = deriveSessionTime(selectedSession);
  const duration = selectedSession?.duration;

  const allSessionTimes = useSessionTimeOptions(sessions);
  const {
    focusResources,
    selectedResource: resourceId,
    selectResource,
    unselectResource,
  } = useResourceMapContext();

  const [dateForCalendarAvailability, setDateForCalendarAvailability] =
    useState<moment.Moment>(date ? moment(date) : moment());

  useEffect(() => {
    focusResources([]);
  }, []);

  useEffect(() => {
    if (
      resourceId &&
      date &&
      time &&
      duration &&
      !sessions.find(
        (session) =>
          session.experienceId === resourceId &&
          session.startDateTime === `${date}T${time}` &&
          session.duration === duration,
      )
    ) {
      unselectResource();
    }
  }, [sessions, date, time, duration, unselectResource, resourceId]);

  useEffect(() => {
    if (date) {
      setDateForCalendarAvailability(moment(date));
    }
  }, [date]);

  const { availableDates, isLoadingAvailableDates } = useAvailableDates({
    from: dateForCalendarAvailability
      .clone()
      .startOf('month')
      .format(DATE_FORMAT),
    to: dateForCalendarAvailability.clone().endOf('month').format(DATE_FORMAT),
    collectionId: collection?.id,
    resourceGroupId: groupId,
    latest: searchParams.latest === 'true',
  });

  const reserveUrl = buildReserveResourceUrl({
    collectionId,
    date,
    duration,
    groupId,
    resourceId,
    time,
  });

  const handleSessionChange = (time: string, duration: number) => {
    setSearchParam('time', time);
    setSearchParam('duration', duration.toString());
  };

  const handleDateChange = (date: string) => setSearchParam('date', date);

  const handleResourceChange = useCallback(
    (nextResourceId: string) => {
      if (nextResourceId === resourceId) {
        unselectResource();
      } else {
        selectResource(nextResourceId);
      }
    },
    [selectResource, resourceId, unselectResource],
  );

  if (!collectionId || !groupId || !collection || !resourceGroup) {
    return <h1>{translate('noResourceGroupFound')}</h1>;
  }

  const priceTiers = sessions
    .flatMap<PriceTier>((e) => (e ? e.priceTiers ?? [] : []))
    .sort((a, b) => a.price - b.price);
  const startingPrice = priceTiers[0]?.price || 0;

  const resourcesWithAvailabilities = resourceGroup.experiences.filter(
    (exp) =>
      !!sessions.find(
        (session) =>
          session.experienceId === exp.id &&
          (!duration || session.duration === duration) &&
          (!time || session.startDateTime.split('T')[1] === time),
      ),
  );

  const resourceGroupPayload = {
    id: resourceGroup.id,
    groupListItems: resourcesWithAvailabilities.map((item) => ({
      id: item.id,
      name: item.title,
      number: item.number,
    })),
    headline: resourceGroup.description,
    headlineTags: collection.vibes.map(({ name }) => name),
    heroImage: resourceGroup.pictures?.[0],
    price: startingPrice,
    title: resourceGroup.title,
    description: resourceGroup.description,
    includedItems: resourceGroup.included,
    experiences: resourceGroup.experiences,
  };

  if (!collection && !resourceGroup) {
    return <ResourceGroupPageSkeleton />;
  }

  return (
    <ResourceGroupLayout
      availableDates={availableDates}
      categoryName={collection.category?.name}
      collectionBrandId={collection.brandId}
      collectionId={collection.id}
      collectionName={collection.title}
      isLoadingAvailableDates={isLoadingAvailableDates}
      onDateChange={handleDateChange}
      onMonthChange={setDateForCalendarAvailability}
      onResourceChange={handleResourceChange}
      onSessionChange={handleSessionChange}
      reserveUrl={reserveUrl}
      resourceGroup={resourceGroupPayload}
      selectedDate={date || ''}
      selectedDuration={duration || 0}
      selectedTime={time || ''}
      sessionsTimeOptions={allSessionTimes}
    />
  );
};
