import { PaymentIntentError } from '../types';

export const checkPaymentIntentErrorHasExperienceId = ({
  cartItemsError = [],
}: PaymentIntentError) => {
  const item = cartItemsError.find(
    ({ experienceId, resourceGroupId }) => experienceId || resourceGroupId,
  );
  if (item) {
    return !!item.experienceId;
  }
  return false;
};
