import TRANSLATION_ACCESSIBILITY_FR from './accessibility/translation';
import TRANSLATION_BOOKING_FR from './booking/translation';
import TRANSLATION_EVENTS_FR from './events/translation';
import TRANSLATION_EXPERIENCES_FR from './experiences/translation';
import TRANSLATION_FORM_FR from './form/translation';
import TRANSLATION_LANDING_PAGES_FR from './landingPages/translation';
import TRANSLATION_RESERVE_FR from './reserve/translation';
import TRANSLATION_SIGNIN_FR from './singin/translation';
import TRANSLATION_DATES_FR from './weekdays/translation';

export default {
  ...TRANSLATION_DATES_FR,
  ...TRANSLATION_BOOKING_FR,
  ...TRANSLATION_FORM_FR,
  ...TRANSLATION_EXPERIENCES_FR,
  ...TRANSLATION_LANDING_PAGES_FR,
  ...TRANSLATION_EVENTS_FR,
  ...TRANSLATION_RESERVE_FR,
  ...TRANSLATION_ACCESSIBILITY_FR,
  ...TRANSLATION_SIGNIN_FR,
};
