import { ResourceGroup } from 'types/listings';

const getFirstStartingResourceGroup = (
  resourceGroups: ResourceGroup[] | undefined,
): ResourceGroup | undefined =>
  resourceGroups?.sort((a, b) => {
    const dateA = a.firstAvailableDate?.scheduledDate
      ? new Date(a.firstAvailableDate.scheduledDate)
      : new Date(0);
    const dateB = b.firstAvailableDate?.scheduledDate
      ? new Date(b.firstAvailableDate.scheduledDate)
      : new Date(0);
    return dateA.getTime() - dateB.getTime();
  })[0];

export default getFirstStartingResourceGroup;
