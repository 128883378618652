import { useAppState } from 'AppProvider';
import InfoCircleIcon from 'assets/bx-info-circle';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  padding: 16px;
  width: 100%;
`;

const IconContainer = styled.div`
  margin-right: 10px;
`;

const Header = styled.div.withConfig({
  shouldForwardProp: (prop) => !['variantColor'].includes(prop),
})<{ variantColor: string }>`
  color: ${(p) => p.variantColor};
  margin-bottom: 8px;
`;

interface AlertProps {
  children?: React.ReactNode;
  heading: string;
  variant?: 'info';
}

const Alert = ({ children, heading, variant = 'info' }: AlertProps) => {
  const { theme } = useAppState();

  const variantConfig = {
    info: {
      color: theme.colors.alert,
      icon: <InfoCircleIcon color={theme.colors.alert} />,
    },
  }[variant];

  return (
    <Container>
      <IconContainer>{variantConfig.icon}</IconContainer>
      <div>
        <Header variantColor={variantConfig.color}>{heading}</Header>
        {children}
      </div>
    </Container>
  );
};

export default Alert;
