import useSWR from 'swr';
import { get } from 'utils/axios';
import { useBrandId } from 'AppProvider';

export type Vibe = {
  id: string;
  name: string;
  order: number | null;
};

const useBrandVibes = () => {
  const brandId = useBrandId();
  const getVibesUrl = `v1/brands/${brandId}/vibes`;

  const { data, isLoading } = useSWR(getVibesUrl, get<{ items: Vibe[] }>);

  return {
    vibes: data?.items ?? [],
    isLoading,
  };
};

export default useBrandVibes;
