import { RewardProgramIntegrationData } from 'types/rewardProgramIntegration';
import { get } from '../api';
import { getHyattMemberProfileUrl } from './hyattUrls';

export interface RewardProgramIntegrationResponse {
  data: RewardProgramIntegrationData;
}

const getHyattMemberProfile = async (token: string) => {
  const url = getHyattMemberProfileUrl();

  const data: RewardProgramIntegrationResponse = await get(url, {
    headers: {
      Authorization: token,
    },
  });

  return data;
};

export default getHyattMemberProfile;
