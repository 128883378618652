import { useDispatch } from 'AppProvider';
import { ButtonLink } from 'components/theme/Button/Button';
import React, { useEffect, useId, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useClickOutside from 'utils/useClickOutside';
import { setSignOutSuccess } from '../../reducers/rewardProgramIntegration';

const SignOutContainer = styled.div`
  display: inline-block;
  position: relative;
`;
const StyledButtonLink = styled(ButtonLink)`
  display: flex;
  flex-direction: column;
`;

const SignOutDropdown = styled.ul.withConfig({
  shouldForwardProp: (prop) => !['isDropDownOpen'].includes(prop),
})<{ isDropDownOpen: boolean }>`
  align-items: center;
  background-color: var(--way-palette-white-100);
  border-radius: var(--way-design-borderRadiusDefault);
  border: 0.5px solid var(--way-colors-borderColor);
  box-shadow: var(--way-design-boxShadow-s);
  color: var(--way-colors-linkColor);
  display: ${({ isDropDownOpen }) => (isDropDownOpen ? 'flex' : 'none')};
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
  justify-content: center;
  list-style: none;
  margin-top: 10px;
  padding-left: 40px;
  padding-right: 40px;
  position: absolute;
  width: max-content;
  z-index: 100;
`;

const SortOption = styled.li.withConfig({
  shouldForwardProp: (prop) => !['isSelected'].includes(prop),
})<{ isSelected?: boolean }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  list-style: none;
  padding-bottom: 16px;
  padding-top: 16px;
`;

const TertiaryText = styled.span`
  color: var(--way-colors-tertiaryTextColor);
`;

const SignOut = () => {
  const [isDropDownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownId = useId();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropDownOpen);
  };

  const handleSignOut = () => {
    // signout call
    dispatch(setSignOutSuccess());
    const { href } = new URL(window.location.href);
    const currentUrl = new URL(href);
    currentUrl.searchParams.delete('auth_code');
    window.history.replaceState({}, '', currentUrl.toString());
    setIsDropdownOpen(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      setIsDropdownOpen(false);
    }
  };

  const createOptionKeyDownHandler = () => (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      handleSignOut();
    }
  };

  const onClickOutside = () => {
    setIsDropdownOpen(false);
  };

  useClickOutside(dropdownRef, onClickOutside);

  const signOutOptions = [{ label: translate('signOut'), value: 'signout' }];

  useEffect(() => {
    if (isDropDownOpen && dropdownRef.current) {
      dropdownRef.current.focus();
    }
  }, [isDropDownOpen]);

  return (
    <SignOutContainer ref={dropdownRef}>
      <StyledButtonLink
        aria-controls={dropdownId}
        aria-expanded={isDropDownOpen}
        aria-haspopup="listbox"
        onClick={toggleDropdown}
        type="button"
      >
        <span>John</span>
        <TertiaryText>9,254 points</TertiaryText>
      </StyledButtonLink>
      <SignOutDropdown
        aria-controls={dropdownId}
        isDropDownOpen={isDropDownOpen}
        onKeyDown={handleKeyDown}
        role="listbox"
      >
        {signOutOptions
          .filter((option) => option.value !== '')
          .map((option) => (
            <SortOption
              key={option.value}
              onClick={() => handleSignOut()}
              onKeyDown={createOptionKeyDownHandler()}
              role="option"
              tabIndex={0}
            >
              {option.label}
            </SortOption>
          ))}
      </SignOutDropdown>
    </SignOutContainer>
  );
};

export default SignOut;
