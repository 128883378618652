import { useAppState } from 'AppProvider';
import { Divider, Flex } from 'components/common/styled/common-styled';
import { Error } from 'components/theme/Form/Form';
import { SkeletonLine } from 'components/theme/Skeleton/SkeletonLine';
import { PaymentContext } from 'features/ShoppingCart/contexts/PaymentContext';
import useCartItemsListings from 'hooks/useCartItemsListings';
import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CartSummaryItem from './CartSummaryItem';
import DiscountForm from './DiscountForm';
import PriceSection from './PriceSection';
import {
  BookSidebar,
  BookSticky,
  CartFooter,
  CartItemsContainer,
  CartSummaryTitle,
} from './styles';

interface CartSummaryProps {
  isPayingWithPoints?: boolean;
}

const CartSummary = ({ isPayingWithPoints }: CartSummaryProps) => {
  const { t } = useTranslation();
  const { cartItems, listings, isLoading } = useCartItemsListings();
  const {
    cartTotal,
    cartSubTotal,
    totalDiscountAmount,
    cartItemsDetails,
    includedTaxes,
    nonIncludedTaxes,
  } = useContext(PaymentContext);
  const cartItemsValues = Object.values(cartItems);
  const { cartError } = useAppState(
    (state: Record<string, unknown>) => state.booking,
  );

  const isFreeBeforeDiscount = cartTotal === 0 && totalDiscountAmount === 0;

  if (!cartItemsValues.length) {
    return (
      <BookSticky>
        <BookSidebar>
          <div>The cart is empty</div>
        </BookSidebar>
      </BookSticky>
    );
  }

  if (isLoading) {
    return (
      <BookSticky>
        <SkeletonLine style={{ height: 300 }} translucent />
      </BookSticky>
    );
  }

  const sortedCartItems = cartItemsValues.sort((exp1, exp2) => {
    return exp1.sessionDateTime.localeCompare(exp2.sessionDateTime);
  });

  return (
    <BookSticky>
      <BookSidebar>
        <CartSummaryTitle>
          <Flex direction="row">
            <span>{t('reservationSummary')}</span>{' '}
            <span>({cartItemsValues.length})</span>
          </Flex>
          {cartError && (
            <div style={{ paddingTop: '10px' }}>
              <Error>
                There seem to be an error while booking few or all of the
                experiences.
              </Error>
            </div>
          )}
        </CartSummaryTitle>
        <CartItemsContainer>
          {sortedCartItems.map((cartItem, i) =>
            cartItemsDetails[cartItem.cartItemId] ? (
              <Fragment key={cartItem.cartItemId}>
                <CartSummaryItem
                  isPayingWithPoints={isPayingWithPoints}
                  cartItem={cartItem}
                  cartItemTotalDetails={cartItemsDetails[cartItem.cartItemId]}
                  listing={
                    listings[cartItem?.listingId || cartItem?.experienceId]
                  }
                />
                {cartItemsValues.length !== i + 1 && <Divider space={20} />}
              </Fragment>
            ) : null,
          )}
        </CartItemsContainer>

        <CartFooter>
          {isFreeBeforeDiscount ? null : (
            <DiscountForm
              totalCartDiscount={totalDiscountAmount}
              cartExperienceIds={Object.values(cartItems).map(
                (cartItem) => cartItem.experienceId,
              )}
            />
          )}
          <PriceSection
            cartTotal={cartTotal}
            cartSubTotal={cartSubTotal}
            areAllListingsPricesHidden={Object.values(listings).every(
              (listing) => listing.hidePrice,
            )}
            includedTaxes={includedTaxes}
            nonIncludedTaxes={nonIncludedTaxes}
            isPayingWithPoints={isPayingWithPoints}
          />
        </CartFooter>
      </BookSidebar>
    </BookSticky>
  );
};

export default CartSummary;
