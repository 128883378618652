import React from 'react';
import styled from 'styled-components';

type PlayIconProps = Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
  color?: string;
  scale?: number;
};

const StyledSvg = styled.svg.withConfig({
  shouldForwardProp: (prop) => prop !== 'scale',
})<{ scale?: number }>`
  color: var(--way-colors-svgColor);
  height: ${({ scale = 1 }) => 20 * scale}px;
  width: ${({ scale = 1 }) => 20 * scale}px;
`;

const PlayIcon = ({ color, scale = 1, ...props }: PlayIconProps) => (
  <StyledSvg
    fill="none"
    scale={scale}
    style={{ color }}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 3.13281L16.4658 9.48143L5.5 16.4072V3.13281ZM6.5 4.86726V14.5929L14.5342 9.51864L6.5 4.86726Z"
      style={{
        fill: 'currentColor',
        stroke: 'none',
      }}
    />
  </StyledSvg>
);

PlayIcon.displayName = 'PlayIcon';

export default PlayIcon;
