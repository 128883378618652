import { CreatePaymentIntentEndpoint } from '@kouto/types';
import { useAppState, useBrandId } from 'AppProvider';
import useCartItems from 'hooks/useCartItems';
import { useMemo } from 'react';
import useSWR from 'swr';
import { PaymentMethod } from 'types/payment';
import { z } from 'zod';
import { post } from '../../../../../utils/axios';
import getCreatePaymentIntentEndpointUrl from '../../../utils/getCreatePaymentIntentEndpointUrl';
import { getCartItemsData } from '../checkoutUtils';

type PaymentIntentResponse = {
  data: z.infer<typeof CreatePaymentIntentEndpoint.responseSchema>;
};

const usePaymentIntent = (selectedPaymentMethod: string) => {
  const brandId = useBrandId();
  const { cartId, cartItems } = useCartItems();
  const { discountCode } = useAppState(
    (state: Record<string, unknown>) => state.discountCode,
  );
  const defaultCartItemsData = useMemo(() => {
    return getCartItemsData({ cartItems, discountCode });
  }, [cartItems, discountCode]);

  const isHyattPaymentOption = useMemo(
    () => selectedPaymentMethod === PaymentMethod.HYATT_POINTS,
    [selectedPaymentMethod],
  );
  const shouldFetch =
    !!cartId && !!defaultCartItemsData && !isHyattPaymentOption;

  const { data, isLoading, error, mutate } = useSWR<PaymentIntentResponse>(
    shouldFetch
      ? [
          getCreatePaymentIntentEndpointUrl(brandId, cartId),
          defaultCartItemsData,
        ]
      : null,
    ([url, defaultCartItemsData]) =>
      post<PaymentIntentResponse, typeof defaultCartItemsData>(url, {
        data: defaultCartItemsData,
      }),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    },
  );
  const paymentIntent = data?.data.paymentIntentSecret?.slice(0, 27);
  return {
    paymentIntent,
    paymentIntentSecret: data?.data.paymentIntentSecret,
    isLoading,
    error,
    refreshPaymentIntent: () => mutate(),
  };
};

export default usePaymentIntent;
