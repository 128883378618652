import { createAsyncThunk } from '@reduxjs/toolkit';
import { RewardProgramIntegrationData } from '../types/rewardProgramIntegration';
// Mocked API call function
const fetchRewardProgramIntegrationApi =
  async (): Promise<RewardProgramIntegrationData> => {
    // Simulating API delay
    await new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });

    // Always return the mocked data
    return {
      organization: 'Hyatt',
      apis: [
        {
          name: 'getUserInfo',
          url: 'https://api.hyatt.com/user',
          method: 'GET',
        },
        {
          name: 'signup',
          url: 'https://www.hyatt.com/en-US/member/sign-in?returnUrl=',
          method: '',
        },
        {
          name: 'signout',
          url: 'https://api.hyatt.com/signout',
          method: '',
        },
      ],
      paymentMethods: {
        points: { name: 'World of Hyatt Points' },
      },
      buttonLabels: {
        signIn: 'Sign in to Hyatt',
        payment: 'Pay with Hyatt Points',
      },
      id: '1234',
      enabled: false,
    };
  };

export const fetchRewardProgramIntegration = createAsyncThunk(
  'rewardProgramIntegration/fetchRewardProgramIntegration',
  async (_, { rejectWithValue }) => {
    try {
      const data = await fetchRewardProgramIntegrationApi();
      return data;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);
