import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { formatTimeShort } from 'utils/dateTime';
import moment from 'moment/moment';
import useEventBookingSession from 'features/Events/hooks/useEventBookingSession';
import { SkeletonLineSlim } from 'components/theme/Skeleton/Skeleton';
import useWaitList from 'api/waitlist/useWaitList';
import getUpdatedSelectedDate from 'utils/data/getUpdatedSelectedData';

interface Props {
  selectedTime: string | null;
}

const TicketHeader: FC<Props> = ({ selectedTime }) => {
  const { isLoading, event, date } = useEventBookingSession();
  const { data: waitlist } = useWaitList(event?.waitlistId, event?.brandId);
  const selectedDate = getUpdatedSelectedDate(date, event, waitlist);
  const { t: translate } = useTranslation();

  if (isLoading || !event || !selectedDate) {
    return (
      <Wrapper>
        <TitleSkeleton />
        <DateTimeSkeleton />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Title className="page-heading">{event.title}</Title>
      <DateTime>
        {moment(selectedDate).format('dddd, MMMM Do')}{' '}
        {selectedTime
          ? `${translate('at')} ${formatTimeShort(selectedTime)}`
          : ''}
      </DateTime>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const Title = styled.h1`
  margin-top: 0px;
  color: var(--way-colors-primaryTextColor);
  margin-bottom: 6px;
  font-size: 28px;
`;

const DateTime = styled.span`
  color: var(--way-colors-contrastColorShades-60);
`;

const TitleSkeleton = styled(SkeletonLineSlim)`
  width: 50%;
  height: 37px;
  margin-bottom: 6px;
`;

const DateTimeSkeleton = styled(SkeletonLineSlim)`
  width: 33%;
  height: 18px;
  margin-bottom: 10px;
`;

export default TicketHeader;
