import { useAppState } from 'AppProvider';
import useCartItems from 'hooks/useCartItems';
import { useCallback, useEffect, useState } from 'react';
import useFetchExperienceList from 'selectors/experienceList';
import { PaymentMethod } from 'types/payment';
import { IExperiencePayload } from '../types';
import { getPaymentMethods } from '../utils';

export type PaymentOptions = Array<{
  name: PaymentMethod;
  shouldValidateComplimentaryBooking: boolean;
}>;

export const usePaymentOptions = () => {
  const { integrations, settings } = useAppState(
    (state: Record<string, unknown>) => state.brand,
  );
  const [paymentOptions, setPaymentOptions] = useState<PaymentOptions>([]);
  const { cartItems } = useCartItems();
  const { experienceList } = useFetchExperienceList();

  const getExperienceValidationConfigForFreeBooking = (
    experienceList: IExperiencePayload,
    type: keyof IExperiencePayload[number]['shouldValidateComplimentaryBooking'],
  ) => {
    const validationConfig = experienceList.find((e) => {
      return !!e.shouldValidateComplimentaryBooking;
    });
    if (validationConfig) {
      return (
        validationConfig.shouldValidateComplimentaryBooking?.[type] ?? null
      );
    }
    return null;
  };

  const shouldValidateComplimentaryBookings = useCallback(
    (method: PaymentMethod): boolean => {
      if (
        method === PaymentMethod.CREDIT_CARD ||
        method === PaymentMethod.KICC
      ) {
        return false;
      }

      if (method === PaymentMethod.ROOM_CHARGE) {
        const shouldValidate = getExperienceValidationConfigForFreeBooking(
          experienceList,
          'roomCharge',
        );
        if (shouldValidate !== null) {
          return shouldValidate;
        }

        if (integrations.length) {
          const integration = integrations[0];

          if (
            integration.config &&
            'shouldValidateComplimentaryBooking' in integration.config
          ) {
            return !!integration.config.shouldValidateComplimentaryBooking;
          }
        }
        return settings?.shouldValidateComplimentaryBooking?.roomCharge;
      }

      if (method === PaymentMethod.MEMBER_NUMBER) {
        const shouldValidate = getExperienceValidationConfigForFreeBooking(
          experienceList,
          'memberCharge',
        );
        if (shouldValidate !== null) {
          return shouldValidate;
        }
        return settings?.shouldValidateComplimentaryBooking?.memberCharge;
      }

      return false;
    },
    [experienceList, integrations, settings],
  );

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      const paymentMethods = await getPaymentMethods({
        cartExperienceItems: cartItems,
        experienceList,
      });
      const updatedPaymentOptions: PaymentOptions = paymentMethods.map((p) => ({
        name: p,
        shouldValidateComplimentaryBooking:
          shouldValidateComplimentaryBookings(p),
      }));
      setPaymentOptions(updatedPaymentOptions);
    };
    fetchPaymentMethods();
  }, [experienceList, cartItems, shouldValidateComplimentaryBookings]);

  return paymentOptions;
};
