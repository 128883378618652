import { isEmpty } from 'lodash';

const consolidateEmbeddedCategoryWithParams = <
  T extends Record<string, unknown>,
>(
  embeddedId: string | null,
  params: T,
): T & { categoryIds?: string[] } => {
  if (!embeddedId && isEmpty(params)) return {} as T;

  if (!params.categoryIds && isEmpty(params)) {
    return {
      ...params,
      categoryIds: embeddedId ? [embeddedId] : undefined,
    };
  }

  return {
    ...params,
    categoryIds: [
      ...(Array.isArray(params.categoryIds)
        ? params.categoryIds
        : [params.categoryIds]
      ).filter(Boolean),
    ],
  };
};

export default consolidateEmbeddedCategoryWithParams;
