import { Session } from 'types/listings';

const getLastEndingSession = (sessions: Session[]): Session | undefined =>
  sessions.sort((a, b) => {
    const endTimeA =
      new Date(a.startDateTime).getTime() + a.duration * 60 * 1000;
    const endTimeB =
      new Date(b.startDateTime).getTime() + b.duration * 60 * 1000;
    return endTimeA - endTimeB;
  })[sessions.length - 1];

export default getLastEndingSession;
