import React from 'react';
import styled from 'styled-components';
import RateSvg from 'components/common/rating-small.svg';

const YellowStar = styled(RateSvg)`
  margin-right: 7px;
`;

const RateBlock = styled.div`
  display: inline-flex;
  align-items: center;
`;

interface RatingSummaryProps {
  rating: number | null;
}

const RatingSummary = ({ rating }: RatingSummaryProps) => {
  if (!rating) return null;

  return (
    <RateBlock className="rating-summary__rate-block">
      <YellowStar />
      {rating}
    </RateBlock>
  );
};

export default RatingSummary;
