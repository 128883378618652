import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  height: 18px;
`;

const LinkPayIcon = () => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
    <path
      fill="#00D66F"
      d="M12 24c6.6274 0 12-5.3726 12-12 0-6.62743-5.3726-12-12-12C5.37259 0 0 5.37257 0 12c0 6.6274 5.37259 12 12 12Z"
    />

    <path
      fill="#011E0F"
      d="M11.4479 4.80005H7.74707c.72 3.0096 2.82243 5.58235 5.45283 7.19995-2.6352 1.6176-4.73283 4.1904-5.45283 7.2h3.70083c.9168-2.784 3.456-5.2032 6.576-5.6976v-3.0095c-3.1248-.4896-5.664-2.90885-6.576-5.69285Z"
    />
  </StyledSvg>
);

LinkPayIcon.displayName = 'LinkPayIcon';

export default LinkPayIcon;
