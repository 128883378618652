import styled from 'styled-components';

import { MainTitle, Heading4, Heading5, P1 } from 'components/theme/Typography';
import { StyledInput } from 'components/theme/Form';
import { Button } from 'components/theme/Button';

import Duration from 'assets/icon-time';
import Chip from 'components/Chip';

export const dropdownBtnStyles = {
  padding: '5px',
  margin: '11px 0 0',
  userSelect: 'none',
};

export const selectStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: 'transparent',
    minHeight: 54,
    paddingTop: '8px',
    paddingBottom: '8px',
    borderColor: 'var(--way-colors-borderColor)',
    borderWidth: '0.5px',
    borderRadius: 'var(--way-design-borderRadiusDefault)',
    boxShadow: 'none',
    cursor: 'pointer',
    outline:
      state && state?.isFocused
        ? '2px solid var(--way-colors-focusBorderColor)'
        : 'none',
  }),
  menu: (provided, state) => ({
    ...provided,
    opacity: state.isOpen ? 0.5 : 1,
    width: '100%',
    color: state.selectProps.menuColor,
    padding: 0,
    backgroundColor: 'var(--way-palette-white-100)',
    transition: 'all .2s ease-out',
    boxShadow: 'var(--way-design-boxShadow-l)',
  }),
  menuList: (provided, state) => ({
    ...provided,
    opacity: state.isOpen ? 0.5 : 1,
    width: '100%',
    color: state.selectProps.menuColor,
    backgroundColor: 'var(--way-palette-white-100)',
    transition: 'all .2s ease-out',
    padding: 5,
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor:
      isSelected || isFocused ? 'var(--way-palette-black-10)' : '',
    borderRadius: '4px',
    color: isDisabled ? 'var(--way-palette-charcoal-100)' : '#051E39',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    outline: isFocused
      ? '2px solid var(--way-colors-focusBorderColor)'
      : 'none',
  }),
  input: (styles) => ({ ...styles }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },
};

export const selectTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: 'var(--way-palette-black-10)',
    primary: 'var(--way-palette-black-60)',
  },
});

export const HostedByImage = styled.img`
  width: 100%;
  object-fit: cover;
  height: ${({ isSquare }) => (isSquare ? 'auto' : '100%')};
  top: 0;
  left: 50%;
  position: absolute;
  object-position: center;
  transform: translateX(-50%);
`;

export const ParticipantWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.5px solid var(--way-colors-borderColor);
  padding: 17px 12px;
  border-radius: var(--way-design-borderRadiusDefault);
  height: 54px;
  cursor: pointer;
  &:hover {
    border: 0.5px solid var(--way-colors-borderHoverColor);
  }
`;

export const WrapperFirst = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    transform: scale(0.85);
    stroke: var(--way-colors-svgColor);
  }
  & > span {
    margin-left: 12px;
  }
`;

export const WrapperSecond = styled.div`
  display: flex;
  align-items: center;
  max-height: fit-content;
  & > div {
    height: 18px;
    border-left: 0.5px solid var(--way-palette-black-70);
    margin-right: 5px;
  }

  & > svg {
    transform: scale(0.8);
    stroke: ${({ isDropdownVisible }) =>
      isDropdownVisible ? 'darkgray' : 'lightgray'};
  }
`;

export const TierDropdown = styled.div`
  overflow: scroll;
  max-height: 400px;
  padding: 24px;
  position: absolute;
  width: 100%;
  min-width: 255px;
  max-width: 355px;
  z-index: 101;
  background-color: var(--way-palette-white-100);
  border: 0.5px solid var(--way-colors-borderColor);
  transition-duration: 0.2s;
  box-sizing: border-box;
  box-shadow: var(--way-design-boxShadow-s);
  border-radius: var(--way-design-borderRadiusDefault);
  top: 60px;

  &:hover {
    border: 0.5px solid var(--way-colors-borderHoverColor);
  }
`;

export const OpenTierDropdown = styled.div`
  overflow: scroll;
  padding: 24px;
  position: absolute;
  width: 100%;
  max-width: 355px;
  z-index: 101;
  background-color: var(--way-palette-white-100);
  border: 0.5px solid var(--way-colors-borderColor);
  transition-duration: 0.2s;
  box-sizing: border-box;
  box-shadow: var(--way-design-boxShadow-s);
  border-radius: var(--way-design-borderRadiusDefault);
  top: ${({ isPartySizeEnforced }) =>
    isPartySizeEnforced ? '190px' : '155px'};

  &:hover {
    border: 0.5px solid var(--way-colors-borderHoverColor);
  }
`;

export const TierOverlay = styled.div`
  background-color: none;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  position: fixed;
  z-index: 8;
`;

export const SidebarForm = styled.form`
  position: relative;
  z-index: 100;
`;

export const ExternalLinkContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ExternalLink = styled.a`
  text-decoration: none;
`;

export const DurationIcon = styled(Duration)`
  margin-right: 8px;
  opacity: 0.5;
`;

export const CutOffMessage = styled.p`
  font-size: 16px;
  color: gray;
  margin-top: -12px;
  margin-left: 4px;
  display: flex;
  align-items: center;
`;

export const ParticipantsEventWrapper = styled.div`
  margin: 22px 0;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.5px solid var(--way-colors-borderColor);
  padding: 17px 12px;
  border-radius: var(--way-design-borderRadiusDefault);
  height: 54px;
  cursor: pointer;
  &:hover {
    border-color: darkgray;
  }
`;

export const ParticipantsBoxContainer = styled.div`
  margin: 22px 0;
  width: 50%;
`;

export const ExperienceNavigation = styled.div`
  margin-bottom: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const LinksBack = styled.a`
  cursor: pointer;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
`;

export const IconBack = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export const NextIconCalendar = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(45, 54, 87, 0.25);
  color: var(--way-palette-black-30);
  font-size: 24px;
  position: absolute;
  margin-top: -2px;
  top: 19px;
  right: 13px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  padding-left: 2px;
`;

export const PrevIconCalendar = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(45, 54, 87, 0.25);
  color: var(--way-palette-black-30);
  font-size: 24px;
  position: absolute;
  margin-top: -2px;
  top: 19px;
  left: 13px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  padding-right: 2px;
`;
export const IconBackSelect = styled.div`
  display: inline-flex;
  margin-right: 12px;

  svg {
    transform: scale(0.8);
  }
`;
export const ExperiencesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ExperiencesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 50px;
  margin-bottom: 50px;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const ExperiencesHeaderContent = styled.div`
  border-bottom: 0.5px solid var(--way-colors-borderColor);
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ExperiencesHeaderContentMeta = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr minmax(20px, 111px);
  grid-gap: 12px;
  padding-bottom: 24px;
`;
export const ExperiencesHeaderContentList = styled.div``;
export const ListLabel = styled.div`
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 4px;
  margin-top: 8px;
  display: block;
`;
export const ListValue = styled.div`
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  display: inline-block;
`;

export const MainTitles = styled(MainTitle)`
  margin-bottom: 20px;
  font-size: 36px;
  line-height: 44px;
  @media (min-width: 768px) {
    margin-top: 10px;
    font-size: 36px;
    line-height: 44px;
  }
`;

export const Headline = styled.p`
  font-size: 18px;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 20px;
`;

export const TagButtonWrap = styled.div`
  margin-bottom: 12px;
`;

// svg Icons
export const DurationWrap = styled.span``;
export const PeopleIconWrap = styled.span``;
export const LocationIconWrap = styled.span``;

// ExperiencesBody

export const ExperiencesBody = styled.main`
  @media (min-width: 1025px) {
    display: grid;
    grid-template-columns: minmax(100px, 706px) minmax(100px, 405px);
    justify-content: space-between;
    grid-gap: 30px;
  }
  aside {
    @media (min-width: 1025px) {
      position: sticky;
      top: 150px;
      height: fit-content;
    }
  }
`;

export const ExperiencesBodyContent = styled.section``;

export const Paragraph = styled(P1)`
  line-height: 32px;
`;
export const Padding8 = styled.div`
  padding-bottom: 40px;
`;
export const SidebarLocation = styled(P1)`
  margin-bottom: 15px;
`;

export const SidebarSubtitle = styled(P1)`
  margin-bottom: 14px !important;
`;

export const AvailabilitySessions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 9px;
  margin-bottom: 15px;
  margin-top: 15px;
`;

export const ExperiencesAvailability = styled.div`
  margin-bottom: 30px;
  strong {
    font-weight: normal;
  }
  > div {
    min-height: 350px;
  }
  table {
    margin-bottom: 100px;
  }
`;
export const ExperiencesWeatherWidget = styled.div`
  display: block;
`;

export const SingleCalendarWrapper = styled.div`
  display: block;
  strong {
    font-weight: normal;
  }
  button {
    display: inline-block;
    padding: 0 0 0 12px;
    margin: 0;
    &:last-child {
      padding-right: 12px;
      &:hover {
        background: none;
      }
      display: none;
    }
    + div {
      display: inline-block;
      margin: 0;
      padding: 0;
      border-radius: 0;
      width: 100%;
      background: transparent;
    }
  }
  > div > div {
    width: 100%;
  }
  > div > div > div > div {
    display: grid;
    grid-template-columns: minmax(30px, 30px) 1fr;
    border: 0.5px solid var(--way-colors-borderColor);
    border-radius: var(--way-design-borderRadiusDefault);
    transition-duration: 0.2s;
    padding-right: 0;
    width: 100%;
    background: transparent;

    &:hover {
      border: 0.5px solid var(--way-colors-borderHoverColor);
    }
  }
  input {
    border-bottom: none !important;
    font-size: 16px;
    line-height: 19px;
    font-family: ${({ theme }) => theme.font.secondaryFont};
    padding: 17px 12px;
    border-radius: var(--way-design-borderRadiusDefault);
    cursor: pointer;
    color: var(--way-colors-calendarInputColor);
    background: transparent;
    border: none;
    min-height: 1px;
    font-weight: normal;
    font-style: normal;
    &:focus {
      border-bottom: none !important;
    }
  }
`;

export const ExperiencesBodySidebar = styled.aside`
  max-width: 100%;
  position: sticky;
  top: 150px;
  z-index: 9;
  border: 0.5px solid var(--way-colors-borderColor);
  padding: 24px;
  border-radius: 0;
  @media (min-width: 768px) {
    max-width: 405px;
  }
`;

export const SidebarPrice = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 1;
`;

export const Title4 = styled(Heading4)`
  font-weight: normal;
  margin-bottom: 24px;
`;
export const SidebarTitle = styled(Heading4)`
  font-weight: normal;
  margin-bottom: 15px;
`;

export const ExperiencesBodyHost = styled.div`
  margin-bottom: 40px;
`;

export const HostedBy = styled.div`
  display: block;
  margin-bottom: 48px;
  grid-gap: 36px;
  @media (min-width: 768px) {
    grid-template-columns: minmax(205px, 205px) 1fr;
    display: grid;
    align-items: top;
    justify-content: space-between;
  }
`;

export const HostDescription = styled.div``;

export const DescriptionSkeleton = styled.div``;

export const HostedByImageWrap = styled.div`
  width: 205px;
  height: 205px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  margin-bottom: 24px;
  @media (min-width: 768px) {
    margin-bottom: 0px;
  }
`;

export const ExperiencesBodyMap = styled.div`
  margin-bottom: 32px;
`;

export const SessionSidebarBlock = styled.div`
  margin-bottom: 5px;
`;

export const ValidationMessageBlock = styled.span`
  color: red;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const BookingSpace = styled.div`
  margin-bottom: ${({ containshealthtext }) => {
    return containshealthtext ? '25px' : '0px';
  }};
`;

export const AdditionalSection = styled.div`
  margin-top: 20px;
  margin-bottom: -10px;
`;

export const AdditionalSectionTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

export const HeadingAdditional = styled(Heading5)`
  margin-bottom: 0px;
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
`;

export const AdditionalSectionContent = styled.div`
  line-height: 26px;
  margin-bottom: 0px;
`;

export const SelectBox = styled.div`
  position: relative;
  margin-bottom: 0;
`;
export const CustomSelectDiv = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: var(--way-colors-calendarInputColor);
`;

export const ExperienceInclude = styled.div`
  margin-bottom: 48px;
`;

export const ListButton = styled.div`
  background: var(--way-colors-secondaryColorShades-100);
  color: var(--way-colors-secondaryColorContrastShades-100);
  font-size: 12px;
  line-height: 15px;
  display: inline-block;
  padding: 15px 35px;
  border-radius: 10px;
  text-align: center;
  min-width: 135px;
  margin-right: 15px;
  margin-bottom: 15px;
`;

export const ExperienceIncludeList = styled.div`
  display: block;
`;

export const ExperienceIncludeListWrap = styled.div`
  overflow: auto;
  white-space: nowrap;
  @media (min-width: 768px) {
    overflow: hidden;
    white-space: normal;
  }
`;

export const AccessCodeContainer = styled.div`
  margin-bottom: ${({ containshealthtext }) => {
    return containshealthtext ? '25px' : '0px';
  }};
`;

export const AccessTitle = styled.div`
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 15px;
`;

export const AccessInputContainer = styled.div`
  display: flex;
  flex-direction: row-wrap;
  background: var(--way-palette-white-100);
  border: 0.5px solid var(--way-colors-borderColor);
  box-sizing: border-box;
  box-shadow: var(--way-design-boxShadow-s);
  border-radius: var(--way-design-borderRadiusDefault);
  width: 100%;
  height: auto;
  margin-bottom: 10px;
`;

export const AccessInput = styled(StyledInput)`
  width: 75%;
  height: 40px;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  color: var(--way-palette-black-100);
`;

export const AccessButton = styled(Button)`
  width: 25%;
  height: 40px;
  border: none;
  cursor: pointer;
  padding: 0;
  border-radius: 0 4px 4px 0;
  border-left: 0.5px solid var(--way-colors-borderColor);
  font-size: 12px;
  line-height: 15px;
  &:focus {
    outline: 0 none;
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

export const CodeIsRequired = styled.span`
  font-size: 12px;
  line-height: 12px;
  color: red;
`;

export const PrivateCheckboxContainer = styled.div`
  margin-bottom: 15px;
`;

export const DefaultUserAvatar = styled.div`
  background-color: #dedcdc6e;
  position: relative;
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 50%;
  & > * {
    position: absolute;
    top: 40%;
    left: 42%;
    transform: scale(3.5);
  }
`;

export const DimmedText = styled.div`
  font-size: 12px;
  color: var(--waw-colors-contrastColorShades-70);
  margin: -10px 0 12px 0;
`;

export const CategoryChip = styled(Chip)`
  background-color: var(--way-colors-primaryColorShades-100);
  color: var(--way-colors-primaryColorContrastShades-100);
`;
