import useSWR from 'swr';
import { useBrandId } from 'AppProvider';
import fetchBrandById from './fetchBrandById';
import buildBrandByIdUrl from './buildBrandByIdUrl';

const fetchBrandByIdSWR = ([url, { brandId }]: [string, { brandId: string }]) =>
  fetchBrandById(url, { brandId });

const useBrandById = (selectedBrandId: string | null) => {
  const brandId = useBrandId();
  const url = selectedBrandId ? buildBrandByIdUrl(selectedBrandId) : null;
  const swr = useSWR(url ? [url, { brandId }] : null, fetchBrandByIdSWR);
  return {
    ...swr,
  };
};

export default useBrandById;
