import { Session } from 'types/listings';
import { get } from '../api';
import ApiResponse from '../types/ApiResponse';

const fetchSessions = async (url: string, { brandId }: { brandId: string }) => {
  const response = await get<ApiResponse<{ sessions: Session[] }>>(url, {
    headers: {
      'Kouto-Brand-Id': brandId,
      'Way-Brand-Id': brandId,
    },
  });
  return response.data;
};

export default fetchSessions;
