import { ResourceGroup } from 'types/listings';

type FirstAvailableDate = NonNullable<ResourceGroup['firstAvailableDate']>;
type FirstAvailableDateSession = FirstAvailableDate['sessions'][number];

const getLastEndingFirstAvailableDateSession = (
  sessions: FirstAvailableDateSession[],
): FirstAvailableDateSession | undefined =>
  sessions.sort((a, b) => {
    const endTimeA =
      new Date(`1970-01-01T${a.startTime}`).getTime() + a.duration * 60 * 1000;
    const endTimeB =
      new Date(`1970-01-01T${b.startTime}`).getTime() + b.duration * 60 * 1000;
    return endTimeA - endTimeB;
  })[sessions.length - 1];

export default getLastEndingFirstAvailableDateSession;
