import { ListingV2 } from 'types/listings';
import getListingCoverImagePreview from './getListingCoverImagePreview';

const getListingsCoverPreview = (listings: ListingV2[]): string => {
  const found = listings.find((listing) => {
    const coverPreview = getListingCoverImagePreview(listing);
    return coverPreview !== '';
  });

  return found ? getListingCoverImagePreview(found) : '';
};

export default getListingsCoverPreview;
