import { StripeElementsOptionsClientSecret } from '@stripe/stripe-js';
import { IAppTheme } from 'theme/themeType';

export const getStripeElementOptions = (
  clientSecret: string | null | undefined,
  theme: IAppTheme,
): StripeElementsOptionsClientSecret | undefined =>
  clientSecret
    ? {
        clientSecret,
        // setup_future_usage: 'off_session',
        appearance: {
          theme: 'flat',
          variables: {
            fontFamily: ' "Gill Sans", sans-serif',
            fontLineHeight: '1.5',
            borderRadius: theme.design.borderRadiusDefault,
            colorPrimary: theme.colors.primaryTextColor,
            tabIconColor: theme.colors?.contrastColorShades?.[100],
            tabIconSelectedColor: theme.colors?.contrastColorShades?.[100],
            colorBackground: 'transparent',
            accessibleColorOnColorPrimary: 'white',
            focusOutline: 'none',
            tabSpacing: '6px',
            gridRowSpacing: '24px',
            gridColumnSpacing: '24px',
          },
          rules: {
            '.Block': {
              boxSizing: 'content-box',
              boxShadow: 'none',
              padding: '12px',
            },
            '.Input:focus': {
              border: `0.5px solid ${theme.colors.borderHoverColor}`,
              color: theme.colors.contrastColorShades[100],
              boxShadow: 'none',
            },
            '.Input:hover': {
              border: `0.5px solid ${theme.colors.borderHoverColor}`,
              boxShadow: 'none',
            },
            '.Input': {
              border: `0.5px solid ${theme.colors.borderColor}`,
              backgroundColor: 'transparent',
              padding: '12px',
              outline: 'none',
              boxShadow: 'none',
              color: theme.colors.contrastColorShades[100],
            },
            '.Input:disabled, .Input--invalid:disabled': {
              color: 'lightgray',
              boxShadow: 'none',
              backgroundColor: 'transparent',
            },
            '.Tab': {
              backgroundColor: 'transparent',
              border: `0.5px solid ${theme.colors.borderColor}`,
              padding: '10px 12px 8px 12px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            },
            '.Tab:hover': {
              backgroundColor: 'transparent',
              border: `0.5px solid ${theme.colors.borderHoverColor}`,
              boxShadow: 'none',
            },
            '.Tab:focus, .Tab:active': {
              boxShadow: 'none',
            },
            '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
              backgroundColor: 'transparent',
              border: `1px solid ${theme.colors.contrastColorShades[100]}`,
              boxShadow:
                '0px 1px 1px 0px rgba(0,0,0,0.06), 2px 4px 4px -1px rgba(0,0,0,0.10)',
            },
            '.p-TabIconContainer': {
              margin: '0px 10px',
              width: 'auto',
            },
            '.TabLabel': {
              color: theme.colors.contrastColorShades[100],
              fontSize: '1.2em',
            },
            '.Label': {
              fontWeight: '500',
              marginBottom: '6px',
              color: theme.colors.contrastColorShades[100],
            },
          },
        },
      }
    : undefined;
