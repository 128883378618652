import { debug } from 'utils/debug';

export function getResponseJson(httpError) {
  return httpError.response;
}

export function errorMessage(errorResponse) {
  return (
    (errorResponse &&
      (errorResponse.detail || errorResponse.title || errorResponse.message)) ||
    'Something went wrong'
  );
}

export function getResponseStatus(errorResponse) {
  return errorResponse?.status ?? errorResponse?.statusCode;
}

export function validationMessages(errorResponse) {
  const validationErrors = errorResponse?.validationErrors || {};

  const messages = Object.keys(validationErrors)
    .map((fieldName) => {
      const validationObjects = Object.keys(validationErrors[fieldName]).map(
        (validationType) => ({
          code: `${fieldName}.${validationType}`,
          fieldName,
          message:
            validationErrors[fieldName][validationType]?.message ??
            validationErrors[fieldName][validationType],
        }),
      );
      return validationObjects;
    })
    .reduce((prev, next) => prev.concat(next), []);
  return messages;
}

export function errorAction(actionType, errorResponse) {
  debug('error', {
    actionType,
    errorMessage: errorMessage(errorResponse),
  });
  return {
    type: actionType,
    error: errorMessage(errorResponse),
    payload: {
      validationMessages: validationMessages(errorResponse),
      responseStatus: getResponseStatus(errorResponse),
    },
  };
}

/**
 * Filter undefined, null and empty values in an object
 */
export const filterEmpty = (values) => {
  /* eslint consistent-return: 'off' */
  if (values === null && values === '') return;
  if (typeof values !== 'object') return values;
  /* eslint no-param-reassign: 'off' */
  return Object.keys(values).reduce((prev, next) => {
    // check for nested empty values
    if (
      typeof values[next] === 'object' &&
      !Array.isArray(values[next]) &&
      values[next] !== null
    ) {
      prev[next] = filterEmpty(values[next]);
      return prev;
    }
    if (Array.isArray(values[next])) {
      prev[next] = values[next].map(filterEmpty);
      return prev;
    }
    if (
      values[next] !== undefined &&
      values[next] !== null &&
      values[next] !== ''
    ) {
      prev[next] = values[next];
    }
    return prev;
  }, {});
};
